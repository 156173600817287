import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";

import { db } from '../../firebase'

import Table from 'react-bootstrap/Table';
import Navbar from '../inc/Navbar';


function Tendernew() {
    const [setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(98);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [news, setNews] = useState([])
    const navigate = useNavigate()

    const newsCollectionRef = collection(db, "TENDERS");
    useEffect(() => {

        const getNews = async () => {
            const data = await getDocs(newsCollectionRef);
            setNews(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getNews()
    }, [])
    return (

        <>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

            <div className="album py-0">

                <div className="container">

                    <div className="row">
                        {news.filter((val) => {
                            if (setsearchTerm === "") {
                                return val;
                            } else if (
                                val.title.toLowerCase().includes(searchTerm.toLowerCase())




                            ) {
                                return val;
                            }
                        }).slice(pagination.start, pagination.end).map((centralsericultural) => {
                            return (
                                <div className="col-md-12" >
                               
                               <h5>AUCTION</h5>
                                    <Table striped bordered hover variant="info">
      <thead>
        <tr>
          <th>No</th>
          <th>Details</th>
          <th>Date & Time</th>
          <th>Extended (If So)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{centralsericultural.no1}</td>
          <td>{centralsericultural.name1}</td>
          <td>{centralsericultural.date1}</td>
          <td>{centralsericultural.extended1}</td>
        </tr>
        <tr>
          <td>{centralsericultural.no2}</td>
          <td>{centralsericultural.name2}</td>
          <td>{centralsericultural.date2}</td>
          <td>{centralsericultural.extended2}</td>
        </tr>
        <tr>
          <td>{centralsericultural.no3}</td>
          <td>{centralsericultural.name3}</td>
          <td>{centralsericultural.date3}</td>
          <td>{centralsericultural.extended3}</td>
        </tr>
        <tr>
          <td>{centralsericultural.no4}</td>
          <td>{centralsericultural.name4}</td>
          <td>{centralsericultural.date4}</td>
          <td>{centralsericultural.extended4}</td>
        </tr>
        <tr>
          <td>{centralsericultural.no5}</td>
          <td>{centralsericultural.name5}</td>
          <td>{centralsericultural.date5}</td>
          <td>{centralsericultural.extended5}</td>
        </tr>
        <tr>
          <td>{centralsericultural.no6}</td>
          <td>{centralsericultural.name6}</td>
          <td>{centralsericultural.date16}</td>
          <td>{centralsericultural.extended6}</td>
        </tr>
        <tr>
          <td>{centralsericultural.no7}</td>
          <td>{centralsericultural.name7}</td>
          <td>{centralsericultural.date7}</td>
          <td>{centralsericultural.extended7}</td>
        </tr>
        <tr>
          <td>{centralsericultural.no8}</td>
          <td>{centralsericultural.name8}</td>
          <td>{centralsericultural.date8}</td>
          <td>{centralsericultural.extended8}</td>
        </tr>
        <tr>
          <td>{centralsericultural.no9}</td>
          <td>{centralsericultural.name9}</td>
          <td>{centralsericultural.date9}</td>
          <td>{centralsericultural.extended9}</td>
        </tr>
   

      </tbody>
    </Table>
                                    </div>

                                
                            )
                        })}
                    </div>


                </div>
            </div>
            <br />
        </>

    );
}

export default Tendernew;



