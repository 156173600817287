
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
    apiKey: "AIzaSyD3DXwUgEhznUyNmhlA9_UBjQc7S9z_x_c",
    authDomain: "central-sericultural.firebaseapp.com",
    projectId: "central-sericultural",
    storageBucket: "central-sericultural.appspot.com",
    messagingSenderId: "537867014962",
    appId: "1:537867014962:web:c51ad73ab6354116eebd2b",
    measurementId: "G-FF2C0S1FSF"

};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;