import React from 'react'
import Navbar from "../inc/Navbar";

import {Link} from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import { Table } from "react-bootstrap";
import drdevi from "../images/Dr. Thangjam Ranjita Devi.jpeg";

function Naik() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br/>
            <br />
            <br />
            <ScrollToTop smooth top="100" color="darkBlue"/>
            <div className="sericultural">

                <div className="album py-1">

                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house-door" viewBox="0 0 16 16">
  <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4z"/>
</svg> Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/scientist-profile" ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
  <path fill-rule="evenodd" d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
</svg> Back</Link></li>
                                <li className="breadcrumb-item active" aria-current="page"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-lines-fill" viewBox="0 0 16 16">
  <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5m.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1z"/>
</svg> Contact Us</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br />
            <div className="album py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 mt-3 me-5">
                            
                                <img src={drdevi}  className="card-img-top" alt="Dr. Thangjam Ranjita Devi"/>
                               
                            
                        </div>
                        <div className="col-md-6 mt-3 ">
                            
                     <br/>
                   
                                <div className="card-body">
                                    <h3 style={{color:"darkBlue"}} className="card-title">Dr. Thangjam Ranjita Devi </h3>
                                    <p className="card-text">Scientist-C (Silkworm Breeding and Genetics)</p>

                                    <hr style={{color:"green"}}/>
                                    <br/>
                                    <a href='tel:   +919113600286'>  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone" viewBox="0 0 16 16">
  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
</svg> +919113600286</a>
                                    <a  href='mailto: drpnaik.csb@nic.in'>
                                        <h6 style={{color:"grey"}}><svg xmlns="http://www.w3.org/2000/svg" style={{color:'green'}} width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
</svg> drpnaik.csb@nic.in</h6> 
                                        </a>
                                  
<br/>
                                    
                                            <hr style={{color:"green"}}/>
                                    
                                </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <div className="album py-2">
                <div className="container">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
  <li className="nav-item" role="presentation">
    <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">ABOUT</button>
  </li>
  <li className="nav-item" role="presentation">
    <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">RESEARCH</button>
  </li>
  <li className="nav-item" role="presentation">
    <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">PUBLICATION</button>
  </li>
  <li className="nav-item" role="presentation">
    <button className="nav-link" id="education-tab" data-bs-toggle="tab" data-bs-target="#education" type="button" role="tab" aria-controls="education" aria-selected="false">EDUCATION</button>
  </li>
  <li className="nav-item" role="presentation">
    <button className="nav-link" id="projects-tab" data-bs-toggle="tab" data-bs-target="#projects" type="button" role="tab" aria-controls="projects" aria-selected="false">PROJECTS</button>
  </li>
</ul>
<div className="tab-content" id="myTabContent">
  <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
    <br/>
    <h6 className='texts mb-3' >Dr. Parameswaranaik J is a Scientist- C (Agricultural Extension) Working in Training Division of C.S.R &T.I, Berhampore Central Silk Board, Ministry of Textiles, Govt. of India. </h6>
    <h6>He is currently looking after the Training Division of the Institute and acting as a Principal Coordinator of the Post Graduate Diploma in Sericulture (PGDS) course </h6>
    
  </div>
  <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
    <br/>
    <h6 className='texts mb-3'>His primary area is Human Resource Development, Entrepreneurship Development and Climate Change studies. </h6>

  </div>
  <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
    <br/>
    <h6 className='texts mb-3'>1.	Parameswaranaik, J., Jha, S.K. & Lal, S.P (2020).  Return Migration of Rural Youth Vis-à-Vis Agripreneurship Development in Southern India. National Academy of Science. Letters. (Springer) <a href='https://doi.org/10.1007/s40009-020-00935-x'>https://doi.org/10.1007/s40009-020-00935-x</a> </h6>
    <h6>2.	Parameswaranaik J., Kumar. S.R., Bhawar R.S., Darshan N.P and Patel. D (2016). Factors influencing adaptation strategies by livestock owners to combat climate variability in Karnataka state: Application of ordered logistic regression model. Indian Journal of Animal Sciences, 86(9):74-78</h6>

    <h6> 3.	Parameswaranaik J., Kumar. S.R., Singh. S and Bhawar R.S (2017). Dairy farmers knowledge on climate variability in the northern dry zone of Karnataka. Indian Journal of Dairy Sciences, 70(3): pp.364-370.</h6>
    <h6>4.	Parameswaranaik J and Sujeet Kumar Jha (2018). Return migration of Rural Youth: A new trend in Rural India. Journal of Global Communication. 11(2), 124-127.</h6>
    <h6>5.	Chandrakala G. Raju, Somdyuti Sarkar, Varun Canamedi, Parameshwaranaik J and  Sukhabrata Sarkar (2022). A Review of Silk Farming Automation Using Artificial Intelligence, Machine Learning, and Cloud-Based Solutions. Data Analytics and Learning. ICDAL 2022. Springer, Singapore</h6>
  </div>
  <div className="tab-pane fade" id="education" role="tabpanel" aria-labelledby="education-tab">

    <br/>
   
    <div className="album py-1">
        <div className="container">

          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th> <center style={{ color: "maroon" }}>Degree</center></th>
                <th><center style={{ color: "maroon" }}> Year</center></th>
                <th><center style={{ color: "maroon" }}>Thesis title</center></th>
                <th><center style={{ color: "maroon" }}> Institute</center></th>

              </tr>
            </thead>
            <tbody>

              <tr >
                <td > <center className='mb-2 mt-2'>Ph.D</center></td>
                <td><center className='mb-2 mt-2'></center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>Agricultural Extension Education</center></td>
                <td><center className='mb-2 mt-2'>ICAR - National Dairy Research Institute, Karnal, Haryana </center></td>



              </tr>
              <tr >
                <td > <center className='mb-2 mt-2'>M.Sc.</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'></center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>.: Agricultural Extension Education</center></td>
                <td><center className='mb-2 mt-2'> ICAR - National Dairy Research Institute, Karnal, Haryana </center></td>



              </tr>
              <tr >
                <td > <center className='mb-2 mt-2'>B.Sc</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'></center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>Agriculture</center></td>
                <td><center className='mb-2 mt-2'><small>University of Agricultural Sciences, Bengaluru</small></center></td>



              </tr>


            </tbody>
          </Table>
          <h6>(Awarded Post Graduate Diploma in Rural Development (PGDRD) from Computer Point Institute of Technology and Management, Karnal, Haryana).
            <br/> <br/>
(Awarded Post Graduate Diploma in Human Resource Management (PGDHRM) from Computer Point Institute of Technology and Management, Karnal, Haryana)<br/>
<br/>
(Awarded Post Graduate Certificate in Climate Change (PGCCC) from Indira Gandhi National Open University (IGNOU), New Delhi with 77.13% during the year 2021).
</h6>
        </div>
      </div>
  </div>
  <div className="tab-pane fade" id="projects" role="tabpanel" aria-labelledby="projects-tab">
    <br/>
    <div className="album py-1">
        <div className="container">

          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th> <center style={{ color: "maroon" }}>S.N</center></th>
                <th><center style={{ color: "maroon" }}> Name of Research Project</center></th>
                <th><center style={{ color: "maroon" }}>Funding Agency / Amount(in Rs.)</center></th>
                <th><center style={{ color: "maroon" }}> Status</center></th>

              </tr>
            </thead>
            <tbody>

              <tr >
                <td > <center className='mb-2 mt-2'>1</center></td>
                <td><center className='mb-2 mt-2'>Seri-Entrepreneurship Development in Aspirational Districts of North-Eastern India (Principal Investigator)</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>DBT₹43.40 lakh</center></td>
                <td><center className='mb-2 mt-2'>Concluded </center></td>



              </tr>
              <tr >
                <td > <center className='mb-2 mt-2'>2</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>Development of Seri-Entrepreneurship
through sericulture chawki business by setting up 02 Chawki Rearing Centers (CRC) as demonstrative units in Murshidabad district, West Bengal. (Principal Investigator)</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>NABARD 
₹20.89 lakh
</center></td>
                <td><center className='mb-2 mt-2'> Concluded  </center></td>



              </tr>
              <tr >
                <td > <center className='mb-2 mt-2'>3</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>Vulnearability of sericulture to Climate Change in India (Principal Investigator)</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>CSB ₹40.00 lakh</center></td>
                <td><center className='mb-2 mt-2'><small>On-Going (March 2024 to August 2026)</small></center></td>

              </tr>
              <tr >
                <td > <center className='mb-2 mt-2'>4</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>Impact assessment sericultural technologies in India (Co-Principal Investigator)</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>CSB ₹52.00 lakh</center></td>
                <td><center className='mb-2 mt-2'><small>On-Going  (July 2022 to June 2025)</small></center></td>

              </tr>
              <tr >
                <td > <center className='mb-2 mt-2'>5</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>Study on Sericulture Based IFS in Hilly region of West Bengal (Co- Investigator)</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>CSB ₹4.60 lakh</center></td>
                <td><center className='mb-2 mt-2'><small>Concluded</small></center></td>

              </tr>

              <tr >
                <td > <center className='mb-2 mt-2'>6</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>Improvement of Seed Crop Productivity in West Bengal (Co- Investigator)</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>CSB ₹84.60 lakh</center></td>
                <td><center className='mb-2 mt-2'><small>On-Going  (Nov 2022 to Octo 2025)</small></center></td>

              </tr>

            </tbody>
          </Table>
    
        </div>
      </div>
  </div>
</div>
</div>
</div>
<br/>
<br/>
        </div>
    )
}

export default Naik