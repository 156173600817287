import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";

import { db } from '../../firebase'

import Table from 'react-bootstrap/Table';
import Navbar from '../inc/Navbar';







function RelatedLink() {
    const [setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(98);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [news, setNews] = useState([])
    const navigate = useNavigate()

    const newsCollectionRef = collection(db, "Related-Link");
    useEffect(() => {

        const getNews = async () => {
            const data = await getDocs(newsCollectionRef);
            setNews(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getNews()
    }, [])
    return (

        <>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br/>
            <br />

            <div className="album py-0">

                <div className="container">

                    <div className="row">
                        {news.filter((val) => {
                            if (setsearchTerm === "") {
                                return val;
                            } else if (
                                val.title.toLowerCase().includes(searchTerm.toLowerCase())




                            ) {
                                return val;
                            }
                        }).slice(pagination.start, pagination.end).map((centralsericultural) => {
                            return (
                                <div className="col-md-12" >
                                    <div className='card'>

                                        <Table striped>
                                            <thead>
                                                <tr>
                                                    <th><h5 style={{color:'Darkblue'}}><b>{centralsericultural.title}</b></h5></th>
                                                    <th>{centralsericultural.view}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{centralsericultural.name1}</td>
                                                    <td><a href={centralsericultural.link} target="_blank" rel="noopener noreferrer" >{centralsericultural.webname} </a></td>

                                                </tr>
                                                <tr>
                                                    <td>{centralsericultural.name2}</td>
                                                    <td><a href={centralsericultural.link2} target="_blank" rel="noopener noreferrer" >{centralsericultural.webname2} </a></td>

                                                </tr>
                                                <tr>
                                                <td>{centralsericultural.name3}</td>
                                                    <td><a href={centralsericultural.link3} target="_blank" rel="noopener noreferrer" >{centralsericultural.webname3} </a></td>

                                                </tr>
                                                <tr>
                                                <td>{centralsericultural.name4}</td>
                                                    <td><a href={centralsericultural.link4} target="_blank" rel="noopener noreferrer" >{centralsericultural.webname4} </a></td>
                                                </tr>
                                                <tr>
                                                <td>{centralsericultural.name5}</td>
                                                    <td><a href={centralsericultural.link5} target="_blank" rel="noopener noreferrer" >{centralsericultural.webname5} </a></td>
                                                </tr>
                                                <tr>
                                                <td>{centralsericultural.name6}</td>
                                                    <td><a href={centralsericultural.link6} target="_blank" rel="noopener noreferrer" >{centralsericultural.webname6} </a></td>
                                                </tr>
                                                <tr>
                                                <td>{centralsericultural.name7}</td>
                                                    <td><a href={centralsericultural.link7} target="_blank" rel="noopener noreferrer" >{centralsericultural.webname7} </a></td>
                                                </tr>
                                                <tr>
                                                <td>{centralsericultural.name8}</td>
                                                    <td><a href={centralsericultural.link8} target="_blank" rel="noopener noreferrer" >{centralsericultural.webname8} </a></td>
                                                </tr>
                                                <tr>
                                                <td>{centralsericultural.name9}</td>
                                                    <td><a href={centralsericultural.link9} target="_blank" rel="noopener noreferrer" >{centralsericultural.webname9} </a></td>
                                                </tr>
                                                <tr>
                                                <td>{centralsericultural.name10}</td>
                                                    <td><a href={centralsericultural.link10} target="_blank" rel="noopener noreferrer" >{centralsericultural.webname10} </a></td>
                                                </tr>
                                                <tr>
                                                <td>{centralsericultural.name11}</td>
                                                    <td><a href={centralsericultural.link11} target="_blank" rel="noopener noreferrer" >{centralsericultural.webname11} </a></td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>

                                </div>
                            )
                        })}
                    </div>


                </div>
            </div>
            <br />
        </>

    );
}

export default RelatedLink;



