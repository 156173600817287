import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";

import { db } from '../../firebase'









function Notice() {
    const [setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(68);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [news, setNews] = useState([])
    const navigate = useNavigate()

    const newsCollectionRef = collection(db, "NOTICE");
    useEffect(() => {

        const getNews = async () => {
            const data = await getDocs(newsCollectionRef);
            setNews(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getNews()
    }, [])
    return (

        <>
         
            <div className="album py-0">

                <div className="container">

                    <div className="row">
                        {news.filter((val) => {
                            if (setsearchTerm === "") {
                                return val;
                            }
                             {
                                return val;
                            }
                        }).slice(pagination.start, pagination.end).map((centralsericultural) => {
                            return (
                                <div className="col-md-12" >
                                    
                                     
                                        
                                        
                                            <h6  style={{color:"green"}}  className="card-text mt-2"> {centralsericultural.notice1}</h6>
                                            <h6  style={{color:"green"}}  className="card-text">{centralsericultural.eventhelnotice2}</h6>

                                            <h6  style={{color:"green"}}  className="card-text"> {centralsericultural.notice3}</h6>
                                            <h6  style={{color:"green"}}  className="card-text"> {centralsericultural.notice4}</h6>

                                            





                                            <div className="d-flex justify-content-between align-items-center">



                                            
                                        </div>
                                    </div>

                                
                            )
                        })}
                    </div>


                </div>
            </div>

        </>

    );
}

export default Notice;



