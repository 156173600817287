import React from 'react'
import Navbar from '../inc/Navbar'

import {Link} from "react-router-dom";

function Rti13() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className="sericultural">

<div className="album py-1">

    <div className="container mt-3">
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                
                <li className="breadcrumb-item"><Link to="/" href="#"> Home</Link></li>
                <li className="breadcrumb-item"><Link to="/rti" href="#"> Back</Link></li>
                <li className="breadcrumb-item active" aria-current="page">RTI13</li>
            </ol>
        </nav>
    </div>
</div>
</div>
            <br />
            <div className="album py-1">
                <div className="container ">
                    <h4 style={{ color: "darkblue" }}>The particulars of recipients of concessions, permits or authorization granted by it.

     

</h4>
                    <br />
                    <br />
                    <h6>CSRTI @ Berhampore, under the aegis of Central Silk Board, is the leading institute in mulberry sericulture</h6>
                    <br/>

    <h6>   As a source of the technology it works for R&D activities regularly for the sake of the stakeholder of silk industry in Eastern & North Eastern India and pass on those to the stakeholders’ i.e. ultimate receivers/users.</h6>



    <br/>


    <h6>All the technologies (e.g. region & season specific hybrid silkworm, mulberry varieties, disinfectants etc.) developed in the institute are disseminated to the farmers for overall productivity of the cocoon which in-turn uplift the socio-economic condition of the Limited Resource Farmers.</h6>

    <br/>
    <h6>Many of such technologies are exploited commercially specially product oriented (e.g. Nirmool) and some are patented.</h6>






                </div>
            </div>
            <br />
            <br />

        </div>
    )
}

export default Rti13