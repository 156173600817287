import React from 'react'
import Navbar from '../inc/Navbar'
import {Link} from "react-router-dom";

function Reeling() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <div className="sericultural">

<div className="album py-1">

    <div className="container mt-3">
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/" href="#">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Hostplant Post Coocon Technology</li>
            </ol>
        </nav>
    </div>
</div>
</div>
<br/>
<br />
        <div className="album py-0">
<div className="container">
    <h2 style={{color:"maroon"}}>Reeling & Spinning</h2>
    <h5>SCIENTISTS: </h5>
    <h6>Shri Arun kimar Scientist - B 
</h6>
<br/>
<h5>ACTIVITIES:</h5>
<h6>Enhancement of productivity and quality of raw silk and technological evaluation of new mulberry silkworm races

</h6>

    </div>
</div>
<br/>
<br/>
    </div>
  )
}

export default Reeling