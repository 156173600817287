import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";

import { db } from '../../firebase'







function DirectorSpeech() {

    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded);
    };


    const [setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(15);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [news, setNews] = useState([])
    const navigate = useNavigate()

    const newsCollectionRef = collection(db, "DirectorSpeech");
    useEffect(() => {

        const getNews = async () => {
            const data = await getDocs(newsCollectionRef);
            setNews(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getNews()
    }, [])
    return (

        <>

            <div className="album py-0">

                <div className="container">

                    <div className="row">
                        {news.filter((val) => {
                            if (setsearchTerm === "") {
                                return val;
                            } else if (
                                val.name.toLowerCase().includes(searchTerm.toLowerCase())




                            ) {
                                return val;
                            }
                        }).slice(pagination.start, pagination.end).map((centralsericultural) => {
                            return (
                                <div className="col-md-12 mt-3" >

                                    <h4>DIRECTOR SPEECH</h4>
                                    <br />
                                    <h6 className='texts mb-3'>{centralsericultural.title1}</h6>
                                    <h6 className='texts mb-3'>{centralsericultural.title2}</h6>
                                    <h6 className='texts mb-3'>{centralsericultural.title3}</h6>
                                    <h6 className='texts mb-3'>{centralsericultural.title4}</h6>
                                    <h6 className='texts mb-3'>{centralsericultural.title5}</h6>
                                    <h6 className='texts mb-3'>{centralsericultural.title6}</h6>
                                    <div className="d-flex justify-content-end align-items-center mb-2">
                                        <h4>{centralsericultural.name} <br />
                                        {centralsericultural.post}</h4>

                                    </div>
                                </div>
                            )
                        })}
                    </div>


                </div>
            </div>

        </>

    );
}

export default DirectorSpeech;



