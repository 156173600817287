import React from 'react'
import Navbar from '../inc/Navbar'
import rtiimage from "../images/Picture2.png";
import rtiimage3 from "../images/Picture3.png";
import {Link} from "react-router-dom";

function Rti3() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className="sericultural">

<div className="album py-1">

    <div className="container mt-3">
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/" href="#"> Home</Link></li>
                <li className="breadcrumb-item"><Link to="/rti" href="#"> Back</Link></li>
                <li className="breadcrumb-item active" aria-current="page">RTI3</li>
            </ol>
        </nav>
    </div>
</div>
</div>
            <br />
            <div className="album py-1">
                <div className="container ">
                    <h4 style={{ color: "darkblue" }}>The procedure followed in the decision making process, including channels of supervision and accountability:</h4>
                    <br />
                    <br />
                    <h6>CSB, Bangalore is responsible for making policies relating to silk in India. All research institute under CSB implements those policies as per guideline. CSRTI-Berhampore also implements it accordingly. However, CSRTI-Berhampore, like other institutes, can take those decisions which are empowered to them. The procedure followed in decision making process is as shown below:</h6>

                </div>
            </div>

            <div className="album py-1">
                <div className="container ">
                    <div className="row">
                        <div className="col-md-6 mt-1">
                            <img src={rtiimage} className="d-block w-100" alt="central_sericultural_berhampore" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="album py-1">
            <div className="container ">
            <h6> <b style={{color:"maroon"}}>Research :</b> Research Co-ordination Committee(RCC), constituted by the eminent Scientist of different institutes,  is the highest body in the Central Silk Board for formulating research policies. Scientists of each institutes are entrusted with the formulation of research projects/programme/pilot studies and its implementation following certain channel of procedure e.g</h6>
            <br/>
            <img src={rtiimage3} className="d-block w-100" alt="central_sericultural_berhampore" />
            <br/>
            <h6 className='mb-3'><b style={{color:"maroon"}}>Extension:</b>  Extension activities including ECPs are monitored by the Extension Division through its different field unis (RSRSs & RECs located in different zones/states)</h6>
            <h6><b style={{color:"maroon"}}>Transfer of Technologies (ToTs):</b>  Demonstrations under ToTs are monitored by the concern scientists of different section of CSRTI-Berhampore directly as well as through its different field unis (RSRSs & RECs located in different zones/states)</h6>
<br/>
<h6><b style={{color:"maroon"}}>Accounts:</b> Based on approved annual Action Plan on R &D and other works of the institute by the CSB- Bangalore detain expenditure budget is prepared and spend accordingly. Monthly accounts and annual accounts are forwarded to Central Silk Board, Bangalore as a testimony of utilization of funds. Fund requirement for the future months is send to the CSB in advance. Annual accounts, purchase proposals etc., are audited by the Internal Audit, Central Silk Board, Bangalore and A.G. who furnishes true and fair certificate. Similarly expenditure for all purposes is done as per the prescribed purchase procedure.</h6>
<br/>
<h6><b  style={{color:"maroon"}}>Establishment:</b> All receipts are put-up by concerned to their supervisor viz., Superintendent. The files are examined, suggested and forwarded by the Supdt. to the Sectional heads viz., Assistant Director (Administration & Accounts). Files are then categorized in accordance with delegation of powers at the level of Section Officer and accordingly disposed. Files requiring sanction / approval of the level of Divisional head and Director /Head of office are then segregated and sent to Divisional Head viz., Deputy Director (A&A) then to Director. Such files scrutinized and separated according to delegation of powers to the Divisional Head & Head of office. The files which can be disposed at Deputy Director (A&A) level are accordingly disposed but remaining files / cases are referred to Head of office. Accordingly the accountability has been fixed at different points of disposal</h6>
<h6><b style={{color:"maroon"}}>Store:</b> Procedure for store purchase as mentioned in G.F.R., are being followed scrupulously and as per the guide lines issued time to time by Central Silk Board and Central Vigilance Commission.</h6>
<h6><b style={{color:"maroon"}}>Maintenance:</b> Maintain all kinds of internal works following all procedure as mentioned in G.F.R., (e.g. through proposal, estimate, tender etc.) as per the guide lines issued time to time by Central Silk Board.</h6>
            </div>
            </div>
            <br />
            <br />

        </div>
    )
}

export default Rti3