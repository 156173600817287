import React from 'react'
import Navbar from '../inc/Navbar'
import {Link} from "react-router-dom";
import Table from 'react-bootstrap/Table';
import RtiAct from './RtiAct';
import Rtifeecostrules from './Rtifeecostrules';
import RtiApplicationform from './RtiApplicationform';
import Rti8 from './Rti8';
import RtiEmp from './RtiEmp';
import RtiSalary from './RtiSalary';
import Rti11 from './Rti11';
import Rti12 from './Rti12';
import dpower from "../images/Disciplinary powers.pdf";


function Rti() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      
        <div className="sericulturalberhamporemurshidabad">

<div className="album py-1">

    <div className="container mt-3">
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/" style={{color:"white"}} href="#">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">RTI</li>
            </ol>
        </nav>
    </div>
</div>
</div>
        <div className='backgroundc'>
        <br/>
            <center><h4 style={{color:"green"}}  className='mt-2 mb-4'>RIGHT TO INFORMATION ACT, 2005</h4></center>
            <div className="album py-0">
                <div className="container">
                    <h6>Right to Information Act subject in Central Sericultural Research & Training Institute, Berhampore, West Bengal is handled by the Director, CSR&TI, Berhampore have been designated as Central Public Information Officers (CPIO). A list of all Assistant Public Information Officers (APIOs) of its nested units is available in Item No. 16. If the information made available on this Website is not sufficient, you are welcome to give your inputs to these officers through letter, email, fax, telephone etc.</h6>
                    <br/>
                    <br/>

                    <h6><RtiAct/></h6>
                    <h6><Rtifeecostrules/></h6>
                    <h6 className='mb-4'><RtiApplicationform/></h6>
                    <center>
                    <h6>Reports separately point-wise information (No. I to XVII) under section 4(1)(b) of Right to information Act, 2005.</h6>
                    </center>
                    </div>
                </div>
                <br/>
                
<div className="album py-1">

<div className="container mt-0">
                <Table striped bordered hover variant="light">
      <thead>
        <tr>
          <th><center>No.</center></th>
          <th><center>Name of the item</center></th>
          <th><center>Details File type / status</center></th>
         
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><center>1</center></td>
          <td>The  Particulars Of The Organization,  Function  &  Duties</td>
      
          <td><center><a href='Rti-1'>PDF file/click me</a></center></td>
          
         
        </tr>
        <tr>
          <td><center>2</center></td>
          <td>Powers and duties of its officers & employees
            <br/>
            <a href='http://csb.gov.in/'  target="_blank" rel="noopener noreferrer">
          
            <p className='mb-0'>Duties and Responsibilities of CSB Officers and Employees</p>
            </a>
            <p className='mb-0'> Financial Powers- Circular - <a href='http://csb.gov.in/' target="_blank" rel="noopener noreferrer">English Version </a> - <a href='http://csb.gov.in/' target="_blank" rel="noopener noreferrer"> Hindi Version</a></p>
            <a href={dpower}>
            <p className='mb-0'>Disciplinary Powers</p></a> 
            <a href='http://csb.gov.in/'>
            <p>Disciplinary Powers in respect of CFW/ TSFW workers</p></a>
        

          </td>
          <td><center></center></td>
         
        </tr>
        <tr>
          <td><center>3</center></td>
          <td>The procedures followed in decision making process including channels of supervision and accountability</td>
          <td><center><a href='Rti3'>PDF file/click me</a></center></td>
        </tr>
        <tr>
          <td><center>4</center></td>
          <td>The norms set by it for the discharge of its</td>
          <td><center><a href='Rti4'>PDF file/click me</a></center></td>
        </tr>
        <tr>
          <td><center>5</center></td>
          <td>	The rules, regulations, instructions, manuals and records held under its control for used by its employees for discharging its function</td>
          <td><center><a href='Rti5'>PDF file/click me</a></center></td>
        </tr>
        <tr>
          <td><center>6</center></td>
          <td>A statement of the categories of documents that are held by it or under its control</td>
          <td><center><a href='Rti6'>PDF file/click me</a></center></td>
        </tr>
        <tr>
          <td><center>7</center></td>
          <td>	
          The particulars of any arrangement that exists for consultation with, or representation by, the members of the public in relation to the formulation of its policy or implementation thereof</td>
          <td><center><a href='Rti6'><a href='Rti7'>PDF file/click me</a></a></center></td>
        </tr>

        <tr>
          <td><center>8</center></td>
          <td>	
          A statement of Board, Councils, Committees and other bodies consisting of  two or more officials constituted as its part or for the purpose of its advice and as to whether meeting of those boards, councils, committees and other bodies are open to the public or minutes of such meetings are accessible for public</td>
          <td><center><Rti8/></center></td>
        </tr>

        <tr>
          <td><center>9</center></td>
          <td>	
          A directory of its officers and employees</td>
          <td><center><RtiEmp/></center></td>
        </tr>

        <tr>
          <td><center>10</center></td>
          <td>The monthly remuneration received by each of its Officers and employees, including the system of compensation as provided in its regulations</td>
          <td><center><RtiSalary/></center></td>
        </tr>
        <tr>
          <td><center>11</center></td>
          <td>The budget allocated to each of its agency, indicating the particulars of all plans, proposed expenditures and reports on disbursements made</td>
          <td><center><Rti11/></center></td>
        </tr>

        <tr>
          <td><center>12</center></td>
          <td>The manner of execution of subsidy programmes, including the amounts allocated and the details of beneficiaries of such programmes</td>
          <td><center><Rti12/></center></td>
        </tr>
        <tr>
          <td><center>13</center></td>
          <td>Particulars of recipients of concessions permits or authorizations granted by it</td>
          <td><center><a href='Rti13'>PDF file/click me</a></center></td>
        </tr>
        <tr>


          <td><center>14</center></td>
          <td>Details in respect of the information, available to or held by it, reduced in an electronic form.</td>
          <td><center><a href='Rti14'>PDF file/click me</a></center></td>
        </tr>
        <tr>
          <td><center>15</center></td>
          <td>The particulars of facilities available to citizens for obtaining information, including the working hours of a library or reading room, if maintained for public use.</td>
          <td><center><a href='Rti15'>PDF file/click me</a></center></td>
        </tr>
        <tr>
          <td><center>16</center></td>
          <td>Particulars of recipients of concessions permits or authorizations granted by it</td>
          <td><center><a href='Rti16'>PDF file/click me</a></center></td>
        </tr>
        <tr>
          <td><center>17</center></td>
          <td>Such other information as may be prescribed and thereafter update these publications every year</td>
          <td><center><a href='Rti17'>PDF file/click me</a></center></td>
        </tr>
      </tbody>
    </Table>
    </div>
    </div>
        </div>
       
        
    </div>
  )
}

export default Rti