import React from 'react'
import Navbar from '../inc/Navbar'
import DirectorSpeech from './DirectorSpeech'

function Directors() {
  return (
    <div>
      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <div className="album py-1">
<DirectorSpeech/>
        {/* <div className="container">
          <h4>DIRECTOR SPEECH</h4>
          <br />
          <h6 className='texts mb-3'>It is a matter of great pride and privilege to be at the helm of affairs of the oldest research institute under Central Silk Board (CSB). Central Sericultural Research and Training Institute (CSRTI) Berhampore is the pioneer among all the institutions under CSB and was established in the year 1943 as Central Sericultural Research Station.  The primary aim was to give a fillip to the practice of silkworm raising, to find ways to improve the techniques involved and to ensure meaningful return to the farmers. This pre-independent era institute with the richest legacy came under the administrative fold of CSB in 1949 and, was later rechristened with the present name in the year 1980  with a much wider and deeper mandate.</h6>
          <h6 className='texts mb-3'>The institute can proudly boast to own a bunch of scientists and technical personnel who are focused and logic-driven.  They are ably supported by the self-motivated administrative and auxiliary staff. The right mix of experience and youth creates a vibrant working atmosphere in the institute.</h6>
          <h6 className='texts mb-3'>The focus of the institute has always been the all-round development of Mulberry Sericulture in the thirteen Indian states it commands in the east and north east so that the Institute can touch the lives of thousands of most marginalized farmers.  We are committed to leave no stone unturned in this noble endeavour and see that it makes meaningful contribution which would remain relevant through the years to come and lift the standard of life of the beneficiaries, quite substantially.</h6>
          <h6 className='texts mb-3'>As everyone would appreciate, the region faces probably the toughest climatic conditions laced with distinct seasons. In this backdrop, development of a climate smart sericulture bouquet would entail our pinnacle priority.  Consistent efforts are on, to develop newer productive mulberry varieties with appropriate packages of practices suitable to the region. Our limited extension mechanism is on its toe to create awareness among the farmers. The training courses offered including PGDS are much sought after and scores of students opt in.</h6>
          <h6 className='texts mb-3'>The stellar role played by this institute in preserving all the goodness of the hardy multivoltine silkworm breed, Nistari is enormous and cannot be overstated.  Nistari being the nature’s gift and fail-safe performer had its share of responsibility along with the socioeconomic status of the farmers of the region, on the rather slow pace of graduation to multi x bivoltine sericulture from the truly traditional multivoltine sericulture</h6>
          <h6 className='texts mb-3'>Recognizing the benefit of cross breed sericulture, farmers have been gradually moving to it, thanks to SK6 x SK7, the bivoltine foundation cross developed in the laboratories here. For years, SK6 x SK7 has been the crown jewel of the bivoltine story in this part of the world. The new improved cross breed from the institute, 12Y x BFC1 is sure to evince greater interests among the farmers of east and north east India owing to higher crop success rate, improved yield and superior silk quality. We are keenly looking forward to similar upgradation from cross breed to bivoltine sericulture in not-so–distant future and to be recognized as the change agent.</h6>
          <h6 className='texts mb-3'>To alter the mindset of the traditional farmers is the biggest challenge that still remains. To go past this roadblock, we as a research institute will strive continuously to develop workable and sustainable models that can be demonstrated on-site.  In this direction, we are more than ready to cooperate and collaborate with institutions across India and abroad on any new exciting ideas, incubate them and facilitate the start-ups with a hope to partner in scale-ups in the years to come.</h6>
          <div className="d-flex justify-content-end align-items-center mb-2">
            <h4>Dr. Jula S. Nair <br />
              Director</h4>
          </div>

        </div> */}
      </div>
    </div>
  )
}

export default Directors