import React from 'react'
import Navbar from "../inc/Navbar";
import Nagarju from "../images/Dr. Yalavarthi Nagaraju sir.jpg";
import looplogo from "../images/loop.jpeg";
import ico from "../images/ico.jpeg"
import graduate from "../images/graduate.jpeg";
import id from "../images/id.jpeg";
import color from "../images/3 color.jpeg";
import rg from "../images/rg.jpeg";
import {Link} from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import { Table } from "react-bootstrap";

function YalavarthiNagaraju() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br/>
            <br />
            <br />
            <ScrollToTop smooth top="100" color="darkBlue"/>
            <div className="sericultural">

                <div className="album py-1">

                    <div className="container mt-2">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house-door" viewBox="0 0 16 16">
  <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4z"/>
</svg> Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/scientist-profile" ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
  <path fill-rule="evenodd" d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
</svg> Back</Link></li>
                                <li className="breadcrumb-item active" aria-current="page"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-lines-fill" viewBox="0 0 16 16">
  <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5m.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1z"/>
</svg> Contact Us</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br />
            <div className="album py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 mt-3 me-5">
                            
                                <img src={Nagarju}  className="card-img-top" alt="..." />
                               
                            
                        </div>
                        <div className="col-md-6 mt-3 ">
                            
                     <br/>
                   
                                <div className="card-body">
                                    <h3 style={{color:"darkBlue"}} className="card-title">Dr. Yalavarthi Nagaraju </h3>
                                    <p className="card-text">Scientist-B (Agricultural Microbiology)</p>

                                    {/* <h6 className='mb-3'>Central Sericultural Research and Training Institute, Berhampore, Westbengal field of Agricultural Microbiology</h6> */}
                                    <hr style={{color:"green"}}/>
                                    <br/>
                                    <a  href='mailto: nagarajulvrth62@gmail.com '>
                                        <h6 style={{color:"grey"}}><svg xmlns="http://www.w3.org/2000/svg" style={{color:'green'}} width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
</svg> nagarajulvrth62@gmail.com</h6> 
                                        </a>
                                        <a  href='mailto: yalavarthi.csb@csb.gov.in '>
                                        <h6 style={{color:"grey"}}><svg xmlns="http://www.w3.org/2000/svg" style={{color:'green'}} width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
</svg> yalavarthi.csb@csb.gov.in</h6> 
                                        </a>
<br/>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <a href='https://loop.frontiersin.org/people/1235001/overview' target="_blank" rel="noopener noreferrer">
                                            <img src={looplogo} height="30px" width="30px" alt='loop'/>
                                            </a>
                                            <a href='https://scholar.google.com/citations?user=Olh0b9kAAAAJ' target="_blank" rel="noopener noreferrer">
                                            <img src={ico} height="30px" width="30px" alt='Scholar'/>
                                            </a>
                                            <a href='https://independent.academia.edu/YalavarthiNagaraju' target="_blank" rel="noopener noreferrer">
                                            <img src={graduate} height="30px" width="30px" alt='Academia'/>
                                            </a>
                                            <a href='https://orcid.org/0000-0003-2326-9427' target="_blank" rel="noopener noreferrer">
                                            <img src={id} height="30px" width="30px" alt=''/>
                                            </a>
                                            <a href='https://www.webofscience.com/wos/author/record/AGU-7827-2022' target="_blank" rel="noopener noreferrer">
                                            <img src={color} height="30px" width="30px" alt='Clarivate/web of Science'/>
                                            </a>
                                            <a href='https://www.researchgate.net/profile/Yalavarthi-Nagaraju' target="_blank" rel="noopener noreferrer">
                                            <img src={rg} height="30px" width="30px" alt='Researchgate'/>
                                            </a>
                                            </div>
                                            <hr style={{color:"green"}}/>
                                    
                                </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <div className="album py-2">
                <div className="container">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
  <li className="nav-item" role="presentation">
    <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">ABOUT</button>
  </li>
  <li className="nav-item" role="presentation">
    <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">RESEARCH</button>
  </li>
  <li className="nav-item" role="presentation">
    <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">PUBLICATION</button>
  </li>
  <li className="nav-item" role="presentation">
    <button className="nav-link" id="education-tab" data-bs-toggle="tab" data-bs-target="#education" type="button" role="tab" aria-controls="education" aria-selected="false">EDUCATION</button>
  </li>
  <li className="nav-item" role="presentation">
    <button className="nav-link" id="projects-tab" data-bs-toggle="tab" data-bs-target="#projects" type="button" role="tab" aria-controls="projects" aria-selected="false">PROJECTS</button>
  </li>
</ul>
<div className="tab-content" id="myTabContent">
  <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
    <br/>
    <h6 className='texts mb-3' >Dr. Yalavarthi Nagaraju is currently working as a Scientist-B at Central Sericultural Research and Training Institute, Berhampore, Westbengal field of Agricultural Microbiology, known for his significant contributions to the study and application of microorganisms in agriculture/sericulture. His expertise encompasses a broad range of topics within the discipline, including plant-microbe interactions, soil microbiology, biogeochemical cycling, bioenergy, biofuels, and nutrient management in crops. Dr. Nagaraju’s research has been pivotal in understanding the biodiversity of soil microorganisms and their role in biotic and abiotic stress management in crops. He has extensively worked on the biosynthesis and characterization of nanoparticles, as well as the design and evaluation of bio-products, consortiums, and biofilms. His work on biological control of plant pathogens has also been noteworthy.</h6>
    <h6 className='texts mb-3'>He had completed his PhD in Agricultural Microbiology at the University of Agricultural Sciences Raichur, Karnataka, where he focused on alleviating salinity stress in chickpea and black gram using native halophilic bacterial isolates. His academic achievements include receiving the ICAR-SRF fellowship for his PhD program and securing the University Gold medal for the highest OGPA</h6>
    <h6 className='texts mb-3'>Professionally, Dr. Nagaraju has held several positions, including Scientist-B at the Central Sericultural Research and Training Institute in Berhampore, West Bengal, and Senior Technical Officer at the ICAR-National Bureau of Agriculturally Important Microorganisms in Uttar Pradesh. He has also served as a Research Associate at the Winter Nursery Center of the Indian Institute of Maize Research in Hyderabad, Agribiotech Foundation, Hyderabad, and Agricultural Research Station, ANGRAU, Amaravathi, Guntur.</h6>
    <h6 className='texts mb-3'>His scholarly work is well-cited, with a notable h-index of 6, reflecting the impact and relevance of his research in the scientific community. Some of his notable research achievements include rapid composting desalinization of alkaline soils, rapid testing kit for acidification of soil by microorganisms,</h6>
    <h6 className='texts mb-3'>Dr. Nagaraju’s dedication to advancing the field of Agricultural Microbiology is evident through his numerous publications and presentations, where he shares his findings and insights, contributing to the current trends and future prospects of halophilic microbes in agriculture and sericulture.</h6>
  </div>
  <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
    <br/>
    <h6 className='texts mb-3'>His research interests are broad and include plant-microbe interactions, soil microbiology, biogeochemical cycling, antimicrobial resistance, bacteriocins, microbial peptides, biofertilizers, biopesticides, novel compound characterization, soil biodiversity, and nutrient management in crops. He has a strong focus on the biodiversity of soil microorganisms and biotic and abiotic stress management in crops. Dr. Nagaraju is also known for his work on nanoparticles biosynthesis, characterization, and evaluation, as well as bio-products design, development, and evaluation</h6>
    <h6 className='texts mb-3'>In terms of expertise, Dr. Nagaraju has contributed significantly to the understanding of soil bio-remediation and soil ecology, particularly concerning the soil and plant microbiome. He has also delved into the use of Next-Generation Sequencing (NGS) and biopesticides to advance agricultural practices. Currently, Dr. Nagaraju is engaged in research that includes the improvement of crop growth and yields under biotic and abiotic stress conditions through the use of endophytic bacteria. His work on halophilic bacteria and their role in plant stress alleviation is particularly noteworthy, as it contributes to sustainable agricultural practices in saline soils.</h6>
  </div>
  <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
    <br/>
    <h6 className='texts mb-3'>1.	Sahu, P.K., Jayalakshmi, K., Tilgam, J., Gupta, A., Nagaraju, Y., Kumar, A., Hamid, S., Singh, H.V., Minkina, T., Rajput, V.D. and Rajawat, M.V.S. (2022) ROS generated from biotic stress: Effects on plants and alleviation by endophytic microbes. Front. Plant Sci. 13:1042936. doi: 10.3389/fpls.2022.1042936. NAAS: 11.62 </h6>
    <h6 className='mb-3'>2.Triveni, S. and Nagaraju, Y.  (2022) Biosynthesis of silver nanoparticles from Pseudomonas fluorescens and their antifungal activity against Aspergillus niger and Fusarium udum. Annals of Applied Biology. <a href='https://doi.org/10.1111/aab.12761' target="_blank" rel="noopener noreferrer"> https://doi.org/10.1111/aab.12761</a>. NAAS-8.75</h6>
    <h6 > 3.	Nagaraju, Y., Gundappagol, R.C.  Mahadevaswamy (2020). Mining saline soils to manifest plant stress alleviating halophilic bacteria. Curr. Microbiol. 77, 2265–2278 (2020). <a href='https://doi.org/10.1007/s00284-020-02028-w' target="_blank" rel="noopener noreferrer">https://doi.org/10.1007/s00284-020-02028-w</a>. NAAS-8.19</h6>
   
    <h6 className='mb-3'>4.	<b>Nagaraju, Y.</b>, Mahadevaswamy, Naik, N.M., Gowdar, S.B., Narayanarao, K. and Satyanarayanarao, K. (2021). ACC deaminase-positive halophilic bacterial isolates with multiple plant growth-promoting traits improve the growth and yield of Chickpea (Cicer arietinum L.) under salinity Stress. Front. Agron. 3:681007. doi: 10.3389/fagro.2021.681007. </h6>
    <h6 className='mb-3'>5.	Sharath, S., Triveni, S., <b>Nagaraju, Y.</b>, Latha, P.C. and Vidyasagar, B. (2021). The role of phyllosphere bacteria in improving cotton growth and yield under drought conditions. Front. Agron. 3:680466. doi: 10.3389/fagro.2021.680466. </h6>
    <h6 className='mb-3'>6.	Prasanna Kumar, B., Trimurtulu, N., Vijaya Gopal, A. and Nagaraju, Y. (2022). Impact of culturable endophytic bacteria on soil aggregate formation and Peanut (Arachis hypogaea L.) growth and yield under drought conditions. Curr Microbiol. 79:308. <a href='https://doi.org/10.1007/s00284-022-03000-6'>https://doi.org/10.1007/s00284-022-03000-6</a> NAAS: 8.19</h6>
    <h6 className='mb-3'> 7.	<b>Nagaraju, Y</b>., Mahadevaswamy, Gowder, S.B. (2022). Harnessing the saline soil-inhabiting bacteria for antagonistic, antibiotic resistance, and plant growth-promoting attributes. Vegetos. <a href='https://doi.org/10.1007/s42535-022-00466-4'>https://doi.org/10.1007/s42535-022-00466-4</a> NAAS: 5.42</h6>
    <h6 className='mb-3'>8.	<b>Y. Nagaraju, </b>S. Triveni, R. Subhash Reddy, B. Vidyasagar, B.P.Kumar, K.D. Chari (2017) Screening of zinc solubilizing and potassium releasing bacterial and fungal isolates from different rhizosphere soils. The Bioscan. 11(4): 2187-2192.</h6>
    <h6 className='mb-3'>9. <b>	Y. Nagaraju,</b> Mahadevaswamy, Gundappagol and Nagaraj M Naik (2020) Response of black gram to seed biopriming with facultative halophilic bacteria under salinity. International Journal of Environment and Climate change. 10(12): 561-571.</h6>
    <h6>10.	Y. Nagaraju, S. Triveni, A. Vijaya Gopal, G Thirumal, K. Bhavya and B. Prasanna Kumar (2017) In vitro Screening of Zinc solubilizing rhizospheric isolates for Agrochemicals compatibility. Agriculture Update. 12: 1500-1505. </h6>
  </div>
  <div className="tab-pane fade" id="education" role="tabpanel" aria-labelledby="education-tab">
    <br/>
   
    <div className="album py-1">
        <div className="container">

          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th> <center style={{ color: "maroon" }}>SL.</center></th>
                <th><center style={{ color: "maroon" }}> Degree</center></th>
                <th><center style={{ color: "maroon" }}>Year of Passing</center></th>
                <th><center style={{ color: "maroon" }}> University / College</center></th>

              </tr>
            </thead>
            <tbody>
              


              <tr >
                <td > <center className='mb-2 mt-2'>1</center></td>
                <td><center className='mb-2 mt-2'>Ph.D. in Agricultural Microbiology</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>2019</center></td>
                <td><center className='mb-2 mt-2'><a href='https://uasraichur.karnataka.gov.in/english' target="_blank" rel="noopener noreferrer">University of Agricultural Sciences Raichur, Karnataka</a></center></td>



              </tr>
              <tr >
                <td > <center className='mb-2 mt-2'>2</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>M.Sc. in Agricultural Microbiology</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>2016</center></td>
                <td><center className='mb-2 mt-2'> <a href='https://www.pjtsau.edu.in/' target="_blank" rel="noopener noreferrer"> Professor Jayashankar Telangana State Agricultural University, Hyderabad</a></center></td>



              </tr>
              <tr >
                <td > <center className='mb-2 mt-2'>3</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>B.Sc. (Agriculture)</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>2014</center></td>
                <td><center className='mb-2 mt-2'><small><a href='https://angrau.ac.in/' target="_blank" rel="noopener noreferrer">Acharya N.G Ranga Agricultural University, Agricultural College, Bapatla, Guntur</a></small></center></td>



              </tr>


            </tbody>
          </Table>
        </div>
      </div>
  </div>
  <div className="tab-pane fade" id="projects" role="tabpanel" aria-labelledby="projects-tab">
    <br/>
    <h6>Update Soon...</h6>
  </div>
</div>
</div>
</div>
<br/>
<br/>
        </div>
    )
}

export default YalavarthiNagaraju