import React from 'react'
import Navbar from "../inc/Navbar";
import Javidur from "../images/javid.png";
import looplogo from "../images/loop.jpeg";
import ico from "../images/ico.jpeg"
import graduate from "../images/graduate.jpeg";
import id from "../images/id.jpeg";
import color from "../images/3 color.jpeg";
import rg from "../images/rg.jpeg";
import {Link} from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import { Table } from "react-bootstrap";

function Javid() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br/>
            <br />
            <ScrollToTop smooth top="100" color="darkBlue"/>
            <div className="sericultural">

                <div className="album py-1">

                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house-door" viewBox="0 0 16 16">
  <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4z"/>
</svg> Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/scientist-profile" ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
  <path fill-rule="evenodd" d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
</svg> Back</Link></li>
                                <li className="breadcrumb-item active" aria-current="page"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-lines-fill" viewBox="0 0 16 16">
  <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5m.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1z"/>
</svg> Contact Us</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br />
            <div className="album py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 mt-3 me-5">
                            
                                <img src={Javidur}  className="card-img-top" alt="Dr.Javid Ur Rahaman" />
                               
                            
                        </div>
                        <div className="col-md-6 mt-3 ">
                            
                     <br/>
                   
                                <div className="card-body">
                                    <h3 style={{color:"darkBlue"}} className="card-title">Dr. Javid Ur Rahman </h3>
                                    <p className="card-text">Scientist-B (Silkworm Breeding and Genetics)</p>

                                    <hr style={{color:"green"}}/>
                                    <br/>
                                    {/* <a  href='mailto: nagarajulvrth62@gmail.com '>
                                        <h6 style={{color:"grey"}}><svg xmlns="http://www.w3.org/2000/svg" style={{color:'green'}} width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
</svg> nagarajulvrth62@gmail.com</h6> 
                                        </a>
                                        <a  href='mailto: yalavarthi.csb@csb.gov.in '>
                                        <h6 style={{color:"grey"}}><svg xmlns="http://www.w3.org/2000/svg" style={{color:'green'}} width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
</svg> yalavarthi.csb@csb.gov.in</h6> 
                                        </a> */}
<br/>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <a href='https://www.scopus.com/authid/detail.uri?authorId=57219945438' target="_blank" rel="noopener noreferrer">
                                            <img src={looplogo} height="30px" width="30px" alt='loop'/>
                                            </a>
                                            <a href='https://scholar.google.com/citations?hl=en&authuser=1&user=_owCGOAAAAAJ' target="_blank" rel="noopener noreferrer">
                                            <img src={ico} height="30px" width="30px" alt='Scholar'/>
                                            </a>
                                            <a href='https://www.reviewercredits.com/user/drjavid763gmail-com/' target="_blank" rel="noopener noreferrer">
                                            <img src={graduate} height="30px" width="30px" alt='Academia'/>
                                            </a>
                                            <a href='http://orcid.org/0000-0002-5190-6019' target="_blank" rel="noopener noreferrer">
                                            <img src={id} height="30px" width="30px" alt=''/>
                                            </a>
                                            <a href='https://www.webofscience.com/wos/author/record/56710394' target="_blank" rel="noopener noreferrer">
                                            <img src={color} height="30px" width="30px" alt='Clarivate/web of Science'/>
                                            </a>
                                            <a href='https://www.researchgate.net/profile/Javid-Ur-Rahman' target="_blank" rel="noopener noreferrer">
                                            <img src={rg} height="30px" width="30px" alt='Researchgate'/>
                                            </a>
                                            </div>
                                            <hr style={{color:"green"}}/>
                                    
                                </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <div className="album py-2">
                <div className="container">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
  <li className="nav-item" role="presentation">
    <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">ABOUT</button>
  </li>
  <li className="nav-item" role="presentation">
    <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">RESEARCH</button>
  </li>
  <li className="nav-item" role="presentation">
    <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">PUBLICATION</button>
  </li>
  <li className="nav-item" role="presentation">
    <button className="nav-link" id="education-tab" data-bs-toggle="tab" data-bs-target="#education" type="button" role="tab" aria-controls="education" aria-selected="false">EDUCATION</button>
  </li>
  <li className="nav-item" role="presentation">
    <button className="nav-link" id="projects-tab" data-bs-toggle="tab" data-bs-target="#projects" type="button" role="tab" aria-controls="projects" aria-selected="false">PROJECTS</button>
  </li>
</ul>
<div className="tab-content" id="myTabContent">
  <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
    <br/>
    <h6 className='texts mb-3' >Dr. Javid Ur Rahman is currently working as Scientist-B at Silkworm Breeding and Genetics at Central Sericultural Research and Training Institute, Berhampore, West Bengal</h6>
    
  </div>
  <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
    <br/>
    <h6 className='texts mb-3'>Animal breeding for climate resilience, disease resistance and functional traits, Genomic Selection, Gene Editing, Genome Wide Association Studies, SNP genotyping, Microsatellite genotyping</h6>

  </div>
  <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
    <br/>
    <h6 className='texts mb-3'>1.Rahman, J.U.*, Kumar, D., Singh, S.P., Shahi, B.N., Ghosh, A.K., Kumar A, Dar, A.H., Ahmad S.F., Pathak, A. and Verma, M.K. Genetic diversity, population structure analysis and codon substitutions of Indicine Badri cattle using ddRAD sequencing. 3 Biotech 14, 46 (2024).</h6>
    <h6>2. Rahman, J.U.*, Kumar, D., Singh, S.P., Shahi, B.N., Ghosh, A.K., Verma, M.K, Pathak, Dar, A.H., Kumar, A. and Sharma, R.K. Genome-wide identification and annotation of SNPs and their mapping in candidate genes related to milk productionand fertility traits in Badri cattle.Trop Anim Health Prod, 55, 117 (2023).</h6>
    <h6> 3. Dar, A.H., Kumar, S., Mukesh, M., Ahmad, S. F., Singh, D. V., Sharma, R. K., Ghosh, A.K., Singh, B., Rahman, J.U. and Sodhi, M. Genetic characterization and population structure of different coat colour variants of Badri cattle. Mol. Biol. Rep., 47(11), 8485-8497. 2020.</h6>
    <h6>4. Dar, A.H., Singh, S. K., Rahman, J.U.*, and Ahmad, S.F. Effects of Probiotic Lactobacillus acidophilus and/or Prebiotic Mannan oligosaccharides on Growth Performance, Nutrient Utilisation, Blood metabolites, Faecal Bacteria and Economics of Crossbred Calves. Iran J Vet Res. 2022;23(4):322-330.</h6>
  </div>
  <div className="tab-pane fade" id="education" role="tabpanel" aria-labelledby="education-tab">
    <br/>
   
    <div className="album py-1">
        <div className="container">

          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th> <center style={{ color: "maroon" }}>Degree</center></th>
                <th><center style={{ color: "maroon" }}> Year</center></th>
                <th><center style={{ color: "maroon" }}>Thesis title</center></th>
                <th><center style={{ color: "maroon" }}> Institute</center></th>

              </tr>
            </thead>
            <tbody>

              <tr >
                <td > <center className='mb-2 mt-2'>Ph.D</center></td>
                <td><center className='mb-2 mt-2'>2018-2022</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>Assessment of genetic diversity and identification of novel SNPs in Badri cattle using genotyping by sequencing approach</center></td>
                <td><center className='mb-2 mt-2'>GB Pant University of Agriculture and Technology, Uttarakhand</center></td>



              </tr>
              <tr >
                <td > <center className='mb-2 mt-2'>M.V. Sc.</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>20216-2018</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>Comparative Evaluation of Functional Traits in Indigenous and Crossbred Cattle</center></td>
                <td><center className='mb-2 mt-2'> ICAR-National Dairy Research Institute, Karnal, Haryana</center></td>



              </tr>
              <tr >
                <td > <center className='mb-2 mt-2'>B.V. Sc. & A.H</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>2011-2016</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>-</center></td>
                <td><center className='mb-2 mt-2'><small>Sher-e-Kashmir University of Agricultural Sciences and Technology, Srinagar</small></center></td>



              </tr>


            </tbody>
          </Table>
        </div>
      </div>
  </div>
  <div className="tab-pane fade" id="projects" role="tabpanel" aria-labelledby="projects-tab">
    <br/>
    <h6>Update Soon...</h6>
  </div>
</div>
</div>
</div>
<br/>
<br/>
        </div>
    )
}

export default Javid