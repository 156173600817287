import React from 'react'
import Navbar from "../inc/Navbar";
import DrOshiniphoto from "../images/dr.Oshini.jpg";

import ico from "../images/ico.jpeg"

import id from "../images/id.jpeg";

import rg from "../images/rg.jpeg";
import {Link} from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import { Table } from "react-bootstrap";

function DrOshini() {
    return (
        <div>
            <Navbar />
            <br />
            <br/>
            <br />
            <br />
            <br />
            <br />
            <ScrollToTop smooth top="100" color="darkBlue"/>
            <div className="sericultural">

                <div className="album py-1">

                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house-door" viewBox="0 0 16 16">
  <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4z"/>
</svg> Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/scientist-profile" ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
  <path fill-rule="evenodd" d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
</svg> Back</Link></li>
                                <li className="breadcrumb-item active" aria-current="page"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-lines-fill" viewBox="0 0 16 16">
  <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5m.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1z"/>
</svg> Contact Us</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br />
            <div className="album py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 mt-3 me-5">
                            
                                <img src={DrOshiniphoto}  className="card-img-top" alt="DrOshiniphoto"/>
                               
                            
                        </div>
                        <div className="col-md-6 mt-3 ">
                            
                     <br/>
                   
                                <div className="card-body">
                                    <h3 style={{color:"darkBlue"}} className="card-title">Dr. Oshin  </h3>
                                    <p className="card-text">Scientist-B (animals for certain traits so that entire populations can be enhanced)</p>

                                   
                                    <hr style={{color:"green"}}/>
                                    <br/>
                                    {/* <a  href='mailto: nagarajulvrth62@gmail.com '>
                                        <h6 style={{color:"grey"}}><svg xmlns="http://www.w3.org/2000/svg" style={{color:'green'}} width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
</svg> nagarajulvrth62@gmail.com</h6> 
                                        </a> */}
                                        <a  href='mailto: oshin2023.csb@csb.gov.in '>
                                        <h6 style={{color:"grey"}}><svg xmlns="http://www.w3.org/2000/svg" style={{color:'green'}} width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
</svg> oshin2023.csb@csb.gov.in</h6> 
                                        </a>
<br/>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                          
                                            <a href='https://scholar.google.com/citations?hl=en&authuser=1&user=VqculIUAAAAJ' target="_blank" rel="noopener noreferrer">
                                            <img src={ico} height="30px" width="30px" alt='Scholar'/>
                                            </a>
                                    
                                            <a href='https://orcid.org/0000-0003-4998-9457' target="_blank" rel="noopener noreferrer">
                                            <img src={id} height="30px" width="30px" alt=''/>
                                            </a>
                                          
                                            <a href='https://www.researchgate.net/profile/Oshin-Togla' target="_blank" rel="noopener noreferrer">
                                            <img src={rg} height="30px" width="30px" alt='Researchgate'/>
                                            </a>
                                            </div>
                                            <hr style={{color:"green"}}/>
                                    
                                </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <div className="album py-2">
                <div className="container">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
  <li className="nav-item" role="presentation">
    <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">ABOUT</button>
  </li>
  <li className="nav-item" role="presentation">
    <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">RESEARCH</button>
  </li>
  <li className="nav-item" role="presentation">
    <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">PUBLICATION</button>
  </li>
  <li className="nav-item" role="presentation">
    <button className="nav-link" id="education-tab" data-bs-toggle="tab" data-bs-target="#education" type="button" role="tab" aria-controls="education" aria-selected="false">EDUCATION</button>
  </li>
  <li className="nav-item" role="presentation">
    <button className="nav-link" id="projects-tab" data-bs-toggle="tab" data-bs-target="#projects" type="button" role="tab" aria-controls="projects" aria-selected="false">PROJECTS</button>
  </li>
</ul>
<div className="tab-content" id="myTabContent">
  <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
    <br/>
    <h6 className='texts mb-3' >Scientist-B, Silkworm Breeding and Genetics
Central Sericulture Research & Training Institute, Berhampore, West Bengal
Central Silk Board, Bangalore</h6>
   
  </div>
  <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
    <br/>
    <h6 className='texts mb-3'>	I am a Veterinary graduate alumna of Lala Lajpat Rai University of veterinary and animal sciences, LUVAS, Hisar, Haryana. In my Master program of research, I attempted to seek the association of milk related traits to SNP markers of PHKA2 gene present on Chr X in Sahiwal cattle of NDRI Karnal- India’s herd. Considering the genomic variation, SNP studies have marked their dominance over other research aspects, however structural variation studies are still in infancy particularly for Indian cattle genome. Looking for the further possibilities, I conducted extensive research in Copy number variations in my doctoral research. It was focused on finding and annotating the Genome-wide Copy Number Variations in Sahiwal and Karan Fries cattle. Through this work, I developed strong skills of analytical tools used in the field of Quantitative Genetics, Genome Analysis, Evolutionary Genetics, Animal breeding and so on and I acquired hands-on experience of R studio, Linux environment handling big data files for carrying various further analysis for genetic interpretation.</h6>
    <h6 className='texts mb-3'>Well-acquainted with theoretical and practical knowledge of analytical tools to tackle a wide range of problems related to my fields of interest: Quantitative Genetics and Genome Analysis; Animal breeding</h6>
  </div>
  <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
    <br/>
    <h6 className='texts mb-3'>1.	Mukherjee, S., Mukherjee, A., Kumar, S., Verma, H., Bhardwaj, S., Oshin,T., ... & Rajkhowa, C. (2022). Genetic Characterization of Endangered Indian Mithun (Bos frontalis), Indian Bison/Wild Gaur (Bos gaurus) and Tho-tho Cattle (Bos indicus) Populations Using SSR Markers Reveals Their Diversity and Unique Phylogenetic Status. Diversity, 14(7), 548.</h6>
    <h6>2. Bhardwaj S, Oshin T, Mumtaz S, Yadav N, Tiwari J, Muansangi L, Illa SK, Wani YM, Mukherjee S, Mukherjee A. Comparative assessment of the effective population size and linkage disequilibrium of Karan Fries cattle revealed viable population dynamics. Animal Bioscience. 2024 May 1;37(5).</h6>
    <h6> 3.	Oshin,T., Deb, S. M., Kadyan, S., Kumar, S., Nandhini, P. B., & Mukherjee, A. (2021). Association of milk production and udder type traits with polymorphism of phosphorylase kinase regulatory subunit alpha-2 gene in Sahiwal cattle. The Indian Journal of Animal Sciences, 91(8). </h6>
    <h6>4. Oshin,T., Deb, S. M., Kadyan, S., Nandhini, P. B., Kumar, S., & George, L.(2021). Genetic Profiling of PHKA2 Gene and its Association with Udder Type Traits in Indian Dairy Cattle. Indian Journal of Animal Research, 1, 6.</h6>
  </div>
  <div className="tab-pane fade" id="education" role="tabpanel" aria-labelledby="education-tab">
    <br/>
   
    <div className="album py-1">
        <div className="container">

          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th> <center style={{ color: "maroon" }}>SL.</center></th>
                <th><center style={{ color: "maroon" }}> Degree</center></th>
                <th><center style={{ color: "maroon" }}>Year of Passing</center></th>
                <th><center style={{ color: "maroon" }}> University / College</center></th>

              </tr>
            </thead>
            <tbody>

              <tr >
                <td > <center className='mb-2 mt-2'>1</center></td>
                <td><center className='mb-2 mt-2'>Ph. D (Animal Genetics and Breeding)</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>2019-23</center></td>
                <td><center className='mb-2 mt-2'>National Dairy Research Institute, Karnal</center></td>



              </tr>
              <tr >
                <td > <center className='mb-2 mt-2'>2</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>MVSc. (Animal Genetics and Breeding)</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>2017-19</center></td>
                <td><center className='mb-2 mt-2'> National Dairy Research Institute, Karnal</center></td>



              </tr>
              <tr >
                <td > <center className='mb-2 mt-2'>3</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>BVSc. & AH</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>2012-17</center></td>
                <td><center className='mb-2 mt-2'><small>Lala Lajpat Rai University of veterinary and animal science,Hisar </small></center></td>



              </tr>


            </tbody>
          </Table>
        </div>
      </div>
  </div>
  <div className="tab-pane fade" id="projects" role="tabpanel" aria-labelledby="projects-tab">
    <br/>
    <h6>Update Soon...</h6>
  </div>
</div>
</div>
</div>
<br/>
<br/>
        </div>
    )
}

export default DrOshini