import React from 'react'
import Navbar from '../inc/Navbar'

import {Link} from "react-router-dom";

function Rti14() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className="sericultural">

<div className="album py-1">

    <div className="container mt-3">
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                
                <li className="breadcrumb-item"><Link to="/" href="#"> Home</Link></li>
                <li className="breadcrumb-item"><Link to="/rti" href="#"> Back</Link></li>
                <li className="breadcrumb-item active" aria-current="page">RTI14</li>
            </ol>
        </nav>
    </div>
</div>
</div>
            <br />
            <div className="album py-1">
                <div className="container ">
                    <h4 style={{ color: "darkblue" }}>Details in respect of the information, available to or held by it, reduced in an electronic form.

     

</h4>
                    <br />
                    <br />
                    <h6>1.Director‘s Message</h6>
                    <br/>
    <h6> 2. Scientists Profile</h6>
    <br/>
    <h6>3. Advertisement and Tenders</h6>
    <br/>
    <h6>4. Circulars</h6>
    <br/>
    <h6>5. Right to Information Ac, 2005 (Item No. :: i  to xvii points)</h6>
    <br/>
    <h6>6. Gallery (Picture relating to findings of mulberry sericulture)    </h6>
    <br/>
    <h6>7. Publication (Publication of research findings, News bulletin, leaflets etc.)</h6>
    <br/>
    <h6>8. Links to different State Govt./Central Ograni9zation (Related only)    </h6>
    <br/>
    <h6>9. Frequently Asked Question (FAQ) on mulberry sericulture    </h6>
    <br/>
    <h6> 10. Feedback (of the farmers, visitors & Dignitaries)    </h6>
    <br/>
    <h6>11. Contact us. etc.  (Address, contact numbers, Fax, e-mail etc.)    </h6>


    






                </div>
            </div>
            <br />
            <br />

        </div>
    )
}

export default Rti14