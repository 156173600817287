import React from 'react'
import Navbar from '../inc/Navbar'
import { Link } from "react-router-dom";
import Picturerti from "../images/Picture1.png"
function Rti1() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br />
<br/>

            <div className="sericulturalberhamporemurshidabad">
                <div className="album py-1">
                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" style={{ color: "white" }} href="#">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">RTI - 1</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br />
            <div className="album py-1">

                <div className="container ">
                    <h4 className='mb-3'>The Particulars of Organization, Function & Duties  :</h4>
                    <p style={{ color: "maroonm" }} >Organization:: </p>
                    <br />
                    <h6 className='texts mb-3' style={{ color: "black" }}>The Central Sericultural Research & Training Institute(CSRTI), Berhampore is a premier R&D Institute established in the year 1943 to provide the research and development support for promotion of silk industry in the eastern region. Presently, it functions under the administrative control of Central Silk Board (CSB), a statutory body under the Ministry of Textiles, Govt. of India. During 1943, realizing the importance of silk industry in providing employment and export opportunities, a policy decision was taken to encourage and support the production of silk. Therefore, in order to revive this ailing industry from the ravages of diseases, inferior quality and poor productivity, the Central Sericultural Research Station(CSRS) was established at Berhampore in the erstwhile Bengal in the same year. In 1969, the Research Station was merged with Central Silk Board. In 1971, as per the Board’s decision for dissemination of research findings from Lab. to land, Training and Extension activities were started and the station was renamed as Central Sericultural Research & Training Institute in the year 1975-76. This has resulted qualitative and quantitative improvement in silk production in the eastern region.
                    </h6>

                    <h6 className='texts mb-3'>The CSR&TI, Berhampore is having 56.74 acres of land, of which 30 acres are under plantation, 1.5 acres under office building, Lab., rearing houses and student’s hostel, 15 acres under residential areas and remaining acres for road, pond etc. A total of 104 residential staff quarters, 3 office buildings, 9 rearing houses, 01 farm house, one student hostel (100 bed capacity), 01 work shop and 01 guest house are available.</h6>
                    <h6 className='texts mb-3'>The Institute is envisaged its R&D activities and extension with major divisions namely Host Plant (Host plant improvement; Host plant protection; Agronomy/Soil science/Farm management), Silkworm (Silkworm improvement : Multivoltine, Bivoltine, Post Cocoon; Silkworm protection: Silkworm Pathology, Silkworm Entomology; and Silkworm rearing management & seed technology) Biotechnology (Host plant biotechnology; Silkworm biotechnology), SEEM (Extension and Publicity), Training (Capacity building & Training; Library), PMCE (PMCE; Statistics; Information communication & technology) and Administration(Accounts; Establishment; Store; Maintenance & Vehicle and Computer). The Institute & its nested units covering in 13 states have a substantial manpower of 232 staff & workers comprising with Director, Scientists, Technical, Administrative & MTS and 184 SFW’s & TSFWs (upto March-2020).</h6>
                    <h6 className='texts'>Central Sericultural Research & Training institute (CSRTI) was established for rendering research, developmental, technical, technological, extension support to the silk industry in Eastern and North Eastern India (East: West Bengal, Odisha, Bihar, Jharkhand, Chhattisgarh; North-East: Arunachal Pradesh, Assam, Manipur, Meghalaya, Mizoram, Nagaland, Sikkim and Tripura). CSRTI-Berhampore is on its 77th year and contributed to the development of mulberry varieties, silkworm breeds/hybrids, package of practices for mulberry cultivation, silkworm rearing and  innovations/products/processes suitable to the region.</h6>


                </div>
            </div>
            <div className="album py-0">
                <div className="container">
                    <div className="row ">
                        <div className="col-md-4" >
                            <div className="card">
                                <img src={Picturerti} className="card-img-top" alt="Picturerti" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <br />
            <div className="album py-1">
                <div className="container ">
                <h6 className='texts mb-3'> The institute conducts research on all fields of sericulture from soil to silk. Entrusted with the mandate to carry out research, it has made remarkable breakthrough and outstanding contributions for the overall development of silk industry in the region. In the plant side, improved mulberry varieties, suitable to the agro climatic condition of different areas, have been developed along with its package of practices by the way of integrated nutrient management (INM). Regarding silkworm, region and season specific high yielding silkworm breeds have been evolved. The institute has developed of diseases and pests of mulberry and silkworm, which are being popularized in the fields and proved to be effective to minimize crop losses. The Institute The institute undertakes R&D projects sponsored by various institutions/ organizations. CSRTI-Berhampore regularly publishes technology pamphlets/booklets for the benefit of stakeholders in various languages. The scientists contribute research articles in national and international journals, scientific seminars/symposia.</h6>

                <h6 className='texts mb-3'>Under the aegis of Kalyani University-Kalyani, CSRTI-Berhampore offers 15 months Post-Graduate Diploma in Sericulture (PGDS) for students across India in Mulberry Sericulture.  The institute conducts several training programmes (upto 30 days) since, 1975 in various disciplines to the farmers, reelers, CSB & Non-CSB officials, students etc. CSRTI-Berhampore also facilitates M.Sc. students for Dissertation Works on payment basis. The training division has well-equipped classrooms, library and hostel facilities.</h6>

                <h6 className='texts mb-3'> <b style={{color:"maroon"}}>Extension Network:</b> The Institute has an effective extension network for dissemination of the sericultural technologies to the farmers through Transfer of technologies and other Technology Demonstration Programmes. Besides, the Institute disseminates the sericultural technologies to the farmers through its nested units i.e. Regional Sericultural Research Stations (RSRSs) and Research Extension Centers (RECs) located in different states (West Bengal, Sikkim, Jharkhand, Orissa and all North-Eastern states) for fine tuning of the technologies developed. RSRS is entrusted with the regional problems of sericulture and overall extension and development. It also conducts the adoptive research for the main institute for testing suitability in the region. Similarly, RECs are also entrusted with the responsibilities for testing/popularizing new technologies through demonstration at farmers’ level.</h6>

                <h6 className='texts mb-3'><b style={{color:"maroon"}}>Functions & Duties : </b> Central Sericultural Research & Training institute (CSRTI) was established for rendering research, developmental, technical, technological, extension support to the silk industry in Eastern and North Eastern India (East: West Bengal, Odisha, Bihar, Jharkhand, Chhattisgarh; North-East: Arunachal Pradesh, Assam, Manipur, Meghalaya, Mizoram, Nagaland, Sikkim and Tripura). CSRTI-Berhampore is on its 77th year and contributed to the development of mulberry varieties, silkworm breeds/hybrids, package of practices for mulberry cultivation, silkworm rearing and  innovations/products/processes suitable to the region. CSRTI-Berhampore, West Bengal functions under the direct control of the Member Secretary, Central Silk Board, Bangalore and determined to achieve following vision, mission and mandates.</h6>

                <h6 className='texts mb-3'>The CSR&TI, Berhampore is having 56.74 acres of land, of which 30 acres are under plantation, 1.5 acres under office building, Lab., rearing houses and student’s hostel, 15 acres under residential areas and remaining acres for road, pond etc. A total of 104 residential staff quarters, 3 office buildings, 9 rearing houses, 01 farm house, one student hostel (100 bed capacity), 01 work shop and 01 guest house are available.</h6>

                </div>
            </div>
        </div>
    )
}

export default Rti1