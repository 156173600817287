import React from 'react'
import Navbar from '../inc/Navbar'
import {Link} from "react-router-dom";

function Extension() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>

        <div className="sericultural">

<div className="album py-1">

    <div className="container mt-3">
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/" href="#">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Extension</li>
            </ol>
        </nav>
    </div>
</div>
</div>
<div className="album py-1">

    <div className="container">
        <br/>
    <h1 style={{color:"purple"}}><b>SEEM</b></h1>
    <br/>
    <h5 style={{color:"maroon"}}>SCIENTISTS: </h5>

<br/>
<h6 className='mb-1'>1.Dr. Srinivasa G., Scientist-D</h6>

<br/>
<h5 style={{color:"maroon"}}>Technical Staffs :</h5>
<h6 className='mb-3'>1. Sri Kailash Prasad Sharma, STA</h6>
<h6 className='mb-3'>2. Sri Goutam Mandal , STA</h6>
<h6 className='mb-3'>3. Md.Sirajul Islam , STA</h6>
<br/>
<h5 className='mb-3' style={{color:"maroon"}}>ACTIVITIES: </h5>
<h6 className='mb-3'>1. Undertaking  Research Project / Programmes</h6>
<h6 className='mb-3'>2. Demonstration and Extension Communication Programme</h6>
<h6 className='mb-3'>3. Preparation of Farmers data base </h6>
<h6 className='mb-3'>4. Organization of Review Meeting</h6>
<h6 className='mb-3'>5. Preparation of Reports and Minutes</h6>
<h6 className='mb-3'>6. Organization of Resham Krishi Mela</h6>
<h6 className='mb-3'>7. Monitoring of  CPP (Cluster Promotion Programme) farmers of three clusters implemented during XIth plan</h6>
<h6 className='mb-3'>8. Publication of user friendly technologies</h6>
<h6 className='mb-3'>9. Conceptualization of publicity materials for effective Transfer of Technology</h6>
<h6 className='mb-3'>10. Additional Programmes </h6>

<div className="album py-1">

    <div className="container">
    <p className='mb-3'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-all" viewBox="0 0 16 16">
  <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
  <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
</svg> Co-ordination with DoS/DoT(S), NSSO, NGO.</p>
<p className='mb-3'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-all" viewBox="0 0 16 16">
  <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
  <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
</svg> Participation in Extension Communication Program organized by NSSO & DoT (S).</p>

<p className='mb-3'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-all" viewBox="0 0 16 16">
  <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
  <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
</svg> Participation in SLSCC meeting of different states.</p>
<p className='mb-3'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-all" viewBox="0 0 16 16">
  <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
  <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
</svg> Preparation of Annual Action plan and Results Framework Document for nested units - monitoring & supervision of their activities.</p>
<p className='mb-3'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-all" viewBox="0 0 16 16">
  <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
  <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
</svg> Attention to emerging field problems reported by DoS/DoT(S), NSSO, NGO</p>


        </div>
    </div>

    </div>
    </div>
             <br/>
    </div>
  )
}

export default Extension