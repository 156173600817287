import React from 'react'
import  Navbar  from  "../inc/Navbar";
import Table from 'react-bootstrap/Table';
import PosterSilworm from './PosterSilkwor';
import PosterSilwormbengali from './PosterSilkwormbengali';
import PosterGanga from './PosterGanga';
import {Link} from "react-router-dom";
import PosterGbengali from './PosterGbengali';
import PosterEconoMulberry from './PosterEconoMulberry';
import PosterEconBnegali from './PosterEconBengali';
import PosterS1635 from './PosterS1635';
import Posters1635B from './Posters1635B';
import PosterNursery from './PosterNursery';
import Posterbn from './Posterbn';
import PosterC2038 from './PosterC2038';
import PosterCB from './PosterCB';
import PosterRearing from './PosterRearing ';
import Postertak from './Postertak';
import Postertut from './Postertut';
import Postermajor from './Postermajor';


function Poster() {
  return (
    <div>
        <Navbar/>

        <br/>
        <br/>
        <br/>
        <br/>
        
        <br/>
        <br/>
        {/* <div className="sericultural">

<div className="album py-1">

    <div className="container mt-3">
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/" href="#"> Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Poster</li>
            </ol>
        </nav>
    </div>
</div>
</div> */}
        <br/>
       
        <center style={{color:"maroon"}}><h3>PUBLICATION</h3></center>
        <br/>
        <div className="album py-2">

<div className="container">
  <h4>Poster</h4>
        <Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>Title of Brochure / pamphletse</th>
          <th>Vernacular</th>
          <th>View/Download</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>Silkworm Disease Management</td>
          <td>English</td>
          <td><PosterSilworm/></td>
        </tr>
        <tr>
          <td>2</td>
          <td>পলু পোকার রোগ ও স্বাস্থ্য ব্যবস্থাপনা</td>
          <td>Bengali</td>
          <td><PosterSilwormbengali/></td>
        </tr>
        <tr>
          <td>3</td>
          <td>GANGA (C-1360) A High Yielding Mulberry Variety for Irrigated Conditions</td>
          <td>English</td>
          <td><PosterGanga/></td>
        </tr>
        <tr>
          <td>4</td>
          <td>গঙ্গা (সি- ১৩৬০)  সেচ সেবিত অঞ্চলের জন্য একটি উচ্চ -ফলনশীল তুঁত প্রজাতি</td>
          <td>Bengali</td>
          <td><PosterGbengali/></td>
        </tr>
        <tr>
          <td>5</td>
          <td>Economics of Mulberry Sericulture in West Bengal (1 Acre or 3 Bigha/Year)s</td>
          <td>English</td>
          <td><PosterEconoMulberry/></td>
        </tr>
        <tr>
          <td>6</td>
          <td>পশ্চিমবঙ্গে তুঁতরেশম চাষের অর্থনীতি (1 একর বা 3 বিঘা প্রতি বছর)</td>
          <td>Bengali</td>
          <td><PosterEconBnegali/></td>
        </tr>

        <tr>
          <td>7</td>
          <td>S-1635 Popular Mulberry Variety for Eastern & North-Eastern India</td>
          <td>English</td>
          <td><PosterS1635/></td>
        </tr>
        <tr>
          <td>8</td>
          <td>এস-১৬৩৫ পুর্ব এবং উত্তর পুর্ব ভারতের  জন্য একটি উচ্চ ফলনশীল জনপ্রিয় তুঁত প্রজাতি</td>
          <td>Bengal</td>
          <td><Posters1635B/></td>
        </tr>
        <tr>
          <td>9</td>
          <td>   Mulberry Nursery</td>
          <td>English</td>
          <td><PosterNursery/></td>
        </tr>
        <tr>
          <td>10</td>
          <td>  তুঁত নার্সারি</td>
          <td>Bengali</td>
          <td><Posterbn/></td>
        </tr>
        <tr>
          <td>11</td>
          <td> C – 2038 High Yielding Mulberry Variety for Irrigated Conditions</td>
          <td>English</td>
          <td><PosterC2038/></td>
        </tr>
        <tr>
          <td>12</td>
          <td>  সি – ২০৩৮ সেচসেবিত  অঞ্চলের জন্য উচ্চ ফলন্শীল  তুঁত  প্রজাতি  </td>
          <td>Bengali</td>
          <td><PosterCB/></td>
        </tr>
        <tr>
          <td>13</td>
          <td>    Silkworm Shelf Rearing (shoot Feeding) </td>
          <td>English</td>
          <td><PosterRearing/></td>
        </tr>
        <tr>
          <td>14</td>
          <td>  তাক পদ্ধতিতে পলুপাল </td>
          <td>Bengali</td>
          <td><Postertak/></td>
        </tr>
        <tr>
          <td>15</td>
          <td>   Major Pests of Mulberry & their Management</td>
          <td>English</td>
          <td><Postermajor/></td>
        </tr>
        <tr>
          <td>16</td>
          <td>   তুঁত পাতার প্রধান ক্ষতিকারক পোকা ও তার প্রতিকার</td>
          <td>Bengali</td>
          <td><Postertut/></td>
        </tr>
      </tbody>
    </Table>
  





 
    </div>
    </div>
    <br/>
    </div>
  )
}

export default Poster