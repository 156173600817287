import React, { useState, useEffect } from 'react';
import { useNavigate, Link} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import ScrollToTop from "react-scroll-to-top";
import { db } from '../../firebase'
import Navbar from "../inc/Navbar";










function Pgds() {
    const [setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(15);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [news, setNews] = useState([])
    const navigate = useNavigate()

    const newsCollectionRef = collection(db, "Structure-Course-PGDS");
    useEffect(() => {

        const getNews = async () => {
            const data = await getDocs(newsCollectionRef);
            setNews(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getNews()
    }, [])
    return (

        <>
         <Navbar/>
         <br/>
         <br/>
         <br/>
         <br/>
         <br/>
         <ScrollToTop smooth top="100" color="maroon" />

<div className="sericulturalberhamporemurshidabad">

    <div className="album py-1">

        <div className="container mt-4">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/" style={{color:"white"}} href="#">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">PGDS</li>
                </ol>
            </nav>
        </div>
    </div>
</div>
         <br/>
       
         <br/>
            <div className="album py-0">

                <div className="container">

                    <div className="row">
                        {news.filter((val) => {
                            if (setsearchTerm === "") {
                                return val;
                            } else if (
                                val.title.toLowerCase().includes(searchTerm.toLowerCase())




                            ) {
                                return val;
                            }
                        }).slice(pagination.start, pagination.end).map((centralsericultural) => {
                            return (
                                <div className="col-md-12 mt-2" >
                                  
                                    <h4 style={{color:"Darkblue"}}  className="card-text mb-2"> <small>{centralsericultural.title}</small></h4>
                                       
                                        <p style={{color:"black"}} className="card-text mb-4"> {centralsericultural.p}</p>
                                        <h5 style={{color:"Darkblue"}}  className="card-text mb-4"> Post Graduate Diploma in Sericulture (PGDS)</h5>
                                


                                            <h6 style={{color:"black"}} className="card-text mb-4"> <b> Objectives: </b>{centralsericultural.Objectives}</h6>

                                            <h6  className="card-text mb-3"><b> About the course:</b> {centralsericultural.aboutthecourse}</h6>

                                            <h6  className="card-text mb-3"><b>Duration</b> {centralsericultural.duration}</h6>

                                            <h6  className="card-text"><b> Intake:</b> {centralsericultural.intake}</h6>

                                            <h6  className="card-text"><b> Qualification:</b> {centralsericultural.qualification}</h6>
                                            <h6  className="card-text"><b> Age Limit:</b> {centralsericultural.agelimit}</h6>
                                            <h6  className="card-text"><b> Fee Strucutr:</b> {centralsericultural.feestructure}</h6>
                                            <h6  className="card-text"><b> Course Content:</b> {centralsericultural.coursecontent}</h6>


                                           
            <br/>
            <br/>


                                           
                                    </div>

                                
                            )
                        })}
                    </div>


                </div>
            </div>

        </>

    );
}

export default Pgds;



