import React from 'react'
import Navbar from '../inc/Navbar'

import { Link } from "react-router-dom";
import Table from 'react-bootstrap/Table';


function Rti16() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className="sericultural">

                <div className="album py-1">

                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" href="#"> Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/rti" href="#"> Back</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">RTI16</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br />
            <div className="album py-1">
                <div className="container ">
                    <h4 style={{ color: "darkblue" }}>The names, designations and other particulars of the Public Information Officers.

                    </h4>
                    <br />
                    <br />


                </div>
            </div>

            <div className="album py-1">
                <div className="container ">

                    <Table striped bordered hover variant="light">
                        <thead>
                           
                            <tr>
                               
                                <th>#</th>
                                <th>  <center>Name of Public Authority </center></th>
                                <th><center>Designation
                                Under RTI</center></th>
                                <th><center>Contact Detail </center></th>
                                <th><center>Officer senior to CPIO Appellate Authority </center></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td><center>Dr. V. Sivaprasad, Director
                                CSRTI-Berhampore </center></td>
                                <td><center>CPIO </center></td>
                                <td><center>Central Sericultural Research & Training Institute, Central Silk Board, Ministry of Textiles, Govt. of India, Berhampore, PIN-742101, Dist.: Murshidabad, West Bengal INDIA</center></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td><center>Dr. D. Pandit, Scientist-D (PMCE)
                                CSRTI-Berhampore </center></td>
                                <td><center>APIO</center></td>
                                <td><center>Phone : +91 3482 224713 (Director Cell)
+91 3482 224716/17/18(EPABX)
FAX: +91 3482 224714 & +91 3482 224890
Email: csrtiber@gmail.com <br/>

         csrtiber.csb@nic.in</center></td>
         <td><center>Member Secretary 
Central Silk Board
Bangalore. 
  Mobile:
+91 80 26680190 <br/>
e-mail: ms.csb@ nic.in</center></td>
                            </tr>
                            
                            
                        </tbody>
                       
                    </Table>

                </div>
            </div>


            <br />
            <br />

        </div>
    )
}

export default Rti16