import React from 'react'
import Navbar from '../inc/Navbar'
import Table from 'react-bootstrap/Table';
import Patent343953 from './Patent342953';
import { Link } from "react-router-dom";


function Patented() {
  return (
    <div>
      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <div className="sericultural">

        <div className="album py-1">

          <div className="container mt-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/" href="#">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Technology Patented & Commercialized</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <br />
      <center><h5 style={{ color: "green" }}>Technology Patented & Commercialized</h5></center>
      <div className="album py-4">
        <div className="container">
          <Table striped bordered hover variant="success">
            <thead>
              <tr>
                <th>Sl.No</th>
                <th>Technology & Devloped Year</th>
                <th>Developed Year</th>
                <th>Patent Granted/ Filed</th>
                <th>Patent No.</th>
                <th>View/Download</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Labex (Silkworm Bed disinfectant)</td>
                <td>1997</td>
                <td>15.12.2005</td>
                <td>200199</td>
                <th>View/Download</th>

              </tr>
              <tr>
                <td>2</td>
                <td>Sericillin (Silkworm Bed disinfectant)</td>
                <td>2012</td>
                <td>27.9.2020 & Commercialized 06.02.2013</td>
                <td>342953</td>
                <th><Patent343953 /></th>
              </tr>
              <tr>
                <td>3</td>
                <td>Morizyme-B (Growth Regulator Hormone)</td>
                <td>2010</td>
                <td>Commercialized dtd 04/02/2010</td>
                <td>Commercialized</td>
                <th>View/Download</th>
              </tr>
              <tr>
                <td>4</td>
                <td>Gharsodhon - A Room Disinfectant</td>
                <td>2016</td>
                <td>Granted 27.07.2023</td>
                <td>440850 Commercialized year 2016</td>
                <th>View/Download</th>
              </tr>
              <tr>
                <td>5</td>
                <td>	Reeliboost - Process for Enhancing Cocoon Reelability</td>
                <td>2016</td>
                <td>201614736554 dated 20.08.2016</td>
                <td>Applied for patenting</td>
                <th>View/Download</th>
              </tr>


              <tr>
                <td>6</td>
                <td>	    Nirmool - A Disinfectant Composition Against Silkworm Pathogens</td>
                <td>2020</td>
                <td>	202031050005 dated 17.11.2020</td>
                <td>Applied for patenting & Commercialization</td>
                <th>View/Download</th>
              </tr>


              <tr>
                <td>7</td>
                <td>		A Method for Assessment of Soil Microbial Activity</td>
                <td>2020</td>
                <td>20231056354 dated 24.12.2020</td>
                <td>Applied for patenting</td>
                <th>View/Download</th>
              </tr>
              <tr>
                <td>8</td>
                <td>A Method for Disease Management in Mulberry Plant</td>
                <td>2021</td>
                <td>202131009336 dated 05.03.2021</td>
                <td>Applied for patenting</td>
                <th>View/Download</th>
              </tr>
              <tr>
                <td>9</td>
                <td>	Silkworm Egg Box</td>
                <td>2001</td>
                <td>dated 15.11.2007</td>
                <td>A21209(discontinued) </td>
                <th>View/Download</th>
              </tr>
              <tr>
                <td>10</td>
                <td>	Light Weight Rearing Tray</td>
                <td>2000</td>
                <td>Dated 10.05.2012</td>
                <td>237789 (discontinued)</td>
                <th>View/Download</th>
              </tr>
          
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  )
}

export default Patented