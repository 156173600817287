import React from 'react'
import Logo from "../images/csb_red_logo.png";
import logo from "../images/80YEARS_LOGO.jpg";
import { Link } from "react-router-dom";
import "./Navbar.css";
import Techmulberry from '../pages/Techmulberry';
import Licensees from '../pages/Licensees';
import TrainingCalendar from '../pages/TrainingCalendar';
import Hostel from '../pages/Hostel';
import Pgdsyear202324 from '../pages/Pgdsyear202324';
import IntensiveBivol from '../pages/IntensiveBivol';
import SuccessStory from '../pages/SuccessStory';
import Silkwordhybrids from '../pages/Silkwormhybrids';
import Ongoing from '../pages/Ongoing';
import Concluded from '../pages/Concluded';
import Pilodstudies from '../pages/Pilodstudies';
import protection from "../images/SWPATHOLOGY UPDATE.pdf";

import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem
} from 'mdb-react-ui-kit';
import SilkwormGenetics from '../pages/SilkwormGenetics';
import PmceDivision from '../pages/PmcsDivision';
import Anualreport202223 from '../pages/Anualreport202223';
import Anualreport202122 from '../pages/Anualreport202122';
import Anualreport202021 from '../pages/Anualreport202021';
import Annualreport201920 from '../pages/Annualreport201920';
import Annualreport201819 from '../pages/Annualreport201819';
import Annual201718 from '../pages/Annual201718';
import Annual202324 from '../pages/Annual202324';
import Annual201617 from '../pages/Annual201617';
import Actionplan202223 from '../pages/Actionplan202223';
import Action202324 from '../pages/Action202324';
import ActionTarget from '../pages/ActionTarget';
import Trainingdivision from '../pages/Trainingdivision';
import Pub201920 from '../pages/Pub201920';
import Pub202021 from '../pages/Pub202021';
import Pub202122 from '../pages/Pub202122';
import Pub202223 from '../pages/Pub202223';
import Pub202324 from '../pages/Pub202324';
import HostlpantBreeding from '../pages/HostplantBreeding';
import HostlpantSoil from '../pages/HostplantSoil';
import HostlpantPhysiology from '../pages/HostplantPhysiology';
import HostlpantMicrobiology from '../pages/HostplantMicrobiology';
import Biotech from '../pages/Biotech';

import SeemCpp from '../pages/SeemCpp.js';
import Training from '../pages/Training.js';
import Library from '../pages/Library.js';
import PmceComputer from '../pages/PmceComputer.js';


function Navbar() {

  return (
    <div>
      <div className='fixed-top'>

        <div className="sericulturalberhampore">
          <div className="album py-1">

            <div className="container">

              <div className="row ">

                <div className="col-md-4" >
                  <div className="d-flex justify-content-between align-items-center">

                    <a style={{ color: "black" }} className="" > CENTRAL SILK BOARD</a>

                    <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/profile.php?id=100069215829750&view_public_for=625736401397657">
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" style={{ color: "blue" }} className="bi bi-facebook" viewBox="0 0 16 16">
                        <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.018-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                      </svg>
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/@directorcsrti_ber1364">
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" style={{ color: "red" }} className="bi bi-youtube" viewBox="0 0 16 16">
                        <path d="M8.051 1.999h.089c.818.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.18 1.402l.01.104.018.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.018-.26.01-.104c.048-.519.119-1.023.18-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                      </svg>
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/csrtiberhampore/?hl=en">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-instagram" style={{ color: "darkBlue" }} viewBox="0 0 16 16">
                        <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.182 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.182 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.118a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                      </svg>
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/CsrtiBerhampore">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-twitter-x" style={{ color: "black" }} viewBox="0 0 16 16">
                        <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                      </svg>
                    </a>

                  </div>

                </div>

                <div className="d-flex justify-content-between align-items-center">

                </div>
              </div>
            </div>
          </div>
        </div>
        <nav className="navbar navbar-expand-lg navbar-light bg-light shadow">
          <div className="container-fluid">
            <Link to="/">
              <img src={Logo} className='me-2 mb-0' width="70px" height="68px" alt="" />
              <p className='mt-1 mb-0' style={{ color: "darkblue" }}>SINCE 1943</p>
            </Link>
            {/* <Link to="/">
              <p className='mt-3' style={{ color: "darkblue" }}>SINCE 1943</p>
            </Link> */}
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link to="/" className="nav-link active button21" aria-current="page" href="#"><small>HOME</small></Link>
                </li>
                <li className="nav-item">
                  <a href="about-us" className="nav-link button21" ><small>ABOUTUS</small></a>
                </li>
                <MDBContainer fluid>
                  <MDBNavbarNav>
                    <MDBNavbarItem>
                      <MDBDropdown>
                        <MDBDropdownToggle tag='a' className='nav-link button21 '>
                          <small> DIVISION</small>
                        </MDBDropdownToggle>
                        <MDBDropdownMenu>



                          <MDBDropdownItem>
                            <center>
                              <a className='mt-4 button21' href="">Hostplant &raquo;</a>
                            </center>
                            <ul className="dropdown-menu dropdown-submenu">
                              <MDBDropdownItem>


                                <center>
                                  <p className='button21' ><HostlpantBreeding /></p>
                                </center>


                              </MDBDropdownItem>
                         

                              <MDBDropdownItem>
                                <center>
                                  <p className='button21'><HostlpantPhysiology /></p>
                                </center>
                              </MDBDropdownItem>

                              <MDBDropdownItem>
                                <center>
                                  <p className='button21' > <HostlpantMicrobiology /> </p>

                                </center>
                              </MDBDropdownItem>
                              <MDBDropdownItem>
                                <center>
                                  <Link to="#" href="#"><p className='button21'>Agronomy</p></Link>
                                </center>
                              </MDBDropdownItem>
                              <MDBDropdownItem>
                                <center>
                                  <p className='button21'><HostlpantSoil /></p>
                                </center>
                              </MDBDropdownItem>



                            </ul>
                          </MDBDropdownItem>
                          <hr className='mb-2' />
                          <MDBDropdownItem>
                            <center>
                              <a className='mt-4 button21' href="#">Silkworm &raquo;</a>
                            </center>
                            <ul className="dropdown-menu dropdown-submenu">
                              <MDBDropdownItem>
                                <center>
                                  <p className='button21 ' ><SilkwormGenetics /></p>
                                </center>
                              </MDBDropdownItem>
                              <MDBDropdownItem>
                                <center>
                                <a href={protection}>
                                  <p className='button21 '> Silkworm Protection</p>

                                  </a>
                                </center>
                              </MDBDropdownItem>
                              <MDBDropdownItem>
                                <center>
                                <a href="reeling">
                                  <p className='button21 ' >Post Coocon Technology</p>

                                  </a>
                                </center>
                              </MDBDropdownItem>
                              <MDBDropdownItem>
                                <center>
                                  <Link to="#" className='button21' href="#">Entomology</Link>
                                </center>
                              </MDBDropdownItem>



                            </ul>
                          </MDBDropdownItem>
                          <hr className='mb-2' />
                          <MDBDropdownItem>
                            <center>
                              <a className='button21 mt-4' href="#"><Biotech /> </a>
                            </center>

                          </MDBDropdownItem>
                        
                       
                          <hr className='mb-2' />
                          <MDBDropdownItem>
                            <center>
                              <a className='mt-4 button21' href="#">SEEM &raquo;</a>
                            </center>
                            <ul className="dropdown-menu dropdown-submenu">
                              <MDBDropdownItem>
                                <center>
                                  <a href='extension'>
                                  <p className='button21'>Extension & Publicity</p></a>
                                </center>
                              </MDBDropdownItem>
                     
                        
                              <MDBDropdownItem>
                                <center>
                                  <p className='button21' href="#"><SeemCpp/></p>
                                </center>
                              </MDBDropdownItem>



                            </ul>
                          </MDBDropdownItem>
                          <hr className='mb-2' />
                          
                      
                          <MDBDropdownItem>
                            <center>
                              <a className='mt-4 button21' href="#">Training &raquo;</a>
                            </center>
                            <ul className="dropdown-menu dropdown-submenu">
                              <MDBDropdownItem>
                                <center>
                                 
                                  <p className='button21'><Training/></p>
                                </center>
                              </MDBDropdownItem>
                     
                        
                              <MDBDropdownItem>
                                <center>
                                  <p className='button21' href="#"><Library/></p>
                                </center>
                              </MDBDropdownItem>



                            </ul>
                          </MDBDropdownItem>
                          {/* <hr className='mb-2' />
                          <MDBDropdownItem>
                            <center>
                              <a className='mt-4 button21' href="#"><Trainingdivision /></a>
                            </center>

                          </MDBDropdownItem> */}
                          <hr className='mb-2' />
                          <MDBDropdownItem>
                            <center>
                              <a className='mt-4 button21' href="#">PMCE &raquo;</a>
                            </center>
                            <ul className="dropdown-menu dropdown-submenu">
                              <MDBDropdownItem>
                                <center>
                                 
                                  <p className='button21'><PmceDivision /></p>
                                </center>
                              </MDBDropdownItem>
                     
                        
                              <MDBDropdownItem>
                                <center>
                                  <p className='button21' href="#"><PmceComputer/></p>
                                </center>
                              </MDBDropdownItem>



                            </ul>
                          </MDBDropdownItem>

                     

                        </MDBDropdownMenu>
                      </MDBDropdown>
                    </MDBNavbarItem>
                  </MDBNavbarNav>
                </MDBContainer>

                <MDBContainer fluid>
                  <MDBNavbarNav>
                    <MDBNavbarItem>
                      <MDBDropdown>
                        <MDBDropdownToggle tag='a' className='nav-link button21'>
                          <small> PUBLICATIONS </small>
                        </MDBDropdownToggle>
                        <MDBDropdownMenu>



                          <MDBDropdownItem>
                            <center>
                              <a className='mt-4 button21' href=""><small> Annual Report</small> &raquo;</a>
                            </center>
                            <ul className="dropdown-menu dropdown-submenu">
                              <MDBDropdownItem>
                                <center>
                                  <p className='button21 mb-2' ><Annual202324 /></p>
                                </center>
                              </MDBDropdownItem>
                              <MDBDropdownItem>
                                <center>
                                  <p className='button21 mb-2' ><Anualreport202223 /></p>
                                </center>
                              </MDBDropdownItem>
                              <MDBDropdownItem>
                                <center>
                                  <p className='button21 mb-2' href="#"><Anualreport202122 /></p>
                                </center>
                              </MDBDropdownItem>

                              <MDBDropdownItem>
                                <center>
                                  <p className='button21 mb-3'  ><Anualreport202021 /></p>
                                </center>
                              </MDBDropdownItem>

                              <MDBDropdownItem>
                                <center>
                                  <p className='button21 mb-3 mt-2' ><Annualreport201920 /></p>
                                </center>
                              </MDBDropdownItem>
                              <MDBDropdownItem>
                                <center>
                                  <p className='button21 mb-3' ><Annualreport201819 /></p>
                                </center>
                              </MDBDropdownItem>
                              <MDBDropdownItem>
                                <center>
                                  <p className='button21 mb-2'><Annual201718 /></p>
                                </center>
                              </MDBDropdownItem>
                              <MDBDropdownItem>
                                <center>
                                  <p className='button21'  ><Annual201617 /></p>
                                </center>
                              </MDBDropdownItem>


                            </ul>
                          </MDBDropdownItem>
                          <hr className='mb-2' />
                          <MDBDropdownItem>
                            <center>
                              <a className='mt-4 button21' href="Publication_Minutes"><small>Minutes</small> &raquo;</a>
                            </center>

                          </MDBDropdownItem>
                          <hr className='mb-2' />
                          <MDBDropdownItem>
                            <center>
                              <a className='mt-4 button21' href="#"><small>Action Plan</small> &raquo;</a>
                            </center>
                            <ul className="dropdown-menu dropdown-submenu">
                              <MDBDropdownItem>
                                <center>
                                  <p className='button21'  ><Actionplan202223 /></p>
                                </center>
                              </MDBDropdownItem>
                              <MDBDropdownItem>
                                <center>
                                  <p className='button21'  ><Action202324 /></p>
                                </center>
                              </MDBDropdownItem>
                              <MDBDropdownItem>
                                <center>
                                  <p className='button21'  ><ActionTarget /></p>
                                </center>
                              </MDBDropdownItem>
                            </ul>

                          </MDBDropdownItem>
                          <hr className='mb-2' />
                          <MDBDropdownItem>
                            <center>
                              <a className='mt-4 button21' href="#"><small>Research Paper</small> &raquo;</a>
                            </center>
                            <ul className="dropdown-menu dropdown-submenu">
                              <MDBDropdownItem>
                                <center>
                                  <p className='button21'  ><Pub201920 /></p>
                                </center>
                              </MDBDropdownItem>
                              <MDBDropdownItem>
                                <center>
                                  <p className='button21' ><Pub202021 /></p>
                                </center>
                              </MDBDropdownItem>
                              <MDBDropdownItem>
                                <center>
                                  <p className='button21'   ><Pub202122 /></p>
                                </center>
                              </MDBDropdownItem>
                              <MDBDropdownItem>
                                <center>
                                  <p className='button21'   ><Pub202223 /></p>
                                </center>
                              </MDBDropdownItem>
                              <MDBDropdownItem>
                                <center>
                                  <p className='button21'   ><Pub202324 /></p>
                                </center>
                              </MDBDropdownItem>
                            </ul>
                          </MDBDropdownItem>
                          <hr className='mb-2' />
                          <MDBDropdownItem>
                            <center>
                              <a className='mt-4 button21' href="brochure"><small>Brochure/Pamphlets</small> &raquo;</a>
                            </center>

                          </MDBDropdownItem>
                          <hr className='mb-2' />
                          <MDBDropdownItem>
                            <center>
                              <a className='mt-4 button21' href="poster"><small>Poster</small> &raquo;</a>
                            </center>

                          </MDBDropdownItem>
                          <hr className='mb-2' />
                          <MDBDropdownItem>
                            <center>
                              <a className='mt-4 button21' href="Rkb"><small>Resham Krishi Barta</small> &raquo;</a>
                            </center>

                          </MDBDropdownItem>
                          <hr className='mb-2' />
                          <MDBDropdownItem>
                            <center>
                              <a className='mt-4 button21' href="NewsViews"><small>News & Views</small> &raquo;</a>
                            </center>

                          </MDBDropdownItem>
                          <hr className='mb-2' />
                          <MDBDropdownItem>
                            <center>
                              <a className='mt-4 button21' href="Awareness"> <small>Audio & Video </small> &raquo;</a>
                            </center>

                          </MDBDropdownItem>

                        </MDBDropdownMenu>
                      </MDBDropdown>
                    </MDBNavbarItem>
                  </MDBNavbarNav>
                </MDBContainer>

                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle button21" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <small> PROJECTS</small>
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li><a className="dropdown-item " href="#"><Ongoing /></a></li>
                    <li><a className="dropdown-item" href="#"><Concluded /></a></li>
                    <li><hr className="dropdown-divider" /></li>
                    <li><a className="dropdown-item" href="#"><Pilodstudies /></a></li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle button21" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <small>TECHNOLOGIES</small>
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li><p className="dropdown-item" > <Techmulberry /></p></li>
                    <li><p className="dropdown-item" ><IntensiveBivol /></p></li>
                    <li><p className="dropdown-item" ><Silkwordhybrids /></p></li>

                    <li><hr className="dropdown-divider" /></li>
                    <li><p className="dropdown-item" ><SuccessStory /></p></li>
                  </ul>
                </li>

                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle button21" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <small> ANOUNCEMENT</small>
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">


                    <li><Link to="/advertise" className=" button21 " style={{ margin: 10 }} href="#">Advertisement</Link></li>

                  </ul>
                </li>

                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle button21" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <small> CBT/TRAINING</small>
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li>
                    <Link to="/pgds">
                      <p className="button21" style={{ margin: 16 }}  ><small>PGDS(Structured Course)</small></p>
                    </Link></li>

                    <li><a className="dropdown-item button21" href="#"><Pgdsyear202324 /></a></li>
                    <li><p className="button21 button54" style={{ margin: 16 }} >Non Structured <br /> Course</p></li>

                    <li><a className="dropdown-item button21" href="#"><Hostel /></a></li>
                    <li><a className="dropdown-item button21" href="#"><TrainingCalendar /></a></li>
                  </ul>
                </li>


                {/* <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <small> PUBLICATION</small>
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li><a className="dropdown-item" href="#">Hostplant</a></li>
                    <li><a className="dropdown-item" href="#">Silkworm</a></li>
                    <li><hr className="dropdown-divider" /></li>
                    <li><a className="dropdown-item" href="#">PMC Division</a></li>

                    <li><a className="dropdown-item" href="#">PMCE Division</a></li>
                    <li><a className="dropdown-item" href="#">Training Division</a></li>
                  </ul>
                </li> */}
                {/* <li className="nav-item">
                  <a className="nav-link mt-1 button21" href="#"><small><Licensees /></small></a>
                </li> */}
                {/* <li className="nav-item">
          <Link to="/" className="nav-link" href="#"><small>PATENTED </small></Link>
        </li> */}
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle button21" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <small>FACULTY</small>
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li><a className="button21 mt-2 mb-3" style={{ margin: 10 }} href="scientist-profile">Scientist</a></li>
                    <li><a className=" button21 mt-2 " style={{ margin: 10 }} href="faculty_administration">Administration</a></li>
                    <li><hr className="dropdown-divider" /></li>
                    <li><a className="button21" style={{ margin: 10 }} href="#">Technical</a></li>
                  </ul>
                </li>
                {/* <li className="nav-item">
                  <Link to="/scientist-profile" className="nav-link button21" href="#"><small>FACULTY</small></Link>
                </li> */}

                <li className="nav-item">
                  <Link to="/gallery" className="nav-link button21"><small>GALLERY</small></Link>
                </li>
                {/* <li className="nav-item">
          <Link to="/" className="nav-link" href="#"><small>PATAINTED </small></Link>
        </li> */}
              </ul>
              <form className="d-flex">
                <Link to="/">
                  <img src={logo} width="70px" height="70px" alt="80years_logo" />
                </Link>
              </form>
            </div>
          </div>
        </nav>
      </div>
    </div>
  )
}

export default Navbar