import React from 'react'
import Navbar from '../inc/Navbar'

import {Link} from "react-router-dom";

function Rti7() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className="sericultural">

<div className="album py-1">

    <div className="container mt-3">
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                
                <li className="breadcrumb-item"><Link to="/" href="#"> Home</Link></li>
                <li className="breadcrumb-item"><Link to="/rti" href="#"> Back</Link></li>
                <li className="breadcrumb-item active" aria-current="page">RTI7</li>
            </ol>
        </nav>
    </div>
</div>
</div>
            <br />
            <div className="album py-1">
                <div className="container ">
                    <h4 style={{ color: "darkblue" }}>The particulars of any arrangement that exists for consultation with, or representation by, the members of the public in relation to the formulation of its policy or implementation thereof.    

</h4>
<p>The Institute or its sub-ordinate units organizes the following public interactive programmes/events for the benefits of the stakeholders i.e. sericulture NGOs, RSPs, farmers, reelers, entrepreneurs about new technologies or/and, to address their problems etc.:</p>
                    <br />
                    <br />
                    <h6><b style={{color:"maroon"}}>Resham Krishi Mela:</b> It is platform to organize the stakeholders like sericulture NGOs, RSPs, farmers, reelers, entrepreneurs to discuss about new technologies or/and to address problem of them. It is an interactive occasion where stakeholder-scientists interaction take place.</h6>
                <br/>

                <h6><b style={{color:"maroon"}}>Sericulture related Workshop:</b> Organize to discuss about new technologies or new improved package of practices and to address the feedback of the participant stakeholders directly.</h6>

<br/>

              <h6><b style={{color:"maroon"}}>Awareness Programme:</b> It is organize locally in potential sericulture areas in the stakeholders own situation for awareness among the stakeholders about new technology(s).</h6>
              <br/>

<h6><b style={{color:"maroon"}}>Field day/Farmers Day:</b> It is organize locally in potential sericulture areas in the stakeholders own situation to show new practice in their own situation.</h6>
<br/>
<h6><b style={{color:"maroon"}}>Technology Demonstration:</b> It is organized locally in farmers own situation and new technology is demonstrated before them for better understanding.</h6>

<br/>


<h6><b  style={{color:"maroon"}}>Exhibition:</b> It is organize to exhibit the new findings /research outputs i.e. new technologies / new or improved package of practices by showing models, charts, graphs live materials etc.</h6>

<br/>
<center><h6>All these programmes are organized involving various level of scientists, officers, officials, technical persons of state & CSB, local authorities (administration), concerned public representatives etc.</h6></center>
                </div>
            </div>
            <br />
            <br />

        </div>
    )
}

export default Rti7