import React from 'react';

// import AddBook from '../AddBook';
import ScrollToTop from "react-scroll-to-top";
import "./Contact.css";
import Navbar from '../inc/Navbar';
import { Link } from "react-router-dom";
import Map from './Map';
function Contacts() {
    return (

        <>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br/>
            <ScrollToTop smooth top="100" color="maroon"/>

            <div className="sericultural">

                <div className="album py-1">

                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" href="#">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <div className="no1restuarantkrishnagar">
                <br />
                <br />


                <div className="album py-0">

                    <div className="container">

                        <div className="row justify-content-center">

                            <div className="col-md-5">

                                <h2 style={{ color: "light" }} className="card-text"> <b>CENTRAL SILK BOARD</b></h2>
                                <h6 className="card-text">Postal Address</h6>
                                <p style={{ color: "black" }} className="card-text mb-1"><small> Central Sericultural Research & Training Institute <br></br>Central Silk Board, Ministry of Textiles, Govt. of India </small></p>
                                <p style={{ color: "black" }} className="card-text MB-1">Berhampore (Post) Pin: 742 101</p>
                              
                                    <p style={{ color: "black" }} className="card-text MB-1">District - Murshidabad, West Bengal, INDIA.
                                    </p>
                                <p className="card-text"><br />Copyright © CENTRAL SERICULTURAL RESEARCH AND TRAINING INSTITUTE </p>



                                <div className="d-flex justify-content-between align-items-center">


                                </div>

                            </div>
                            <div className="col-md-4 mt-6" >

                                <h6 className="card-text mb-2"> <b>CONTACT </b></h6>
                                <a href="tel: +91 3482 224713">
                                    <p style={{ color: "black" }} className="mb-3" >    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
                                        <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z" />
                                    </svg> +91 3482 224713  (Director Cell)
                                    </p> </a>


                                <a href="tel: +91 3482 224716 ">
                                    <p style={{ color: "black" }} className="mb-2" >    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
                                        <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z" />
                                    </svg> +91 3482 224716 / <a style={{color:"black"}} href="tel: +91 3482 224717"> 17</a> / <a style={{color:"black"}} href="tel: +91 3482 224718"> 18</a>  (EPABX)
                                    </p> </a>


                                {/* <ReactWhatsapp number="+91 9609612912" className="btn btn-success" message="I am Interested"> Whatsapp Inquiry</ReactWhatsapp> */}
                                <div className="d-flex justify-content-between align-items-center">
                                </div>
                            </div>
                            <div className="col-md-3 mt-6" >
                                <h6 className="card-text "> <b>FOR EMAIL</b></h6>
                             
                                <a href="mailto:  csrtiber.csb@nic.in" className="btn btn- btn-light mb-3">  csrtiber.csb@nic.in</a>
                                <br></br>
                                                 
                                <a href="mailto:  csrtiber@gmail.com" className="btn btn- btn-light"> csrtiber@gmail.com</a>



                                <div className="d-flex justify-content-between align-items-center">

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <br />
            </div>

            <Map/>

        </>

    );
}

export default Contacts;