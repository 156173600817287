import React from 'react'
import Navbar from '../inc/Navbar'
import { Table } from "react-bootstrap";
import RkbApril17 from './RkbApril17';
import RkbJan17 from './RkbKan17';
import RkbOct16 from './RkbOct16';
import RkbJuly16 from './RkbJuly16';
import RkbApril16 from './RkbApril16';
import RkbJan16 from './RkbJan16';
import { Link } from "react-router-dom";


function Rkb() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br/>
            <br />
            <br />
            <div className="sericultural">

                <div className="album py-1">

                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house-door" viewBox="0 0 16 16">
                                    <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4z" />
                                </svg> Home</Link></li>

                                <li className="breadcrumb-item active" aria-current="page"> RKB</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br />

            <div className="album py-2">

                <div className="container">
                    <h4 style={{ color: "DarkBlue" }}>রেশম কৃষি বার্তা / RESHAM KRISHI BARTA</h4>
                    <h6>(A BENGALI QUARTERLY MAGAGIN)                          </h6>
                </div>
            </div>

            <div className="album py-1">
                <div className="container">

                    <Table striped bordered hover size="sm">
                        <thead>

                        </thead>
                        <tbody>
                            <tr >
                                <td > <center className='mb-2 mt-2'>Year: 2 Vol. 2, April, 2017		</center></td>
                                <td><center className='mb-2 mt-2'><RkbApril17 /></center></td>





                            </tr>

                            <tr >
                                <td > <center className='mb-2 mt-2'>Year: 2 Vol. 1, January, 2017	</center></td>
                                <td><center className='mb-2 mt-2'><RkbJan17 />	</center></td>




                            </tr>
                            <tr >
                                <td > <center className='mb-2 mt-2'>Year: 1 Vol.: 4, October, 2016	</center></td>
                                <td><center className='mb-2 mt-2'><RkbOct16 /></center></td>




                            </tr>
                            <tr >
                                <td > <center className='mb-2 mt-2'>Year: 1 Vol.: 3, July, 2016	</center></td>
                                <td><center className='mb-2 mt-2'><RkbJuly16 /></center></td>
                            </tr>

                            <tr >
                                <td > <center className='mb-2 mt-2'>Year: 1 Vol.: 2, April, 2016</center></td>
                                <td><center className='mb-2 mt-2'><RkbApril16 />	</center></td>
                            </tr>
                            <tr >
                                <td > <center className='mb-2 mt-2'>Year: 1 Vol.: 1, Janyary, 2016</center></td>
                                <td><center className='mb-2 mt-2'><RkbJan16 />	</center></td>
                            </tr>




                        </tbody>
                    </Table>
                </div>
            </div>

        </div>
    )
}

export default Rkb