import React from 'react'



function Map() {
  return (
    <div>
    
           
      <div className="album py-2">
        <div className="container">
            <div className='row'>
        <iframe className="iframe-fluid" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d693.600795216386!2d88.26458662748335!3d24.091175225898137!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f97db5f53f3203%3A0x556abc054a446477!2sCentral%20Sericulture%20Research%20%26%20Training%20Institute!5e1!3m2!1sen!2sin!4v1722159288299!5m2!1sen!2sin" width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        
    </div>
    </div>
    <br/>
    <br/>
    </div>
  )
}

export default Map