import React from 'react'
import Navbar from '../inc/Navbar'
import Table from 'react-bootstrap/Table';
import {Link} from "react-router-dom";

import speechs1 from "../images/speech.mp3";
import speechs2 from "../images/Kanika_Trivedy.mp3";
import speechs4 from "../images/_S_Chakraborty.mp3";
import ranajitkar5 from "../images/28-07-16_R_Kar.mp3";

import ranajitkar6 from "../images/25-08-16_R_Kar.mp3";
import ddas7 from "../images/04-08-16_D_Das.mp3";
import drritab8 from "../images/11-08-16_R_Banerjee.mp3";
import drsandipkrdutta9 from "../images/18-08-16_S_K_Datta.mp3";
import mc10 from "../images/01-09-16_Monica_Chowdhury.mp3";
import ritac11 from "../images/08-09-16_Rita_Banerjee.mp3";
import anilkrverma12 from "../images/15-09-16_A_K_Verma.mp3";
import ANILKRVERMA13 from "../images/22-09-16_A_K_Verma.mp3";
import dranilkr14 from "../images/29-09-16_A_K_Verma.mp3";
import MRNBKAR15 from "../images/06-10-16_N_B_kar_bi-product.mp3";
import nbkar16 from "../images/13-10-16_N_B_Kar_post-coc-tech.mp3";
import nbkar17 from "../images/20-10-16_N_B_Kar_silk-marketing.mp3";
import Subhrachanda18 from "../images/27-10-16_S_Chanda_Female-in-seri.mp3";
import drzakirhossain18 from "../images/11-07-17_Zakir_Hosain.mp3";
import tduttab19 from "../images/18_07_17_T_Dutta_Biswas.mp3";
import drdebojit20 from "../images/25_07_17_Debojit.mp3";
import drgssing21 from "../images/15-07-17_GSSingh.wav";
import LSINGH22 from "../images/14-02-17_Manipur.mp3";



function Awar() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br />
            
            <br />
            <div className="sericultural">

<div className="album py-1">

    <div className="container mt-3">
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/" href="#"> Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Awareness Program</li>
            </ol>
        </nav>
    </div>
</div>
</div>
<br />
            <center><h4 style={{ color: "ThreeDLightShadow" }} className='mb-3 mt-2'>AWARENESS THROUGH MEDIA</h4></center>
            <center><h4 style={{ color: "Darkblue" }}>VIDEO</h4></center>
            <br />
            <br />
            <div className="album py-1">
                <div className="container">
                    <Table striped bordered hover variant="danger">
                        <thead>
                            <tr>
                                <th>Vernacular</th>
                                <th>Topic</th>
                                <th>View</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>BENGALI</td>
                                <td>Resham Shilp kotha Episode 5	</td>
                                <td> <a href='https://www.youtube.com/watch?v=yWjAfHQUk5E' target="_blank" rel="noopener noreferrer" > View</a></td>

                            </tr>
                            <tr>
                                <td>BENGALI</td>
                                <td>Resham Shilp kotha Episode 4	</td>
                                <td> <a href='https://www.youtube.com/watch?v=y5P2muyu9-s' target="_blank" rel="noopener noreferrer" > View</a></td>

                            </tr>
                            <tr>
                                <td>BENGALI</td>
                                <td>Resham Shilp kotha Episode 3</td>
                                <td> <a href='https://www.youtube.com/watch?v=seMtAUHG_Ak' target="_blank" rel="noopener noreferrer" > View</a></td>

                            </tr>
                            <tr>
                                <td>BENGALI</td>
                                <td>Resham Shilp kotha Episode 2</td>
                                <td> <a href='https://www.youtube.com/watch?v=K3ryLMXVpys' target="_blank" rel="noopener noreferrer" > View</a></td>

                            </tr>
                            <tr>
                                <td>BENGALI</td>
                                <td>Resham Shilp kotha Episode 1</td>
                                <td> <a href='https://www.youtube.com/watch?v=_vl-Ps_AA3k' target="_blank" rel="noopener noreferrer" > View</a></td>

                            </tr>
                            <tr>
                                <td>BENGALI</td>
                                <td>Exposure visit for Awareness on Mulberry Sericulture (08:34)</td>
                                <td> <a href='https://www.youtube.com/watch?v=U7YCZlUVaAM' target="_blank" rel="noopener noreferrer" > View</a></td>

                            </tr>
                            <tr>
                                <td>BENGALI</td>
                                <td>	Attaining self sufficiency by the women of Mallikpur village under Khargram Block through sericulture held at CSR&TI, Berhampore on 08-03-2018 (04:31 min)</td>
                                <td> <a href='https://www.youtube.com/watch?v=8N-TXRlmjGY&t=73s' target="_blank" rel="noopener noreferrer" > View</a></td>

                            </tr>
                            <tr>
                                <td>BENGALI</td>
                                <td>updte</td>
                                <td> <a href='https://www.youtube.com/watch?v=_vl-Ps_AA3k' target="_blank" rel="noopener noreferrer" > View</a></td>

                            </tr>
                            <tr>
                                <td>BENGALI</td>
                                <td>	Whitefly - A severe pest of mulberry / সাদামাছি - তুঁত গাছের একটি ভয়ঙ্কর ক্ষতিকারক কীট</td>
                                <td> <a href='https://www.youtube.com/watch?v=8fApxS0LeiQ' target="_blank" rel="noopener noreferrer" > View</a></td>

                            </tr>
                            <tr>
                                <td>BENGALI</td>
                                <td>	Documentory: Kishan Nursery / কৃষক নার্সারি (14:00 min)</td>
                                <td> <a href='https://www.youtube.com/watch?v=qMopeFzEJVg' target="_blank" rel="noopener noreferrer" > View</a></td>

                            </tr>
                            <tr>
                                <td>BENGALI</td>
                                <td>	Documentory: Bivoltine Sericulture / বাইভোল্টাইন পলু পালন (19:26 min)</td>
                                <td> <a href='https://www.youtube.com/watch?v=2AcVf48WbfY' target="_blank" rel="noopener noreferrer" > View</a></td>

                            </tr>
                            <tr>
                                <td>BENGALI</td>
                                <td>Documentory: Women in Sericulture / নারী শক্তি : উন্নত রেশম চাষে মহিলা উদ্যোগপতি (12:50 min)</td>
                                <td> <a href='https://www.youtube.com/watch?v=KZXmuP8MwRo' target="_blank" rel="noopener noreferrer" > View</a></td>

                            </tr>
                            <tr>
                                <td>HINDI</td>
                                <td>	Sericulture in Jharkhand / झारखंड राज्य में शहतूत रेशम उत्पादन : Ranchi Doordarshan presentation</td>
                                <td> <a href='https://www.youtube.com/watch?v=o7yErC6_Ba8' target="_blank" rel="noopener noreferrer" > View</a></td>

                            </tr>
                            <tr>
                                <td>ENGLISH</td>
                                <td>Sericulture Weaving Leads Way to Untapped Potential of Handloom in Meghalaya</td>
                                <td> <a href='https://www.youtube.com/watch?v=2E9AM02CcGE' target="_blank" rel="noopener noreferrer" > View</a></td>

                            </tr>

                            <tr>
                                <td>ENGLISH</td>
                                <td>Resham Krishi Mela 2018 (CSR&TI Berhampore) - Media coverage</td>
                                <td> <a href='#' target="_blank" rel="noopener noreferrer" > View</a></td>

                            </tr>


                        </tbody>
                    </Table>
                </div>
            </div>
            <br />

            <br />
            <center><h2 style={{ color: "Darkblue" }}><b>Audio</b></h2></center>
            {/* <audio controls> 
            <source src={speechs1} type="audio/mp3"/>
             </audio> */}


            <div className="album py-3">
                <div className="container">
                    <Table striped bordered hover variant="primary">
                        <thead>
                            <tr>
                                <th>Vernacular</th>
                                <th>Topic (Date of broadcast from AIR)</th>
                                <th>Lecturer</th>
                                <th>Media file</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>BENGALI</td>
                                <td>Campaign in overall development of Mallikpur, Diara Village (03-03-2018)</td>
                                <td><small>Dr. Kanika Trivedy</small></td>
                                <td> <audio controls>
                                    <source src={speechs1} type="audio/mp3" /></audio></td>

                            </tr>
                            <tr>
                                <td>BENGALI</td>
                                <td>Mulberry Sericulture - An introduction (13-07-2016)	</td>
                                <td>Dr. Kanika Trivedy	</td>

                                <td> <audio controls>
                                    <source src={speechs2} type="audio/mp3" />
                                </audio></td>

                            </tr>
                            <tr>
                                <td>BENGALI</td>
                                <td>Technologies developed at CSR&TI, Berhampore and its prospects (14-07-2016)</td>
                                <td>Dr. Kanika Trivedy	</td>

                                <td> <audio controls>
                                    <source src={speechs2} type="audio/mp3" />
                                </audio></td>

                            </tr>
                            <tr>
                                <td>BENGALI</td>
                                <td>Disinfection of rearing room & appliances. Different diseases & pests of silkworm (21-07-16)</td>
                                <td>Dr. S. Chakraborty</td>

                                <td> <audio controls>
                                    <source src={speechs4} type="audio/mp3" />
                                </audio></td>

                            </tr>
                            <tr>
                                <td>BENGALI</td>
                                <td>
                                    Importance of soil testing (Part-I) (28-0-2016)</td>
                                <td>Dr. Ranjit Kar</td>

                                <td> <audio controls>
                                    <source src={ranajitkar5} type="audio/mp3" />
                                </audio></td>

                            </tr>
                            <tr>
                                <td>BENGALI</td>
                                <td>
                                    Method of soil sampling and fertilizer application (Part-II) (25-08-2016)</td>
                                <td>	Dr. Ranjit Kar	</td>

                                <td> <audio controls>
                                    <source src={ranajitkar6} type="audio/mp3" />
                                </audio></td>

                            </tr>
                            <tr>
                                <td>BENGALI</td>
                                <td>Major pest infestation of mulberry and their control measures (04-08-2016)</td>
                                <td>
                                    Shri Debojit Das	</td>

                                <td> <audio controls>
                                    <source src={ddas7} type="audio/mp3" />
                                </audio></td>

                            </tr>
                            <tr>
                                <td>BENGALI</td>
                                <td>
                                    Kisan Nursery & Improved Technologies for mulberry plantation (11-08-2016)</td>
                                <td>Dr. Rita Banerjee                        </td>

                                <td> <audio controls>
                                    <source src={drritab8} type="audio/mp3" />
                                </audio></td>

                            </tr>
                            <tr>
                                <td>BENGALI</td>
                                <td>	Major mulberry diseases and their control (18-08-2016)</td>
                                <td>Dr. Sandip Kr. Dutta                        </td>

                                <td> <audio controls>
                                    <source src={drsandipkrdutta9} type="audio/mp3" />
                                </audio></td>

                            </tr>
                            <tr>
                                <td>BENGALI</td>
                                <td>	Vermicompost (01-09-2016)</td>
                                <td>Dr. M. Chaudhuri                        </td>

                                <td> <audio controls>
                                    <source src={mc10} type="audio/mp3" />
                                </audio></td>

                            </tr>
                            <tr>
                                <td>BENGALI</td>
                                <td>	Different Mulberry varieties, package of practices, fertilizer utilization and irrigation (8-9-16)                        </td>
                                <td>Dr. Rita Banerjee                        </td>

                                <td> <audio controls>
                                    <source src={ritac11} type="audio/mp3" />
                                </audio></td>

                            </tr>
                            <tr>
                                <td>BENGALI</td>
                                <td>	Incubation of silkworm DFLs and Chawki rearing (15-09-2016)</td>
                                <td>Dr. Anil Kr. Verma
                                </td>

                                <td> <audio controls>
                                    <source src={anilkrverma12} type="audio/mp3" />
                                </audio></td>

                            </tr>
                            <tr>
                                <td>BENGALI</td>
                                <td>	Different Season and Region specific silkworm breeds and hybrids (22-09-2016)
                                </td>
                                <td>Dr. Anil Kr. Verma                        </td>

                                <td> <audio controls>
                                    <source src={ANILKRVERMA13} type="audio/mp3" />
                                </audio></td>

                            </tr>
                            <tr>
                                <td>BENGALI</td>
                                <td>Late age silkworm rearing (29-09-2016)                        </td>
                                <td>Dr. Anil Kr. Verma 	</td>
                                <td> <audio controls>
                                    <source src={dranilkr14} type="audio/mp3" />
                                </audio></td>
                            </tr>




                            <tr>
                                <td>BENGALI</td>
                                <td>Biproduct from Sericulture (06-10-2016)                       </td>
                                <td>Mr. N. B. Kar                                </td>
                                <td> <audio controls>
                                    <source src={MRNBKAR15} type="audio/mp3" />
                                </audio></td>
                            </tr>
                            <tr>
                                <td>BENGALI</td>
                                <td>	
                                Post cocoon technologies - An over view (13-10-2016)                       </td>
                                <td>Mr. N. B. Kar</td>
                                <td> <audio controls>
                                    <source src={nbkar16} type="audio/mp3" />
                                </audio></td>
                            </tr>
                            <tr>
                                <td>BENGALI</td>
                                <td>Marketing of cocoons (20-10-2016)                                </td>
                                <td>Mr. N. B. Kar</td>
                                <td> <audio controls>
                                    <source src={nbkar17} type="audio/mp3" />
                                </audio></td>
                            </tr>
                            <tr>
                                <td>BENGALI</td>
                                <td>Role of women in sericulture (27-10-2016)                                </td>
                                <td>Dr. Subhra Chanda                                </td>
                                <td> <audio controls>
                                    <source src={Subhrachanda18} type="audio/mp3" />
                                </audio></td>
                            </tr>
                            <tr>
                                <td>BENGALI</td>
                                <td>	
                            Disease & Pest management of silkworm & mulberry (11-07-2017)                      </td>
                                <td>Mr. Zakir Hossain                                </td>
                                <td> <audio controls>
                                    <source src={drzakirhossain18} type="audio/mp3" />
                                </audio></td>
                            </tr>
                            <tr>
                                <td>BENGALI</td>
                                <td>Improved silkworm rearing (18-07-2017)                                </td>
                                <td>Dr. T. Datta Biswas                                </td>
                                <td> <audio controls>
                                    <source src={tduttab19} type="audio/mp3" />
                                </audio></td>
                            </tr>
                            <tr>
                                <td>BENGALI</td>
                                <td>Mulberry cultivation (25-07-2017)                                </td>
                                <td>Mr. Debojit Ds                                </td>
                                <td> <audio controls>
                                    <source src={drdebojit20} type="audio/mp3" />
                                </audio></td>
                            </tr>
                            <tr>
                                <td>HINDI</td>
                                <td>झारखंड राज्य में शहतूत पौधे की खेती (15-07-2017)                                </td>
                                <td>Dr. G.S. Singh                                </td>
                                <td> <audio controls>
                                    <source src={drgssing21} type="audio/mp3" />
                                </audio></td>
                            </tr>
                            <tr>
                                <td>MANIPURI</td>
                                <td>	
                                Mulberry pest and disease control (14-02-2017)                      </td>
                                <td>Dr. L. Somen Singh                                </td>
                                <td> <audio controls>
                                    <source src={LSINGH22} type="audio/mp3" />
                                </audio></td>
                            </tr>




                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    )
}

export default Awar