import React from 'react'
import Navbar from '../inc/Navbar'
import coverpage from "../images/Cover-Pages.jpg";
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
function Aboutus() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <ScrollToTop smooth top="100" color="maroon"/>
            <div className="sericultural">

                <div className="album py-1">

                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" href="#">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">About Us</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br />

            <div className="album py-1">
                <div className="container">
                    <div className="row ">
                        {/* <div className="col-md-6">

                            <img src={institutephoto} className="card-img-top" alt="..." />
                            <div className="card-body">

                            </div>
                        </div> */}
                        <div className="col-md-12">

                            <div className="card-body">
                                <h5 className="card-title texts mb-2">CSR&TI, Berhampore is a premier research station established during early 1943 to provide the research and development support for promotion of silk industry in the Eastern and Northeastern region. Presently, it functions under the administrative control of <a href='https://csb.gov.in/'> Central Silk Board</a>, a statutory body under the ministry of textiles, Govt. of India. The institute is located in the historic district of Murshidabad in West Bengal, where the silk industry has bloomed, flourished and reached its pinnacle. The city is 200 km away from Kolkata and well connected by both Rail and Road.</h5>

                                <h5 className="card-text texts mb-3">The institute conducts research in all aspects of sericulture from soil to silk. Vested with the mandate to conduct research, it has made remarkable research break through and outstanding contributions for the overall development of silk industry in the region. On the plant side, new mulberry varieties capable of increasing the productivity and quality of leaf have been developed together with package of practices. On the insect side, productive silkworm breeds suitable to the region have been evolved, and suitable prevention and control measures have been worked out. Even on the reeling front, improved cooking and reeling techniques are suggested. To generate human resources development, tailor made training programme are conducted apart from the regular course of Post Graduate Diploma in Sericulture. All these contributions helped to sustain growth of the industry in this region.</h5>

                                <h5 className='texts'>In the recent years, the institute has embarked on a "lab to land" programme to disseminate the research findings into successful economic ventures among the rural people. It's a new phase, a new dimension and a new promise to the industry ahead that acknowledges a greater wisdom and pride.</h5>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <div className="album py-0">

<div className="container">
    <center>
    <h6 className='mandate'>  MANDATE</h6>
    </center>

    <br/>
    <h6>1. Undertake basic, strategic and appilied/adaptive research augmenting silk productivity and quality in Eastern and North Eastern India</h6>
    <h6>2. Strengthening extension and linkage with stakeholders for technology management</h6>
    <h6>3. Impart and coordinate quality human resource development</h6>
    </div>
    </div>


          
            <img src={coverpage} className="d-block w-100" alt="central_sericultural_berhampore"/>
            
        </div>
    )
}

export default Aboutus