import React from 'react'
import Navbar from '../inc/Navbar'
import seritourism from "../images/MSD-tourism map.jpg";
import {Link} from "react-router-dom";
function SeriTourism() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <div className="sericulturalberhamporemurshidabad">

<div className="album py-1">

    <div className="container mt-3">
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/" style={{color:"white"}} href="#">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">SERI-TOURISM</li>
            </ol>
        </nav>
    </div>
</div>
</div>
<br/>
<center><h4 style={{color:"green"}}>SERI-TOURISM</h4></center>
<br/>
<div className="album py-2">

    <div className="container">
    <img src={seritourism} className="d-block w-100" alt="central_sericultural_berhampore"/>
        </div>
    </div>
<br/>
<br/>
    </div>
  )
}

export default SeriTourism