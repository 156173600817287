import React from 'react'
import Navbar from '../inc/Navbar'
import Table from 'react-bootstrap/Table';
import Admissionnoti from './Admissionnoti';
import {Link} from "react-router-dom";

function Adver2() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        
        <div className="sericultural">

<div className="album py-1">

    <div className="container mt-3">
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/" href="#"> Home</Link></li>
                <li className="breadcrumb-item"><Link to="/" href="#"> Back</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Admission</li>
            </ol>
        </nav>
    </div>
</div>
</div>
<br/>
        <center><h4 className='mt2 mb-3'><b>Admission</b></h4></center>
        <div className="album py-1">
        <div className="container">
        <Table striped bordered hover variant="success">
          
      <thead>
     
        <tr>
          <th><center>Date</center></th>
          <th><center>Subject</center></th>
          <th><center>View/Download</center></th>
 
        </tr>
      </thead>
      <tbody>
      
        <tr>
          <td><center>18.05.2023</center></td>
          <td><center>Admission Notification for Post Graduate Diploma in Sericulture (PGDS): 2023-24</center></td>
          <td><center><Admissionnoti/></center></td>
       
        </tr>
       
       
      </tbody>
   
    </Table>
    </div>
    </div>
<br/>
    </div>
  )
}

export default Adver2