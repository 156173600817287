import React from 'react'
import Navbar from '../inc/Navbar'
import {Link} from "react-router-dom";

function Feedback() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br />
        
            <br />
            <div className="sericultural">

<div className="album py-1">

    <div className="container mt-3">
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/" href="#"> Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Feedback</li>
            </ol>
        </nav>
    </div>
</div>
</div>
            <br />
          
            <center><h1><b>Feedback</b></h1></center>
            <br />
            <hr/>

            <div className="album py-1">

                <div className="container">
                    <a className='mb-3' href='mailto:  csrtiber@gmail.com'> csrtiber@gmail.com</a>
                    <br/>
                    <br/>
                    <a href='mailto:  csrtiber.csb@nic.in'> csrtiber.csb@nic.in</a>
                </div>
            </div>
            <br />
        </div>
    )
}

export default Feedback