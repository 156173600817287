import React from 'react'
import Navbar from "../inc/Navbar";
import DrOshiniphoto from "../images/dr.Oshini.jpg";

import ico from "../images/ico.jpeg"

import id from "../images/id.jpeg";
import Arunkumar from "../images/Arunkumarsir.jpg";

import rg from "../images/rg.jpeg";
import {Link} from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import { Table } from "react-bootstrap";

function Arunk() {
    return (
        <div>
            <Navbar />
            <br />
            <br/>
            <br />
            <br />
            <br />
            <br />
            <ScrollToTop smooth top="100" color="darkBlue"/>
            <div className="sericultural">

                <div className="album py-1">

                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house-door" viewBox="0 0 16 16">
  <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4z"/>
</svg> Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/scientist-profile" ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
  <path fill-rule="evenodd" d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
</svg> Back</Link></li>
                                <li className="breadcrumb-item active" aria-current="page"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-lines-fill" viewBox="0 0 16 16">
  <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5m.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1z"/>
</svg> Contact Us</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br />
            <div className="album py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 mt-3 me-5">
                            
                                <img src={Arunkumar}  className="card-img-top" alt="Arunkumar"/>
                               
                            
                        </div>
                        <div className="col-md-6 mt-3 ">
                            
                     <br/>
                   
                                <div className="card-body">
                                    <h3 style={{color:"darkBlue"}} className="card-title">Mr. Arun Kumar   </h3>
                                    <p className="card-text">Scientist-B (Reeling and Spinning section )</p>

                                    <hr style={{color:"green"}}/>
                                    <br/>
                                 
                                     
<br/>
                                       
                                            <hr style={{color:"green"}}/>
                                    
                                </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <div className="album py-2">
                <div className="container">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
  <li className="nav-item" role="presentation">
    <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">ABOUT</button>
  </li>
  <li className="nav-item" role="presentation">
    <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">RESEARCH</button>
  </li>
  <li className="nav-item" role="presentation">
    <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">PUBLICATION</button>
  </li>
  <li className="nav-item" role="presentation">
    <button className="nav-link" id="education-tab" data-bs-toggle="tab" data-bs-target="#education" type="button" role="tab" aria-controls="education" aria-selected="false">EDUCATION</button>
  </li>
  <li className="nav-item" role="presentation">
    <button className="nav-link" id="projects-tab" data-bs-toggle="tab" data-bs-target="#projects" type="button" role="tab" aria-controls="projects" aria-selected="false">PROJECTS</button>
  </li>
</ul>
<div className="tab-content" id="myTabContent">
  <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
    <br/>
    <h6 className='texts mb-3' >Arun Kumar is currently working as Scientist-B at the Central Silk Board in Berhampore, West Bengal, leading the Reeling and Spinning section. He is working to optimize silk production methodologies and drive innovation in the industry, collaborating with cross-functional teams and mentoring staff. Arun Kumar ensures compliance with safety regulations and represents the department and sector in senior management and policy meetings.</h6>
    <h6 className='texts mb-3 texts'>Previously, Arun kumar was a Manager of Marketing & Technical Services (Polyester) at Reliance Industries Limited, Navi Mumbai, developing and establishing the new products at customer end and improving customer satisfaction. He was joined as a GET in Technical Services PSF at Reliance Industries Limited and interned at Alok Industries Ltd, successfully completed a project focusing on productivity optimization in weaving technology.</h6>
    <h6 className='texts mb-3'>Academically, Arun Kumar holds a B.Tech. in Textile Engineering, having knowledge of technical textiles, spinning, textile design and Weaving, from UPTTI and achieved GATE 2022 AIR-39 organised by IIT kharagpur and AIR 36 in GATE 2019 organised by IIT Kanpur. He has also volunteered as a Technical Lead at Unmulan NGO, contributing to various events and initiatives. Arun kumar has technical and managerial skills and experience which was appreciated in many events, he is dedicated to advancing quality silk production & it’s by product utilisation through continuous innovation and improvement.</h6>

   
  </div>
  <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
    <br/>
    <h6 className='texts mb-3'>	As a dedicated researcher for the silk industry, he is deeply involved in enhancing reeling and spinning methodologies while leading the charge in developing innovative solutions for the post-cocoon sector of sericulture. With a commitment to technological advancement and sustainability, I strive to empower silk producers with the tools they need to thrive in a rapidly evolving market.</h6>
    <h6>Enthusiastically exploring avenues for new silk product development, I'm committed to leveraging innovative solutions in the realm of sericulture.</h6>
  </div>
  <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
    <br/>
   
  </div>
  <div className="tab-pane fade" id="education" role="tabpanel" aria-labelledby="education-tab">
    <br/>
   
    <div className="album py-1">
        <div className="container">

          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th> <center style={{ color: "maroon" }}>SL.</center></th>
                <th><center style={{ color: "maroon" }}> Degree</center></th>
                <th><center style={{ color: "maroon" }}>Year of Passing</center></th>
                <th><center style={{ color: "maroon" }}> University / College</center></th>

              </tr>
            </thead>
            <tbody>

              <tr >
                <td > <center className='mb-2 mt-2'>1</center></td>
                <td><center className='mb-2 mt-2'>Intermediate</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>2014</center></td>
                <td><center className='mb-2 mt-2'>Jawahar Navodaya Vidyalaya Unnao UP </center></td>



              </tr>
              <tr >
                <td > <center className='mb-2 mt-2'>2</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>B.tech </center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>2019</center></td>
                <td><center className='mb-2 mt-2'>Uttar Pradesh textile Technology Institute Kanpur UP </center></td>



              </tr>
             


            </tbody>
          </Table>
        </div>
      </div>
  </div>
  <div className="tab-pane fade" id="projects" role="tabpanel" aria-labelledby="projects-tab">
    <br/>
    <h6 className='mb-3'>CFW02023MIC: - Study the weaving and knitting performance of WB & NE mulberry silk yarn & Comfort value of its fabric</h6>
    <h6>Starting year: - Feb 2024 duration 24 months </h6>
  </div>
</div>
</div>
</div>
<br/>
<br/>
        </div>
    )
}

export default Arunk