import React from 'react'
import Navbar from '../inc/Navbar'
import Table from 'react-bootstrap/Table';
import {Link} from "react-router-dom";

function AdminstrationFaculty() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <div className="sericultural">

<div className="album py-1">

    <div className="container mt-3">
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/" href="#"> Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Administration</li>
            </ol>
        </nav>
    </div>
</div>
</div>
<br />
       <div className="album py-1">
        <div className="container">
        
          <Table striped bordered hover variant="light">
            <thead>
              <tr>
                <th>#</th>
                <th>Name & designation</th>
                <th>Email  - ID</th>
                <th>Mobile No.</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>	TAPAS CHAKRABORTY, (2913), SUPERINTENDENT (ADMN.)</td>
                <td><a href='mailto: tapas_borty@yahoo.co.in'>tapas_borty@yahoo.co.in</a></td>
                <td><a href='tel: 9433030912'> +91 9433030912</a></td>
              </tr>
              <tr>
                <td>2</td>
                <td>SANTI PROSAD LAHIRI (5159), ASSISTANT SUPERINTENDENT (ADMN.)</td>
                <td><a href='mailto: lahirisp68@gmail.com'>lahirisp68@gmail.com</a></td>
                <td><a href='tel: 9735272686'>+91 9735272686</a></td>
              </tr>
              <tr>
                <td>3</td>
                <td >Basudeb ganguly (4816), ASSISTANT SUPERINTENDENT (ADMN.)</td>
                <td><a href='mailto: bgber.66@gmail.com'>bgber.66@gmail.com</a></td>
                <td><a href='tel: 7602762813'>+91 7602762813</a></td>
              </tr>
              <tr>
                <td>4</td>
                <td >SRINIVAS RAO M (5210), ASSISTANT SUPERINTENDENT (ADMN.)</td>
                <td><a href='mailto: mallempudisrinu@gmail.com'>mallempudisrinu@gmail.com</a>	</td>
                <td><a href='tel: 9437375408'>+91 9437375408</a></td>
              </tr>
              <tr>
                <td>5</td>
                <td >GOURANGA PATEL (03862) ASSISTANT SUPERINTENDENT (ADMN.)</td>
                <td><a href='mailto: gourangapatel1965@gmail.com'>gourangapatel1965@gmail.com</a></td>
                <td><a href='tel: 9348957211'>+91 9348957211</a></td>
              </tr>
              <tr>
                <td>6</td>
                <td >	ARUN KUMAR ROYCHOWDHURY (03839) ASSISTANT SUPERINTENDENT (ADMN.)</td>
                <td><a href='mailto: akrckhagra@gmail.com'>akrckhagra@gmail.com</a>		</td>
                <td><a href='tel: 9332107933'> +91 9332107933</a></td>
              </tr>
           
            
              <tr>
                

              </tr>
            </tbody>
          </Table>
        </div>
      </div>
      <br/>
    </div>
  )
}

export default AdminstrationFaculty