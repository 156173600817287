import React from 'react'
import Navbar from '../inc/Navbar'
import { Link } from 'react-router-dom';
import ScrollToTop from "react-scroll-to-top";

function Directorbenefit() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <ScrollToTop smooth top="100" color="maroon" />

<div className="sericultural">

    <div className="album py-1">

        <div className="container mt-4">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/" href="#">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Director Benefit Transfer</li>
                </ol>
            </nav>
        </div>
    </div>
</div>


        <div className='colorbacks'>
        <br/>
        <br/>
        <div className="album py-3">
                <div className="container">
                    <center>
                    <h3 style={{color:"darkblue"}}><b>DIRECT BENEFIT TRANSFER (DBT)</b></h3>
                    <h6><b>Download List of beneficiaries in details (13 batches trained during the period June - Dec., 2016)</b></h6>
                    </center>
                    </div>
                    </div>
                    <br/>
        </div>
       

    </div>
  )
}

export default Directorbenefit