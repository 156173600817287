import React from 'react'
import Navbar from '../inc/Navbar'

import {Link} from "react-router-dom";

function Rti15() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className="sericultural">

<div className="album py-1">

    <div className="container mt-3">
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                
                <li className="breadcrumb-item"><Link to="/" href="#"> Home</Link></li>
                <li className="breadcrumb-item"><Link to="/rti" href="#"> Back</Link></li>
                <li className="breadcrumb-item active" aria-current="page">RTI15</li>
            </ol>
        </nav>
    </div>
</div>
</div>
            <br />
            <div className="album py-1">
                <div className="container ">
                    <h4 style={{ color: "darkblue" }}>The particulars of facilities available to citizens for obtaining information, including the working hours of a library or reading room, if maintained for public use.

     

</h4>
                    <br />
                    <br />
                  <p>Library for reading facility is available during office working hours i.e. from 9.30 am. to 5.00 pm. However, prior permission of the Director/ Competent Authority is necessary for using library facilities by public. Students of schools/ colleges require nomination from their respective institute head.</p>





    






                </div>
            </div>
            <br />
            <br />

        </div>
    )
}

export default Rti15