import React from 'react'
import Navbar from "../inc/Navbar";
import DrOshiniphoto from "../images/dr.Oshini.jpg";

import ico from "../images/ico.jpeg"

import id from "../images/id.jpeg";
import Ravisainimr from "../images/Mr. Ravi Saini.jpeg";

import rg from "../images/rg.jpeg";
import {Link} from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import { Table } from "react-bootstrap";

function Ravisaini() {
    return (
        <div>
            <Navbar />
            <br />
            <br/>
            <br />
            <br />
            <br />
            <br />
            <ScrollToTop smooth top="100" color="darkBlue"/>
            <div className="sericultural">

                <div className="album py-1">

                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house-door" viewBox="0 0 16 16">
  <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4z"/>
</svg> Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/scientist-profile" ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
  <path fill-rule="evenodd" d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
</svg> Back</Link></li>
                                <li className="breadcrumb-item active" aria-current="page"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-lines-fill" viewBox="0 0 16 16">
  <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5m.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1z"/>
</svg> Contact Us</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br />
            <div className="album py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 mt-3 me-5">
                            
                                <img src={Ravisainimr}  className="card-img-top" alt="mrravisaini"/>
                               
                            
                        </div>
                        <div className="col-md-6 mt-3 ">
                            
                     <br/>
                   
                                <div className="card-body">
                                    <h3 style={{color:"darkBlue"}} className="card-title">Mr. Ravi Saini  </h3>
                                    <p className="card-text">Scientist-B (Soil Science and Chemistry Section )</p>

                                    <hr style={{color:"green"}}/>
                                    <br/>
                                    {/* <a  href='mailto: nagarajulvrth62@gmail.com '>
                                        <h6 style={{color:"grey"}}><svg xmlns="http://www.w3.org/2000/svg" style={{color:'green'}} width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
</svg> nagarajulvrth62@gmail.com</h6> 
                                        </a> */}
                                        <a  href='mailto: ravisaini09121998@gmail.com'>
                                        <h6 style={{color:"grey"}}><svg xmlns="http://www.w3.org/2000/svg" style={{color:'green'}} width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
</svg> ravisaini09121998@gmail.com</h6> 
                                        </a>
<br/>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                          
                                            {/* <a href='https://scholar.google.com/citations?hl=en&authuser=1&user=VqculIUAAAAJ' target="_blank" rel="noopener noreferrer">
                                            <img src={ico} height="30px" width="30px" alt='Scholar'/>
                                            </a>
                                     */}
                                            <a href='https://orcid.org/0009-0000-0400-2765' target="_blank" rel="noopener noreferrer">
                                            <img src={id} height="30px" width="30px" alt=''/>
                                            </a>
                                          
                                            <a href='https://www.researchgate.net/profile/Ravi-Saini-16?' target="_blank" rel="noopener noreferrer">
                                            <img src={rg} height="30px" width="30px" alt='Researchgate'/>
                                            </a>
                                            </div>
                                            <hr style={{color:"green"}}/>
                                    
                                </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <div className="album py-2">
                <div className="container">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
  <li className="nav-item" role="presentation">
    <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">ABOUT</button>
  </li>
  <li className="nav-item" role="presentation">
    <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">RESEARCH</button>
  </li>
  <li className="nav-item" role="presentation">
    <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">PUBLICATION</button>
  </li>
  <li className="nav-item" role="presentation">
    <button className="nav-link" id="education-tab" data-bs-toggle="tab" data-bs-target="#education" type="button" role="tab" aria-controls="education" aria-selected="false">EDUCATION</button>
  </li>
  <li className="nav-item" role="presentation">
    <button className="nav-link" id="projects-tab" data-bs-toggle="tab" data-bs-target="#projects" type="button" role="tab" aria-controls="projects" aria-selected="false">PROJECTS</button>
  </li>
</ul>
<div className="tab-content" id="myTabContent">
  <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
    <br/>
    <h6 className='texts mb-3' >Mr. Ravi Saini is currently working as a Scientist-B in Soil Science and Chemistry Section at Central Sericultural Research and Training Institute, Berhampore, West Bengal.</h6>
   
  </div>
  <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
    <br/>
    <h6 className='texts mb-3'>	His research interests are broad and include Development of novel fertilizers, Soil fertility, Plant nutrition, Plant phenomic, nutrient management in crops and precision nutrient management</h6>
  </div>
  <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
    <br/>
    <h6 className='texts mb-3'>Saini R, Manjaiah KM, Chobhe KA, Raju D, Ranjan R, Bana RS, Kumar A, Patil KV. Root Morphological Characteristics of Five Rice Genotypes with Different Nitrogen Use Efficiency. International Journal of Environment and climate change. 2023 Sep 20;13(10):3690-7.doi: <a href='https://doi.org/10.9734/ijecc/2023/v13i103040'>https://doi.org/10.9734/ijecc/2023/v13i103040</a></h6>
    <h6>Gawdiya S, Kumar D, Shivay YS, Radheshyam, Nayak S, Ahmed B, Kour B, Singh S, Sadhukhan R, Malik S, Saini R. Nitrogen-Driven Genotypic Diversity of Wheat (Triticum aestivum L.) Genotypes. Agronomy. 2023 Sep 22;13(10):2447.doi: <a href='https://doi.org/10.3390/agronomy13102447'>https://doi.org/10.3390/agronomy13102447</a></h6>
    <h6> Gawdiya S, Kumar D, Shivay YS, Kour B, Kumar R, Meena S, Saini R, Choudhary K, Al-Ansari N, Alataway A, Dewidar AZ. Field screening of wheat cultivars for enhanced growth, yield, yield attributes, and nitrogen use efficiencies. Agronomy. 2023 Jul 29;13(8):2011.doi: <a href='https://doi.org/10.3390/agronomy13082011 '>https://doi.org/10.3390/agronomy13082011 </a></h6>
    <h6>Rakshitha KR, Chobhe KA, Dandapani R, Sahoo RN, Kumar A, Saini R, Manjaiah KM. Effect of Nitrogen Fertilizers Applied through Nano Clay Polymer Composites on Nitrogen Use Efficiency of Rice. International Journal of Plant & Soil Science. 2023 Oct 10;35(20):1252-9. doi: <a href='https://doi.org/10.9734/ijpss/2023/v35i203924'>https://doi.org/10.9734/ijpss/2023/v35i203924</a></h6>
  </div>
  <div className="tab-pane fade" id="education" role="tabpanel" aria-labelledby="education-tab">
    <br/>
   
    <div className="album py-1">
        <div className="container">

          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th> <center style={{ color: "maroon" }}>SL.</center></th>
                <th><center style={{ color: "maroon" }}> Degree</center></th>
                <th><center style={{ color: "maroon" }}>Year of Passing</center></th>
                <th><center style={{ color: "maroon" }}> University / College</center></th>

              </tr>
            </thead>
            <tbody>

              <tr >
                <td > <center className='mb-2 mt-2'>1</center></td>
                <td><center className='mb-2 mt-2'>Ph.D. in Soil Science</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>Pursuing</center></td>
                <td><center className='mb-2 mt-2'>1	Ph.D. in Soil Science	Pursuing	ICAR-Indian Agricultural Research Institute, New Delhi</center></td>



              </tr>
              <tr >
                <td > <center className='mb-2 mt-2'>2</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>M.Sc. in Soil Science and Agricultural Chemistry </center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>2021</center></td>
                <td><center className='mb-2 mt-2'> ICAR-Indian Agricultural Research Institute, New Delhi</center></td>



              </tr>
              <tr >
                <td > <center className='mb-2 mt-2'>3</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>B.Sc. (Hons.)Agriculture</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>2019</center></td>
                <td><center className='mb-2 mt-2'><small>Sri Karan Narendra Agriculture University, Jobner, Jaipur</small></center></td>



              </tr>


            </tbody>
          </Table>
        </div>
      </div>
  </div>
  <div className="tab-pane fade" id="projects" role="tabpanel" aria-labelledby="projects-tab">
    <br/>
    <h6>Project proporal on “Fertilizer Use Pattern and Fertility Status of Mulberry Growing Soils of Eastern and North-Eastern States of India” has been submitted for evaluation</h6>
  </div>
</div>
</div>
</div>
<br/>
<br/>
        </div>
    )
}

export default Ravisaini