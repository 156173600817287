import React from 'react'
import Navbar from '../inc/Navbar'

import {Link} from "react-router-dom";

function Rti6() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className="sericultural">

<div className="album py-1">

    <div className="container mt-3">
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                
                <li className="breadcrumb-item"><Link to="/" href="#"> Home</Link></li>
                <li className="breadcrumb-item"><Link to="/rti" href="#"> Back</Link></li>
                <li className="breadcrumb-item active" aria-current="page">RTI6</li>
            </ol>
        </nav>
    </div>
</div>
</div>
            <br />
            <div className="album py-1">
                <div className="container ">
                    <h4 style={{ color: "darkblue" }}>A statement of the categories of documents that are held by it or under its control.     

</h4>
                    <br />
                    <br />
                    <h6> <b>Some of the statement of the categories of documents is as follows:</b></h6>
<br/>
<h6>Annual Report</h6>
<h6>Annual Action Plan</h6>
<h6>Minutes of Research Council (RC), Research Advisory Committee (RAC) and all mother meetings <br/>
Technical Bulletin, News bulletin <br/>
Booklets, Technology descriptor, Success story book, Book Chapters etc. <br/>
Service Book of all officials <br/>
Annual Performance Appraisal Report <br/>
Asset register, Stock register (both consumable & Dead stock) <br/>
Stock Register of Books & Journals <br/>
Vehicle Registers & Logbooks<br/>
Documents of regular correspondence, office notes, orders, memorandums, reports, vouchers, bills etc. <br/> <br/>
Service book rules like :</h6>



     <h6>Fundamental Rules and Supplementary Rules (FRSR) of Central Govt. <br className='mb-2'/>
CCS Leave Rule <br/>

CCS LTC Rules<br/>
CCS Conduct Rules <br/>
CCS (CCA) Rules <br/>
CS (MA) Rules <br/>
CCS (CCA) Rules <br/>
CCS General Financial Rule <br/>
CCS Staff Car Rules <br/>
CCS TA Rules <br/>
Central Silk Board Act & Rule</h6>
<br/>


                </div>
            </div>
            <br />
            <br />

        </div>
    )
}

export default Rti6