import React from 'react'
import Navbar from '../inc/Navbar'

import {Link} from "react-router-dom";

function Rti5() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className="sericultural">

<div className="album py-1">

    <div className="container mt-3">
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                
                <li className="breadcrumb-item"><Link to="/" href="#"> Home</Link></li>
                <li className="breadcrumb-item"><Link to="/rti" href="#"> Back</Link></li>
                <li className="breadcrumb-item active" aria-current="page">RTI5</li>
            </ol>
        </nav>
    </div>
</div>
</div>
            <br />
            <div className="album py-1">
                <div className="container ">
                    <h4 style={{ color: "darkblue" }}>The rules, regulations, instructions, manuals and records, held by it or under its control or used by its employees for discharging its function.      

</h4>
                    <br />
                    <br />
                    <h6><b style={{color:"maroon"}}>Administrative Part:   </b>


<br/>
                <br/>The rules, regulations, instructions, manuals and records, held by it or under its control are used by its employees for discharging its functions are the Government of India Rules adopted by the Board, such as FRSR, GFR, CCS (CCA) Rules, CCS (Conduct) Rules, CCS (Leave) Rules, Pension Rules, GPF Rules, General Rules, T.A. Rules, LTC Rules, Rules for Children Education allowance, House building advance, Recruitment rules.
                </h6>
<br/>
<h6>As far as recruitment of Labourers are concerned the rules framed by Central Silk Board is followed.</h6>
<h6>Rules for interest subsidy of Housing loans are also followed.</h6>
<br/>

                <h6><b style={{color:"maroon"}}>Scientific and Technical Part:</b>

 <br/>
 <br/>

 As regards technical / scientific matters, all the published, unpublished data relating to projects/programmes/pilot studies, Annual reports, Booklets, Leaflets, Technical Bulletin, Technology descriptor, Success Stories etc. and all kinds of data relating to routine work are the property of the Institute..</h6>
<br/>
<h6 className='mb-3'>Hence, those are preserved in the Institute and are subject to accessible to public.</h6>

                </div>
            </div>
            <br />
            <br />

        </div>
    )
}

export default Rti5