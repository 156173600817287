import React from 'react'
import Navbar from '../inc/Navbar'
import { Table } from "react-bootstrap";
import {Link} from "react-router-dom";
import Readyrecon from './Readyrecon';
import Forewarningpests from './Forewarningpests';
import ForewarninDiseases from './ForewarningDiseases';

function Forewarning() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <div className="sericulturalberhamporemurshidabad">

    <div className="album py-1">

        <div className="container mt-3">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/" style={{color:"white"}} href="#">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Forewarning</li>
                </ol>
            </nav>
        </div>
    </div>
</div>
<br/>
        <div className="album py-1">
        <div className="container">

          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th> <center style={{ color: "maroon" }}>SL.</center></th>
                {/* <th><center style={{ color: "maroon" }}> Date</center></th> */}
                <th><center style={{ color: "maroon" }}>Topic</center></th>
        

              </tr>
            </thead>
            <tbody>

              <tr >
                <td > <center className='mb-2 mt-2'>1</center></td>
                {/* <td><center className='mb-2 mt-2'>18.05.2023</center></td> */}
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>	 <ForewarninDiseases/> </center></td>
           



              </tr>
              <tr >
                <td > <center className='mb-2 mt-2'>2</center></td>
                {/* <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>19.06.2023</center></td> */}
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'><Readyrecon/></center></td>
             



              </tr>
              <tr >
                <td > <center className='mb-2 mt-2'>3</center></td>
                {/* <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>12.07.2023</center></td> */}
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>	<Forewarningpests/></center></td>
               



              </tr>


            </tbody>
          </Table>
        </div>
      </div>
      <br/>
    </div>
  )
}

export default Forewarning