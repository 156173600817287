import React from 'react'
import Navbar from '../inc/Navbar'

import {Link} from "react-router-dom";

function Rti4() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className="sericultural">

<div className="album py-1">

    <div className="container mt-3">
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                
                <li className="breadcrumb-item"><Link to="/" href="#"> Home</Link></li>
                <li className="breadcrumb-item"><Link to="/rti" href="#"> Back</Link></li>
                <li className="breadcrumb-item active" aria-current="page">RTI4</li>
            </ol>
        </nav>
    </div>
</div>
</div>
            <br />
            <div className="album py-1">
                <div className="container ">
                    <h4 style={{ color: "darkblue" }}>The Norms set by it for the discharge of its function :       

</h4>
                    <br />
                    <br />
                    <h6><b style={{color:"maroon"}}>Research:</b>

     <br/>
<br/>
Need based research projects in Mulberry sericulture are formulated by respective scientists following the setout procedure as discussed in item number-3.</h6>
                <br/>
                <h6><b style={{color:"maroon"}}>Administration:</b>

 <br/>
 <br/>

1. Central Silk Board has adopted / followed the rules and manuals of Government of India i.e., FRSR, GFRs etc.</h6>
<br/>
<h6 className='mb-3'>2. Moreover, Central Silk Board has its own act called CSB Act, 1948 and CSB, Rules, 1955.</h6>
<h6>3. In addition whatever norms are there i.e. Labour norms, discharge of various functions, norms for employment are set by Central Silk Board.</h6>
<br/>
<h6>4. Repairs and maintenance of vehicle are made following the procedure laid down under G.F.R.</h6>
<h6>5. Repair of buildings are entrusted to C.P.W.D. Procurement, awards of contracts are lined up based on the provision of G.F.R. and also followed the guidelines of Central Vigilance Commission as issued from time to time.</h6>
<h6>6. Selection of candidates for special assignment, Prizes, Consultancies etc. are decided by the Member Secretary, Central Silk Board, Bangalore.</h6>
<h6>7. Sanction of Study Leave is considered following CCS (Leave) Rules, 1965 and this is sanctioned by the Member Secretary, Central Silk Board, Bangalore depending upon its merit.</h6>

                </div>
            </div>
            <br />
            <br />

        </div>
    )
}

export default Rti4