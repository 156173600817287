import React from 'react'
import Navbar from "../inc/Navbar";
import { Table, Button } from "react-bootstrap";
import Admissionnoti from './Admissionnoti';
import Pgdsextensiondate from './Pgdsextensiondate';
import Pgdsmulberry from './Pgdsmulberry';
import Pgds202425 from './Pgds20425';

function Advertise() {
  return (
    <div>
      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="album py-1">
        <div className="container">
          <center><h3>ADVERTISEMENT</h3></center>
          <hr />
        </div>
      </div>
      <br />
      <br />


      <div className="album py-1">
        <div className="container">

          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th> <center style={{ color: "maroon" }}>SL.</center></th>
                <th><center style={{ color: "maroon" }}> Date</center></th>
                <th><center style={{ color: "maroon" }}>Subject</center></th>
                <th><center style={{ color: "maroon" }}> View / Download</center></th>

              </tr>
            </thead>
            <tbody>
            <tr >
                <td > <center className='mb-2 mt-2'>1</center></td>
                <td><center className='mb-2 mt-2'>24.05.2024</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>Admission Notification - PGDS 2024-25</center></td>
                <td><center className='mb-2 mt-2'><Pgds202425/></center></td>



              </tr>

              <tr >
                <td > <center className='mb-2 mt-2'>1</center></td>
                <td><center className='mb-2 mt-2'>18.05.2023</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>Admission Notification for Post Graduate Diploma in Sericulture (PGDS): 2023-24</center></td>
                <td><center className='mb-2 mt-2'><Admissionnoti /></center></td>



              </tr>
              <tr >
                <td > <center className='mb-2 mt-2'>2</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>19.06.2023</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>PGDS Extension date</center></td>
                <td><center className='mb-2 mt-2'><Pgdsextensiondate /></center></td>



              </tr>
              <tr >
                <td > <center className='mb-2 mt-2'>3</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>12.07.2023</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>List of Selected Candidates for PGDS (Mulberry): 2023-24</center></td>
                <td><center className='mb-2 mt-2'><Pgdsmulberry/></center></td>



              </tr>


            </tbody>
          </Table>
        </div>
      </div>
      <br />
      <br />


    </div>
  )
}

export default Advertise