import React from 'react'
import Navbar from '../inc/Navbar'
import Table from 'react-bootstrap/Table';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import Section4 from './Section4';
import Section5 from './Section5';
import Quarter3rd from './Quarter3rd';
function Rfd() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <center>
            <p>ACHIEVEMENTS ON RESULTS FRAME WORK DOCUMENTS (RFD) 2318-2319</p>
            </center>

<br/>

<div className="album py-0">

<div className="container">
<div className='colorne'>
<center ><h5 style={{color:"white"}} className='mb-3 mt-2'>Results Framework Document (RFD)</h5></center>
<center><h5 style={{color:"white"}}>Action Plan for the year (2318-2319)</h5></center>
</div>
</div>
</div>
<div className="album py-0">

<div className="container">
<Table striped>
      <thead>
        <tr>
          <th><center>Subject</center></th>
          <th><center>View/Download</center></th>
        
         
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><center>Section-1	</center></td>
          <td><center><Section1/></center></td>
   
        </tr>
        <tr>
          <td><center>Section-2</center></td>
          <td><center><Section2/></center></td>
       
        </tr>
        <tr>
          <td><center>Section-3</center></td>
          <td><center><Section3/></center></td>
       
        </tr>
        <tr>
          <td><center>Section-4</center></td>
          <td><center><Section4/></center></td>
       
        </tr>
        <tr>
          <td><center>Section-5 & 6</center></td>
          <td><center><Section5/></center></td> 
        </tr>
      </tbody>
    </Table>
    </div>
        </div>
        <center>
        <p className='mb-1'>QUARTERLY ACHIEVEMENTS</p>
        <p><small><Quarter3rd/></small></p>
        </center>
       <br/>
<br/>
    </div>
  )
}

export default Rfd