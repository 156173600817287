import React from 'react'
import Navbar from "../inc/Navbar";
import Table from 'react-bootstrap/Table';
import { Link } from "react-router-dom";
import TenderCycle from './TenderCycle';
import TenderCocoon from './TenderCocoon';
import Tender2910 from './Tender2910';
import Tender0810 from './Tender0810';
import Tender0609 from './Tender0609';
import TenderVehicles from './TenderVehicles';
import TenderIRGA from './TenderIRGA';
import Tenders2Food from './Tenders2Food';
import Tenders2Dec31 from './Tenders2Dec31';
import TendersLab from './TendersLab';
import Tenders2Basin from './Tenders2Basin';
import TendersDrip from './TendersDrip';
import Tenders2Basinm from './Tenders2Basinm';
import Tenders2DripF from './Tender2DripF';
import TendersSoil from './TendesSoil';
import TendersCatering from './TendersCatering';
import TendersNotice from './TendersNotice';


function Tenders() {
  return (
    <div>
      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />


      <div className="sericultural">

        <div className="album py-1">

          <div className="container mt-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item"><Link to="/Contacts" >Contact Us</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Tenders</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="album py-3">
        <div className="container">
          <h5>AUCTION</h5>
          <Table striped bordered hover variant="danger">
            <thead>
              <tr>
                <th>No</th>
                <th>Details</th>
                <th>Date & Time</th>
                <th>Extended (If So)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>9</td>
                <td><TenderCycle/></td>
                <td>05-08-2022	</td>
                <td></td>
              </tr>
              <tr>
                <td>8</td>
                <td>	<TenderCocoon/></td>
                <td>03-03-2022</td>
                <td></td>
              </tr>
              <tr>
                <td>7</td>
                <td ><Tender2910/></td>
                <td>29-10-2021 (11:30)	</td>
                <td></td>
              </tr>
              <tr>
                <td>6</td>
                <td ><Tender0810/></td>
                <td>08-10-2021 (11:30)	</td>
                <td></td>
              </tr>
              <tr>
                <td>5</td>
                <td ><Tender0609/></td>
                <td>06-09-2021 (11:30)	</td>
                <td></td>
              </tr>
              <tr>
                <td>4</td>
                <td ><TenderVehicles/></td>
                <td>25-09-2020 (13:00)		</td>
                <td></td>
              </tr>
              <tr>
                <td>3</td>
                <td >Disposal of debris (broken roof top materials) from the campus of CSR&TI, Berhampore	</td>
                <td>14-11-2019 (13:00)	</td>
                <td></td>
              </tr>
              <tr>
                <td>2</td>
                <td >Auction of Pruned twigs of mulberry plants and Green trees wood & dried mulberry shoots	</td>
                <td>11-10-2019 (11:00)	</td>
                <td></td>

              </tr>
              <tr>
                <td>1</td>
                <td >BPY 7705 महिंद्रा जीप का टेंडर करके डिस्पोजल करने के सबंध मे !		</td>
                <td>27-12-2018 (15:00)
                </td>
                <td></td>

              </tr>
            </tbody>
          </Table>
        </div>
      </div>
      <div className="album py-1">
        <div className="container">
          <h5>TENDERS</h5>
          <Table striped bordered hover variant="primary">
            <thead>
              <tr>
                <th>No</th>
                <th>Details</th>
                <th>Last Date & Time (IST)</th>
                <th>Extended (If So)</th>
              </tr>
            </thead>
            <tbody>
            <tr>
                <td>71</td>
                <td> <Tenders2Food/> </td>
                <td>03-07-2024	</td>
                <td></td>
              </tr>
              <tr>
                <td>70</td>
                <td><TenderIRGA/></td>
                <td>24-02-2024	</td>
                <td></td>
              </tr>
              <tr>
                <td>69</td>
                <td>	<Tenders2Dec31/></td>
                <td>20-01-2022	</td>
                <td></td>
              </tr>
              <tr>
                <td>68</td>
                <td ><TendersLab/></td>
                <td>20-07-2021	</td>
                <td> <b>Cancelled</b></td>
              </tr>
              <tr>
                <td>67</td>
                <td ><Tenders2Basin/></td>
                <td>20-10-2020 (14:00)		</td>
                <td></td>
              </tr>
              <tr>
                <td>66</td>
                <td >	<TendersDrip/></td>
                <td>15-10-2020 (14:00)		</td>
                <td></td>
              </tr>
              <tr>
                <td>65</td>
                <td >	<Tenders2Basinm/></td>
                <td>28-9-2020 (14:00)		</td>
                <td></td>
              </tr>
              <tr>
                <td>64</td>
                <td ><Tenders2DripF/></td>
                <td>24-08-2020 (14:00)	</td>
                <td>15-09-2020</td>
              </tr>
              <tr>
                <td>63</td>
                <td> <TendersSoil/>	</td>
                <td>10-08-2020 (14:00)		</td>
                <td>24-08-2020</td>

              </tr>
              <tr>
                <td>62</td>
                <td >	<TendersCatering/>	</td>
                <td>04-04-2020 (14:00)	</td>
                <td>15-06-2020</td>
              </tr>
              <tr>
                <td>61</td>
                <td ><TendersNotice/>	</td>
                <td>04-03-2020 (14:00)		</td>
                <td>26-05-2020</td>
              </tr>
              <tr>
                <td>60</td>
                <td >	Invitation of quotation for setting up Decoration with supply of articles at Rabindra Sadan for Resham Krishi Mela to be held on 24.01.2020		</td>
                <td>13-01-2020 (13:00)		</td>
                <td></td>
              </tr>
              <tr>
                <td>59</td>
                <td >Invitation of Quotations for food items for Resham Krishi Mela to be held on 24.01.2020 at Rabindra Sadan, Berhampore, W.B.</td>
                <td>13-01-2020 (14:00)		</td>
                <td></td>
              </tr>
              <tr>
                <td>58</td>
                <td >		Tender for Food Catering Services for a Period of 2 years</td>
                <td>26-12-2019 (14:00)	</td>
                <td>17-01-2020</td>
              </tr>



              <tr>
                <td>57</td>
                <td >	Tender/Estimate for printing & supply of Folder and Leaflet under rate contract for a period of one year</td>
                <td>28-10-2019 (14:00)	                </td>
                <td>127-11-2019                </td>
              </tr>
              <tr>
                <td>56</td>
                <td >		Tender for Printing and supply of Envelope</td>
                <td>30-09-2019 (14:00)		</td>
                <td></td>
              </tr>
              <tr>
                <td>55</td>
                <td >	Tender for Printing and supply of Annual Report 2018-19</td>
                <td>27-09-2019 (14:00)		</td>
                <td></td>
              </tr>
              <tr>
                <td>54</td>
                <td >		Tender for supply and Installation of Subsurface Drip Fertigation System at CSR&TI, Berhampore, West Bengal</td>
                <td>25-06-2019 (14:00)	
                </td>
                <td>06-08-2019</td>
              </tr>
              <tr>
                <td>53</td>
                <td >		Request for Annual Rate Contract for purchase of Chemicals, Glassware, Plastic and other Misc. items</td>
                <td>26-12-2019 (14:00)	</td>
                <td>17-01-2020</td>
              </tr>
              <tr>
                <td>52</td>
                <td >	Tender for printing & supply of Folder and Leaflet under rate contract for a period of 1 year</td>
                <td>03-05-2019 (14:00)	</td>
                <td></td>
              </tr>





              <tr>
                <td>51</td>
                <td >Invitation for quotation for Setting up Decoration with supply of Items at CSR&TI, Berhampore for Resham Krishi Mela to be held on 29.01.2019</td>

                <td>24-01-2019 (14:00)	</td>
                <td></td>
              </tr>
              <tr>
                <td>50</td>
                <td >	Inviting quotations for food items for Resham Krishi Mela to be held on 29.01.2018 at Rabindra Sadan</td>
                <td>04-01-2019 (14:00)	</td>
                <td></td>
              </tr>
              <tr>
                <td>49</td>
                <td >	Tender for Renewal of ISO Certificate 2018-2021</td>
                <td>12-12-2018 (12:00)	</td>
                <td></td>
              </tr>
              <tr>
                <td>48</td>
                <td >Tender for printing & supply of Soil Health Card</td>
                <td>27-11-2018 (14:00)	</td>
                <td></td>
              </tr>
              <tr>
                <td>47</td>
                <td >	
Inviting tender for disposal of Raw Silk/Silk Waste/Yarn/Cocoons
CORRIGENDUM</td>
                <td>06-09-2018 (14:00)</td>
                <td>27-09-2018 (15:00)</td>
              </tr>
              <tr>
                <td>46</td>
                <td >	Inviting tender for supply of bedding items on rental basis for temporary purpose as & when required for official use at Training Hostel/Staff Quarters - regarding</td>

                <td>29-08-2018 (14:00)	</td>
                <td></td>
              </tr>
              <tr>
                <td>45</td>
                <td >	Inviting tender for engagement of Drivers at CSR&TI, Berhampore & REC, Mothabari (Malda), W.B</td>
                <td>08-08-2018 (14:00)</td>
                <td></td>
              </tr>
              <tr>
                <td>44</td>
                <td >	Inviting tender for Jungle clearance and disposal in the big pond of CSR&TI, Berhampore, W.B.
                  CORRIGENDUM</td>
                <td>23-07-2018 (14:30)	</td>
                <td></td>
              </tr>
              <tr>
                <td>43</td>
                <td >	Inviting tender for engagement of Contract Attender and Electrician at CSR&TI, Berhampore, W.B.</td>
                <td>25-07-2018 (14:00)</td>
                <td>16-08-2018 (14:00)</td>
              </tr>
              <tr>
                <td>42</td>
                <td >	ender for purchase of Stationery/Misc. intems under rate contract</td>
                <td>13-07-2018 (14:00)	</td>
                <td></td>
              </tr>
              <tr>
                <td>41</td>
                <td >	              Quotation for Supply, Installation, Testing and Commissioning of water ATM of capacity 1000 liters per day in the reverse osmosis water treatment plant at CSR&TI, Berhampore                </td>
                <td>15-06-2018 (14:30)	</td>
                <td></td>
              </tr>
              <tr>
                <td>40</td>
                <td >		Quotation for procurement of Scientific Equipment of Gradient PCR</td>
                <td>28-05-2018 (!4:00)</td>
                <td></td>
              </tr>

            </tbody>
          </Table>
        </div>
      </div>
      <br />
      <br />

    </div>
  )
}

export default Tenders