import React from 'react'
import Navbar from '../inc/Navbar'
import Table from 'react-bootstrap/Table';
import { Link } from "react-router-dom";
import Circularstrans from './Circularstrans';
import CircularsNational from './CircularsNational';
import CircularsCcs from './CircularsCcs';
import Circularvill from './CircularsVill';
import CircularsYouth from './CircularsYouth';
import CircularsPradhan from './CircularsPradhan';
import CircularsGandhi from './CircularsGandhi';
import CircularsSensiting from './CircularsSensiting';
import CircularsNoTobacco from './CircularsNoTobacco';



function Circulars() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

            <div className="sericultural">

                <div className="album py-1">

                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/Contacts" >Contact Us</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Circulars</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <div className="album py-3">
            <div className="container">
            <Table striped bordered hover variant="light">
            <thead>
              <tr>
                <th>No</th>
                <th>Circular Detail</th>
                <th>Document</th>
            
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>9</td>
                <td>	Request transfer of employees of CSB for the year 2020</td>
                <td><Circularstrans/></td>
              
              </tr>
              <tr>
                <td>8</td>
                <td>Observance of "Rashtriya Ekta Diwas (National Unity Day)"</td>
                <td><CircularsNational/></td>
               
              </tr>
              <tr>
                <td>7</td>
                <td >Office order for compliance of Central Civil Services (Conduct) Rules 1965</td>
                <td><CircularsCcs/></td>
               
              </tr>
              <tr>
                <td>6</td>
                <td >	Observance of Vigilance Awareness Week for the year 2019</td>
                <td><Circularvill/> </td>
               
              </tr>
              <tr>
                <td>5</td>
                <td >	International Youth Contest of Social Anti-Corruption Advertising "Together Against Corruption!"... hosting of poster / video competition in India from 01-06-2019 to 30-09-2019</td>
                <td><CircularsYouth/></td>
               
              </tr>
              <tr>
                <td>4</td>
                <td >	Recomendation for Pradhan Matri Bal Puraskar-2020 - regarding</td>
                <td><CircularsPradhan/>	</td>
                
              </tr>
              <tr>
                <td>3</td>
                <td >	Information regarding activities to be taken up for commemoration of 150th birth anniversary of Mahatma Gandhi by the Ministry of Textiles - reg.	</td>
                <td><CircularsGandhi/></td>
               
              </tr>
              <tr>
                <td>2</td>
                <td >Sensiting the Public about corruption - Display of Standard Notice Board by Departments/Organisations		</td>
                <td><CircularsSensiting/>	</td>
               

              </tr>
              <tr>
                <td>1</td>
                <td >Observance of World No Tobacco Day on 31.05.2019	</td>
                <td><CircularsNoTobacco/> </td>
              

              </tr>
            </tbody>
          </Table>
</div>
</div>


            {/* <div className="album py-3">
                <div className="container">
                <Table striped bordered hover variant="danger">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Circular Detail</th>
                                <th>Document</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>9</td>
                                <td>Request transfer of employees of CSB for the year 2020		</td>
                                <td>View/Download</td>

                            </tr>
                            <tr>
                                <td>8</td>
                                <td>	Observance of "Rashtriya Ekta Diwas (National Unity Day)"	</td>
                                <td>View/Download</td>

                            </tr>
                            <tr>
                                <td>7</td>
                                <td>Office order for compliance of Central Civil Services (Conduct) Rules 1965	</td>
                                <td>View/Download</td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>Observance of Vigilance Awareness Week for the year 2019	</td>
                                <td>View/Download</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>International Youth Contest of Social Anti-Corruption Advertising "Together Against Corruption!"... hosting of poster / video competition in India from 01-06-2019 to 30-09-2019		</td>
                                <td>View/Download</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td> Recomendation for Pradhan Matri Bal Puraskar-2020 - regarding	</td>
                                <td>View/Download</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>  Information regarding activities to be taken up for commemoration of 150th birth anniversary of Mahatma Gandhi by the Ministry of Textiles - reg.	</td>
                                <td>View/Download</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td> Sensiting the Public about corruption - Display of Standard Notice Board by Departments/Organisations	</td>
                                <td>View/Download</td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td> Observance of World No Tobacco Day on 31.05.2019		</td>
                                <td>View/Download</td>
                            </tr>


                        </tbody>
                    </Table>
                </div>
            </div> */}
        </div>
    )
}

export default Circulars