import React from 'react'
import Navbar from "../inc/Navbar";
import Nagarju from "../images/dr. nararaju.jpg";
import Oshinmam from "../images/drOshini-s.jpg";
import Drjavidur from "../images/Dr. Javid Ur Rahman.jpg";
import Pradeep from "../images/Pradeep S D.jpg"
import { Link } from "react-router-dom";
import Ravisini from "../images/Mr. Ravi Saini.jpg";
import HARSHITHA from "../images/HARSHITHA BS.jpg";
import Facultypage from "../images/faculty page.jpg";
import Srinivas from "../images/srinivas sir.jpg"
import Parameswaranaik from "../images/Dr. Parameswaranaik.jpg";
import DrThangjamRanjitaDevi from "../images/Dr. Thangjam Ranjita Devi.jpg";
import ArunKumar from "../images/Arun Kumar.jpg";



import ScrollToTop from "react-scroll-to-top";
function Faculty() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br />
            <img src={Facultypage} className="d-block w-100" alt="central_sericultural_berhampore" />
            <ScrollToTop smooth top="100" color="darkBlue" />
            <div className="sericultural">

                <div className="album py-1">

                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house-door" viewBox="0 0 16 16">
                                    <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4z" />
                                </svg> Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/Contacts" href="#"> Contact Us</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Scientist Profile </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <div className="album py-3">
                <div className="container">
                    <h5>DIRECTOR PROFILE</h5>
                    <hr />
                </div>
            </div>
            <br />
            <br></br>
            <br />
            <div className="album py-1">
                <div className="container">
                    <h5>SCIENTIST-D</h5>
                    <hr />
                </div>
            </div>
            <div className="album py-1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 mt-3">
                            <a href='#'>
                                <div className='radius50'>
                                    <div className='hoverimage1'>
                                        <img src={Srinivas} className="card-img-top image2" alt="Dr. Yalavarthi Nagaraju" />
                                        <div className="overlay1">Dr. Srinivas <br /> Scientist-D
                                        </div>

                                    </div>
                                </div>
                                {/* <center>
                                    <div className='buttongrup1'>
                                        <button className="button mt-0"><h6 className='mt-3'>Dr. Yalavarthi Nagaraju </h6>
                                            <p><small>Scientist-B</small></p></button>
                                    </div>
                                </center> */}
                            </a>

                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <br />
            <div className="album py-1">
                <div className="container">
                    <h5>SCIENTIST-C</h5>
                    <hr />
                </div>
            </div>
         
            <div className="album py-1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-2 mt-3">
                            <a href='/Dr-Parameswaranaik'>
                                <div className='radius50'>
                                    <div className='hoverimage1'>
                                        <img src={Parameswaranaik} className="card-img-top image2" alt=" Dr. Parameswaranaik J" />
                                        <div className="overlay1"> Dr. Parameswaranaik J   <br /> Scientist-C
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-2 mt-3">
                            <a href='/Dr._Thangjam_Ranjita_Devi'>
                                <div className='radius50'>
                                    <div className='hoverimage1'>
                                        <img src={DrThangjamRanjitaDevi} className="card-img-top image2" alt="Dr. Thangjam Ranjita Devi"/>
                                        <div className="overlay1">Dr. Thangjam Ranjita Devi  <br /> Scientist-C
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <br />
            <br/>
            <div className="album py-1">
                <div className="container">
                    <h5>SCIENTIST-B</h5>
                    <hr />
                </div>
            </div>
            <div className="album py-2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-2 mt-3">
                            <a href='YalavarthiNagaraju'>
                                <div className='radius50'>
                                    <div className='hoverimage1'>
                                        <img src={Nagarju} className="card-img-top image2" alt="Dr. Yalavarthi Nagaraju" />
                                        <div className="overlay1">Dr. Yalavarthi Nagaraju <br /> Scientist-B
                                        </div>

                                    </div>
                                </div>
                                {/* <center>
                                    <div className='buttongrup1'>
                                        <button className="button mt-0"><h6 className='mt-3'>Dr. Yalavarthi Nagaraju </h6>
                                            <p><small>Scientist-B</small></p></button>
                                    </div>
                                </center> */}
                            </a>

                        </div>
                        <div className="col-md-2 mt-3">
                            <a href='DrOshini'>
                                <div className='radius50'>
                                    <div className='hoverimage1'>
                                        <img src={Oshinmam} className="card-img-top image2" alt="photo" />
                                        <div className="overlay1">Dr. Oshini <br /> Scientist-B
                                        </div>
                                    </div>
                                </div>


                            </a>
                        </div>
                        <div className="col-md-2 mt-3">
                            <a href='Dr.Javid-Ur-Rahaman'>
                                <div className='radius50'>
                                    <div className='hoverimage1'>
                                        <img src={Drjavidur} className="card-img-top image2" alt="Dr. Javid Ur Rahman" />
                                        <div className="overlay1">Dr. Javid Ur Rahman <br /> Scientist-B
                                        </div>

                                    </div>
                                </div>
                                {/* <center>
                                    <div className='buttongrup1'>
                                        <button className="button mt-0"><h6 className='mt-3'>Dr. Yalavarthi Nagaraju </h6>
                                            <p><small>Scientist-B</small></p></button>
                                    </div>
                                </center> */}
                            </a>

                        </div>
                        <div className="col-md-2 mt-3">
                            <a href='Pradeepsd'>
                                <div className='radius50'>
                                    <div className='hoverimage1'>
                                        <img src={Pradeep} className="card-img-top image2" alt="Pradeep" />
                                        <div className="overlay1">Dr. Pradeep S D <br /> Scientist-B
                                        </div>
                                    </div>
                                </div>


                            </a>
                        </div>
                        <div className="col-md-2 mt-3">
                            <a href='mr.Ravisaini'>
                                <div className='radius50'>
                                    <div className='hoverimage1'>
                                        <img src={Ravisini} className="card-img-top image2" alt="Mr. Ravi Saini" />
                                        <div className="overlay1">Mr. Ravi Saini  <br /> Scientist-B
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-2 mt-3">
                            <a href='ms-Harshitha-BS'>
                                <div className='radius50'>
                                    <div className='hoverimage1'>
                                        <img src={HARSHITHA} className="card-img-top image2" alt="HARSHITHA" />
                                        <div className="overlay1">HARSHITHA B S  <br /> Scientist-B
                                        </div>
                                    </div>
                                </div>


                            </a>
                        </div>

                        <div className="col-md-2 mt-3">
                            <a href='Arun_kumar'>
                                <div className='radius50'>
                                    <div className='hoverimage1'>
                                        <img src={ArunKumar} className="card-img-top image2" alt="ArunKumar"/>
                                        <div className="overlay1">Arun Kumar  <br /> Scientist-B
                                        </div>
                                    </div>
                                </div>


                            </a>
                        </div>

                    </div>
                </div>
            </div>
            <br />
            <br />

        </div>
    )
}

export default Faculty