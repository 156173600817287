import React from 'react'
import Navbar from '../inc/Navbar'
import { Table} from "react-bootstrap";
import Pubnews2022 from './Pubnews2022';
import Pubnews2022june from './Pubnews2022june';
import Pubnewsdec21 from './Pubnewsdec21';
import PubnewsJune21 from './PubnewsJune21';
import PubnewsDec20 from './PubnewsDec20';
import PubnewsJune20 from './PubnewsJune20';
import PubnewsDec19 from './PubnewsDec19';
import PubnewsJune19 from './PubnewsJune19';
import {Link} from "react-router-dom";
import Dec18 from './Dec18';
import June2018 from './Jun2018';
import Dec17 from './Dec17';
import June2017 from './June2017';

function NewsViws() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br/>
            <div className="sericultural">

                <div className="album py-1">

                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house-door" viewBox="0 0 16 16">
                                    <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4z" />
                                </svg> Home</Link></li>

                                <li className="breadcrumb-item active" aria-current="page"> News & Views</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br />
          
            <div className="album py-2">

                <div className="container">
                    <h4 style={{color:"red"}}>News & Views</h4>
                </div>
            </div>

            <div className="album py-1">
        <div className="container">

          <Table striped bordered hover size="sm">
            <thead>
              
            </thead>
            <tbody>
            <tr >
                <td > <center className='mb-2 mt-2'>December, 2022	</center></td>
                <td><center className='mb-2 mt-2'><Pubnews2022/>	</center></td>
              
               



              </tr>

              <tr >
                <td > <center className='mb-2 mt-2'>June, 2022</center></td>
                <td><center className='mb-2 mt-2'><Pubnews2022june/>	</center></td>




              </tr>
              <tr >
                <td > <center className='mb-2 mt-2'>December, 2021</center></td>
                <td><center className='mb-2 mt-2'><Pubnewsdec21/>	</center></td>
     



              </tr>
              <tr >
                <td > <center className='mb-2 mt-2'>June, 2021</center></td>
                <td><center className='mb-2 mt-2'><PubnewsJune21/>	</center></td>
              </tr>
              <tr >
                <td > <center className='mb-2 mt-2'>December, 2020</center></td>
                <td><center className='mb-2 mt-2'><PubnewsDec20/>	</center></td>
              </tr>
              <tr >
                <td > <center className='mb-2 mt-2'>June, 2020</center></td>
                <td><center className='mb-2 mt-2'><PubnewsJune20/>	</center></td>
              </tr>
              <tr >
                <td > <center className='mb-2 mt-2'>December, 2019</center></td>
                <td><center className='mb-2 mt-2'><PubnewsDec19/>	</center></td>
              </tr>
              <tr >
                <td > <center className='mb-2 mt-2'>June, 2019</center></td>
                <td><center className='mb-2 mt-2'><PubnewsJune19/></center></td>
              </tr>
              <tr >
                <td > <center className='mb-2 mt-2'>December, 2018</center></td>
                <td><center className='mb-2 mt-2'><Dec18/></center></td>
              </tr>
              <tr >
                <td > <center className='mb-2 mt-2'>June, 2018</center></td>
                <td><center className='mb-2 mt-2'><June2018/>	</center></td>
              </tr>
              <tr >
                <td > <center className='mb-2 mt-2'>December, 2017</center></td>
                <td><center className='mb-2 mt-2'><Dec17/>	</center></td>
              </tr>
              <tr >
                <td > <center className='mb-2 mt-2'>June, 2017</center></td>
                <td><center className='mb-2 mt-2'><June2017/>	</center></td>
              </tr>



            </tbody>
          </Table>
          </div>
          </div>

        </div>
    )
}

export default NewsViws