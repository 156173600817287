import React, { useState, useEffect } from 'react';
import { useNavigate,Link } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";

import { db } from '../../firebase'
import  Navbar  from "../inc/Navbar" ;









function Cocoon() {
    const [setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(15);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [august15, setAugust15] = useState([])
    const navigate = useNavigate()

    const august15CollectionRef = collection(db, "Cocoon-Selection-at-Kallimpong");
    useEffect(() => {

        const getAugust15 = async () => {
            const data = await getDocs(august15CollectionRef);
            setAugust15(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getAugust15()
    }, [])
    return (
        <>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <div className="sericultural">

<div className="album py-1">

    <div className="container mt-4">
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/" href="#"> Home</Link></li>
                <li className="breadcrumb-item"><Link to="/gallery" href="#"> Back</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Cocoon Selection at Kallimpong </li>
            </ol>
        </nav>
    </div>
</div>
</div>
        <br/>
        <br/>
         
            <div className="album py-0">

                <div className="container">

                    <div className="row">
                        {august15.filter((val) => {
                            if (setsearchTerm === "") {
                                return val;
                            } else if (
                                val.title.toLowerCase().includes(searchTerm.toLowerCase())




                            ) {
                                return val;
                            }
                        }).slice(pagination.start, pagination.end).map((centralsericultural) => {
                            return (
                                <div className="col-md-4 mt-8" >
                                    <div className="card mb-4 border-White box shadow">
                                        <img className="card-img-top" src={centralsericultural.img} alt="august15"/>
                                        <div className="card-body">
                                       
                                            <h6  style={{color:"green"}}  className="card-text"> {centralsericultural.title}</h6>





                                        
                                        </div>
                                    </div>

                                </div>
                            )
                        })}
                    </div>


                </div>
            </div>

        </>

    );
}

export default Cocoon;



