import React from 'react'
import Navbar from '../inc/Navbar'

import {Link} from "react-router-dom";

function Rti17() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className="sericultural">

<div className="album py-1">

    <div className="container mt-3">
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                
                <li className="breadcrumb-item"><Link to="/" href="#"> Home</Link></li>
                <li className="breadcrumb-item"><Link to="/rti" href="#"> Back</Link></li>
                <li className="breadcrumb-item active" aria-current="page">RTI17</li>
            </ol>
        </nav>
    </div>
</div>
</div>
            <br />
            <div className="album py-1">
                <div className="container ">
                    <h4 style={{ color: "darkblue" }}>Such other information as may be prescribed; and thereafter update these publications every year.

     

</h4>
                    <br />
                    <br />
                  <h6 className='mb-3'>The detail information under point 1 to 16 will be updated</h6>
                  <h6 className='mb-3'>Some information will be required to update as and when basis
                  </h6>
                  <h6 className='mb-3'>Some information yearly
                  </h6>





    






                </div>
            </div>
            <br />
            <br />

        </div>
    )
}

export default Rti17