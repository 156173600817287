import React from 'react'
import Navbar from "../inc/Navbar";

import {Link} from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import { Table } from "react-bootstrap";
import drdevi from "../images/Dr. Thangjam Ranjita Devi.jpeg";

function Devi() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br/>
            <br />
            <ScrollToTop smooth top="100" color="darkBlue"/>
            <div className="sericultural">

                <div className="album py-1">

                    <div className="container mt-2">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house-door" viewBox="0 0 16 16">
  <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4z"/>
</svg> Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/scientist-profile" ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
  <path fill-rule="evenodd" d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
</svg> Back</Link></li>
                                <li className="breadcrumb-item active" aria-current="page"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-lines-fill" viewBox="0 0 16 16">
  <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5m.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1z"/>
</svg> Contact Us</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br />
            <div className="album py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 mt-3 me-5">
                            
                                <img src={drdevi}  className="card-img-top" alt="Dr. Thangjam Ranjita Devi"/>
                               
                            
                        </div>
                        <div className="col-md-6 mt-3 ">
                            
                     <br/>
                   
                                <div className="card-body">
                                    <h3 style={{color:"darkBlue"}} className="card-title">Dr. Thangjam Ranjita Devi </h3>
                                    <p className="card-text">Scientist-C (Silkworm Breeding and Genetics)</p>

                                    <hr style={{color:"green"}}/>
                                    <br/>
                                    <a href='tel:    9749193661'>  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone" viewBox="0 0 16 16">
  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
</svg> +91  9749193661</a>
                                    <a  href='mailto: ranjita.csb@nic.in'>
                                        <h6 style={{color:"grey"}}><svg xmlns="http://www.w3.org/2000/svg" style={{color:'green'}} width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
</svg> ranjita.csb@nic.in</h6> 
                                        </a>
                                  
<br/>
                                    
                                            <hr style={{color:"green"}}/>
                                    
                                </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <div className="album py-2">
                <div className="container">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
  <li className="nav-item" role="presentation">
    <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">ABOUT</button>
  </li>
  <li className="nav-item" role="presentation">
    <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">RESEARCH</button>
  </li>
  <li className="nav-item" role="presentation">
    <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">PUBLICATION</button>
  </li>
  <li className="nav-item" role="presentation">
    <button className="nav-link" id="education-tab" data-bs-toggle="tab" data-bs-target="#education" type="button" role="tab" aria-controls="education" aria-selected="false">EDUCATION</button>
  </li>
  <li className="nav-item" role="presentation">
    <button className="nav-link" id="projects-tab" data-bs-toggle="tab" data-bs-target="#projects" type="button" role="tab" aria-controls="projects" aria-selected="false">PROJECTS</button>
  </li>
</ul>
<div className="tab-content" id="myTabContent">
  <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
    <br/>
    <h6 className='texts mb-3' >Dr Ranjita is Section In-Charge of Silkworm Breeding and Genetics and supervising Multivoltine Breeding Laboratory.  </h6>
    
  </div>
  <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
    <br/>
    <h6 className='texts mb-3'>Dr Ranjita’s area of interest include evolving productive Polyvoltine breeds and crossbreeds of mulberry silkworms suitable for Eastern and North Eastern India. Her research work also focuses on improving of multivoltine breeds popular in Farmers’ field. Her work also consists of maintaining of polyvoltine germplasm breeds with its racial characters</h6>

  </div>
  <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
    <br/>
    <h6 className='texts mb-3'> Devi TR and Shyamala BV. 2013. Male- and female-specific variants of doublesex gene products have different roles to play towards regulation of Sex combs reduced expression and sex comb morphogenesis in Drosophila. J. Biosci. 38: 455–460. DOI:10.1007/s12038-013-9348-1 </h6>
    <h6>Devi TR and Shyamala BV. 2013. Evolution of sex comb from the primitive bristle pattern in Drosophila is associated with modification in the developmental regulatory protein Dachshund. Genesis 51(2):97-109. doi: 10.1002/dvg.22361. </h6>

    <h6> Devi TR, Chandrakanth N, Verma AK, Sivaprasad V, Kumar C.M. 2022. Development of Productive multivoltine and Bivoltine Congenic breeds suitable for Eastern and North Eastern India. IJARESM 10(3): 1401-1407</h6>
    
  </div>
  <div className="tab-pane fade" id="education" role="tabpanel" aria-labelledby="education-tab">

    <br/>
   
    <div className="album py-1">
        <div className="container">

          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th> <center style={{ color: "maroon" }}>Degree</center></th>
                <th><center style={{ color: "maroon" }}> Year</center></th>
                <th><center style={{ color: "maroon" }}>Thesis title</center></th>
                <th><center style={{ color: "maroon" }}> Institute</center></th>

              </tr>
            </thead>
            <tbody>

              <tr >
                <td > <center className='mb-2 mt-2'>B.Sc</center></td>
                <td><center className='mb-2 mt-2'></center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>B. Sc	Chemistry, Botany, Zoology	University of Mysoren</center></td>
                <td><center className='mb-2 mt-2'>University of Mysore</center></td>



              </tr>
              <tr >
                <td > <center className='mb-2 mt-2'>M.Sc.</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'></center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>M. Sc	Zoology with Distinction	University of Mysore</center></td>
                <td><center className='mb-2 mt-2'>University of Mysore </center></td>



              </tr>
              <tr >
                <td > <center className='mb-2 mt-2'>Ph.D</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'></center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>Genetics (Evo-Devo)</center></td>
                <td><center className='mb-2 mt-2'>University of Mysore</center></td>



              </tr>


            </tbody>
          </Table>
         
        </div>
      </div>
  </div>
  <div className="tab-pane fade" id="projects" role="tabpanel" aria-labelledby="projects-tab">
    <br/>
    <div className="album py-1">
        <div className="container">

          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th> <center style={{ color: "maroon" }}>S.N</center></th>
                <th><center style={{ color: "maroon" }}> Name of Research Project</center></th>
         
                <th><center style={{ color: "maroon" }}> Status</center></th>

              </tr>
            </thead>
            <tbody>

              <tr >
                <td > <center className='mb-2 mt-2'>1</center></td>
                <td><center className='mb-2 mt-2'>As Principal Investigator:
AIB 02006 MI: 
Improvement of Nistari lines for survival and silk productivity	Concluded </center></td>
           
                <td><center className='mb-2 mt-2'>Concluded </center></td>



              </tr>
              <tr >
                <td > <center className='mb-2 mt-2'>2</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>As Co-Investigator:
AIB 3619: 
 Development of Silkworm (Bombyx mori L) Congenic Breeds from a Gene Pool with higher Genetic Plasticity 
	Concluded
</center></td>
             
                <td><center className='mb-2 mt-2'> Concluded  </center></td>



              </tr>
              <tr >
                <td > <center className='mb-2 mt-2'>3</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>As Co-Investigator AIB 02009MI:  Authorization trials of silkworm hybrid, 12Yx BFC1 in Eastern & North Eastern India </center></td>
                <td><center className='mb-2 mt-2'>Concluded</center></td>

              </tr>
              <tr >
                <td > <center className='mb-2 mt-2'>4</center></td>
                <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>As Co-Investigator: 
APS 02020MI: 
Improvement of seed crop productivity in West Bengal
</center></td>
                <td><center className='mb-2 mt-2'><small>On-Going  </small></center></td>

              </tr>
              

              

            </tbody>
          </Table>
    
        </div>
      </div>
  </div>
</div>
</div>
</div>
<br/>
<br/>
        </div>
    )
}

export default Devi